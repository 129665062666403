import React, { useState, useRef, useEffect } from "react";

import {
  Grid,
  Typography,
  TextField,
  Box,
  Autocomplete,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableBody,
  IconButton,
  Button,
  createFilterOptions,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components/macro";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Title from "../../../../../../components/CustomComponents/title/index.js";
import EditUserFeature from "./EditUserFeature.js";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton.js";
import NoLocationFound from "../../../../../../components/CustomComponents/Errors/NoLocationFound.js";
import { useStyles } from "../../../styles/style.js";
import FetchEmailsModel from "./FetchEmailsModel.js";
import { api } from "../../../../../../contexts/JWTContext.js";
import Loader from "../../../../../../components/Loaders/Loader.js";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useDateFormat from "../../../../../../hooks/useDateFormat.js";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function AddNewUser({ theme }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { page } = location.state || {};
  const textArea = useRef();
  const classes = useStyles();
  const { format } = useDateFormat();
  const [dateFormat, setDateFormat] = useState({ label: format });
  const [manageLocation, setManageLocation] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [addNewUserEmials, setAddNewUserEmails] = useState("");
  const [validEmailsArray, setValidEmailsArray] = useState([]);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [totalEmailsArr, setTotalEmailsArr] = useState([]);
  const [totalModalEmailsArr, setTotalModalEmailsArr] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllCheckedModal, setSelectAllCheckedModal] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [duplicateRole, setDuplicateRole] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [selectedRoleError, setSelectedRoleError] = useState(false);
  const [emailsError, setEmailsError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationValue, setLocationValue] = useState([]);
  const [firstNameRequired, setFirstNameRequired] = useState(false);
  const [lastNameRequired, setLastNameRequired] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showRoleName, setShowRoleName] = useState(false);
  const [sameNameError, setSameNameError] = useState(false);
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [oneUserRole, setOneUserRole] = useState(null);
  const [oneUserRoleLoading, setOneUserRoleLoading] = useState(false);
  const [singleRole, setSingleRole] = useState({
    permissions: [],
  });
  const [allRoles, setAllRoles] = useState(null);
  const [companyFeatures, setAllCompanyFeatures] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const userData = JSON.parse(localStorage.getItem("user"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [disableButton, setDisableButton] = useState(false);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [oldGroups, setOldGroups] = useState([]);
  const [checkboxes, setCheckboxes] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false,
  });
  const [singleUser, setSingleUSer] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const manangeLocationsArray = [
    { label: "All Locations", displayName: t("All_Locations") },
    { label: "Specific Locations", displayName: t("specificLocation") },
    { label: "Groups", displayName: t("groups_label") },
  ];

  const dateFormats = [
    { label: "D/M/YYYY" },
    { label: "YYYY-MM-DD" },
    { label: "DD/MM/YYYY" },
    { label: "MM/DD/YYYY" },
    { label: "DD.MM.YYYY" },
  ];
  useEffect(() => {
    const permissionFound = permissionsArr?.filter(
      (item) => item?.permissions?.length > 0
    );
    if (permissionFound?.length <= 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [permissionsArr]);

  useEffect(async () => {
    await getAllLocations();
    await getAllCompanyFeatures();
    await getAllRoles();
    await getSingleUser();
    await getAllLocationGroups();
  }, []);
  useEffect(() => {
    if (selectedRole !== null) {
      getSingleRole();
    }
  }, [selectedRole]);

  useEffect(() => {
    if (oneUserRole !== null) {
      if (oneUserRole?.User?.email) {
        setSingleUSer({
          ...singleUser,
          email: oneUserRole.User.email,
          firstName: oneUserRole.User.firstName,
          lastName: oneUserRole.User.lastName,
        });
        setDateFormat({ label: oneUserRole.User.dateFormat });
      }
      if (
        allRoles !== null &&
        allRoles?.rows?.length > 0 &&
        oneUserRole !== null
      ) {
        const userSelectedRole = allRoles?.rows?.find(
          (item) => item?.id === oneUserRole?.CompanyRole?.id
        );

        if (userSelectedRole !== undefined) {
          setSelectedRole(userSelectedRole);
          setShowFeatures(true);
        }
      }

      if (oneUserRole !== null && oneUserRole?.allLocationAccess === true) {
        setManageLocation({
          label: "All Locations",
          displayName: t("All_Locations"),
        });
        if (
          oneUserRole?.name === "company-admin" ||
          location?.state?.isPrimary
        ) {
          setDisableField(true);
        } else {
          setDisableField(false);
        }
      } else if (allGroups !== null && allGroups?.rows?.length > 0) {
        const commonItemsForGroups =
          oneUserRole?.User?.UserGroupAccesses.filter((item1) =>
            allGroups?.rows?.some((item2) => item2.id === item1.groupId)
          );
        if (commonItemsForGroups?.length > 0) {
          setManageLocation({
            label: "Groups",
            displayName: t("groups_label"),
          });

          let selectedGroupsArray = [];

          oneUserRole?.User?.UserGroupAccesses?.forEach((item) => {
            allGroups?.rows?.forEach((itemTwo) => {
              if (item?.groupId === itemTwo?.id) {
                selectedGroupsArray?.push(itemTwo);
              }
            });
          });
          setSelectedGroups([...selectedGroupsArray]);
        }
      } else {
        setManageLocation({
          label: "Specific Locations",
          displayName: t("specificLocation"),
        });
      }
      let selectedLocationArray = [];
      oneUserRole?.User?.UserLocationAccesses?.forEach((item) => {
        allLocations?.forEach((itemTwo) => {
          if (item?.locationId === itemTwo?.id) {
            selectedLocationArray?.push(itemTwo);
          }
        });
      });
      setLocationValue([...selectedLocationArray]);
    }
  }, [oneUserRole, allRoles, allLocations, allGroups]);

  function checkExistence(array1, array2) {
    for (const item1 of array1) {
      const foundItem = array2.find((item2) => item2.id === item1.locationId);
      if (!foundItem) {
        return false;
      }
    }
    return true;
  }

  const getAllLocationGroups = async () => {
    setIsLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllGroups(null);
    }
  };

  const getAllLocations = async () => {
    try {
      setLocationLoading(true);
      const res = await api.get(`/location/search`, {});
      if (res.status === 200) {
        setLocationLoading(false);
        if (res?.data?.data?.results?.length > 0) {
          setAllLocations(res?.data?.data?.results);
        } else {
          setAllLocations(null);
        }
      }
    } catch (error) {
      setAllLocations(null);
      toast.error(error?.response?.data?.message);
      setLocationLoading(false);
    }
  };

  const getAllRoles = async () => {
    setLocationLoading(true);
    try {
      const res = await api.get(
        `companyRoles?companyId=${companyData?.id}&pageNumber=1&limit=1000`
      );
      if (res.status === 200) {
        setAllRoles(res?.data?.data);
        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
      setAllRoles(null);
    }
  };

  useEffect(() => {
    handleChangeGroups(selectedGroups);
    setLocationError(false);
  }, []);
  const getSingleUser = async () => {
    try {
      setLocationLoading(true);
      const res = await api.get(`companyUserRoles/${id}`);
      if (res.status === 200) {
        setSingleRole({
          permissions: res?.data?.data?.CompanyRole?.CompanyRolesPermissions,
        });
        setOneUserRole(res?.data?.data);
      }
      setLocationLoading(false);
    } catch (error) {
      setLocationLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };

  const getAllCompanyFeatures = async () => {
    try {
      setLocationLoading(true);
      const res = await api.get(`/companyFeatures/${companyData?.id}/company`);
      if (res.status === 200) {
        const filteredFeatures =
          res?.data?.data?.filter((feature) => feature.name !== "Reports") ||
          []; // filtering reports
        setAllCompanyFeatures(filteredFeatures);
        setLocationLoading(false);
      }
    } catch (error) {
      setAllCompanyFeatures([]);
      setLocationLoading(false);
    }
  };

  useEffect(() => {
    // Combine the arrays
    const combinedArray = [...dataArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalModalEmailsArr(distinctEmails);
  }, [dataArray]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        let data;
        if (file.type === "text/csv") {
          const lines = fileContents.split("\n");
          data = lines.map((line) => line.replace("\r", "").split(","));
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContents, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          data = [];
          sheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            data.push(...jsonData);
          });
        } else {
          return;
        }
        const flatData = data.flat();

        setDataArray(flatData);
      };
      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(file);
      }
      setOpenPlanModal(true);
      setSelectedFile(file.name);
    } else {
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmitOk = () => {
    const combinedArray = [...selectedItems, ...validEmailsArray];
    //
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    //
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    if (distinctEmails?.length > 0) {
      setTotalEmailsArr(distinctEmails);
    }
    handleCloseEmailsModal();
  };

  const handleAddNewUserEmailsChange = (value) => {
    if (!value) {
      setEmailsError(true);
    } else {
      setEmailsError(false);
    }
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setAddNewUserEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    // Validate each email address and push the valid ones into the array
    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
        setValidEmailsArray(validEmailsArray);
      }
    }

    const combinedArray = [...selectedItems, ...validEmailsArray];

    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleRemoveFile = () => {
    setDataArray([]);
    setSelectedFile(null);
  };

  const handleCloseEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenPlanModal(false);
    setDataArray([]);
    //  setSelectedFile(null);
    // setTotalEmailsArr([]);
    // setValidEmailsArray([]);
    //  setSelectedItems([]);
    setSelectAllCheckedModal(false);
  };
  const handleCancelEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenPlanModal(false);
    setDataArray([]);
    setSelectedFile(null);
    // setTotalEmailsArr([]);
    // setValidEmailsArray([]);
    setSelectedItems([]);
    setSelectAllChecked(false);
  };
  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item.groups.some((group) => group.locationId === targetId)
    );
  }
  const handleChangeGroups = (value) => {
    setSelectedGroups(value);

    let newLocations = [...locationValue];
    const results = oldGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations?.push(location);
            } else {
              setSelectAll(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  locationValue?.map((location) => {
                    const filteredArray = filterArrayById(value, location?.id);
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          locationValue?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }
    const uniqueData = removeDuplicates(newLocations);
    setLocationValue(uniqueData);

    if (newLocations?.length > 0) {
      setLocationError(false);
    } else {
      setLocationError(true);
    }

    setOldGroups(value);
  };
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }
  // Filter 'array2' based on 'groups' existence in 'array1'

  const handleChangeGroupsForLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectAllGroups(false);
      setSelectedGroups(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      //  setLocationError(false);
    } else {
      setSelectAllGroups(false);
      setSelectedGroups([]);
    }
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const getSingleRole = async () => {
    try {
      setLoading(true);
      const res = await api.get(
        `/companyRoles/${selectedRole !== null ? selectedRole?.id : id}`
      );
      if (res.status === 200) {
        setLoading(false);

        setSingleRole({
          permissions: res?.data?.data?.CompanyRolesPermissions,
        });
      }
    } catch (error) {}
  };

  const handleCreateNewRole = () => {
    setShowFeatures(true);
    setSelectedRole(null);
    setCheckboxes({
      view: false,
      add: false,
      edit: false,
      delete: false,
    });
    setSelectAllChecked(false);
    setDuplicateRole(false);
    setShowRoleName(true);
  };

  const handleChangeSelectedRole = (e, value) => {
    if (!value) {
      setSelectedRoleError(true);
    } else {
      if (value?.name === "company-admin") {
        setManageLocation({
          label: "All Locations",
          displayName: t("All_Locations"),
        });
        let locationIds = [];
        if (allLocations?.length > 0) {
          allLocations?.map((item) => {
            locationIds?.push(item);
          });
        }
        setDisableField(true);
        setLocationValue(locationIds);
        setLocationError(false);
        setSelectedGroups([]);
        setSelectAllGroups(false);
      } else {
        setDisableField(false);
      }
      setSelectedRoleError(false);
      setShowFeatures(true);
      setDuplicateRole(false);
      setShowRoleName(true);
    }
    setShowRoleName(false);
    setSelectedRole(value);
  };

  const onCancelEdit = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 1, page: page },
    });
  };

  const handleChangeRoleName = (e) => {
    const value = e.target.value;
    const isSameName = allRoles?.rows?.find((item) => {
      return item.label === value;
    });
    setRoleName(value);
    if (isSameName) {
      setSameNameError(true);
    } else {
      setSameNameError(false);
    }
    if (value === "") {
      setRoleNameError(true);
    } else {
      setSelectedRoleError(false);
      setRoleNameError(false);
    }
  };

  const handleUpdateUser = async () => {
    if (singleUser?.firstName === "") {
      setFirstNameRequired(true);
    }
    if (singleUser?.lastName === "") {
      setLastNameRequired(true);
    }
    if (singleUser?.email === "") {
      setEmailRequired(true);
    }
    let locationIds = [];
    if (manageLocation?.label === "All Locations") {
      if (allLocations?.length > 0) {
        allLocations?.map((item) => {
          locationIds?.push(item?.id);
        });
      }
    } else {
      if (locationValue?.length > 0) {
        locationValue?.map((item) => {
          locationIds?.push(item?.id);
        });
      }
    }

    const allPermissions = permissionsArr?.reduce((accumulator, obj) => {
      return accumulator.concat(obj.permissions);
    }, []);

    setLocationLoading(true);
    let groupIds = [];
    if (manageLocation?.label === "Groups") {
      if (selectedGroups?.length > 0) {
        selectedGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
    } else {
      setSelectedGroups([]);
    }

    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.put(`companyUserRoles/${id}`, {
        email: singleUser?.email,
        firstName: singleUser?.firstName,
        lastName: singleUser?.lastName,
        dateFormat: dateFormat?.label,
        userId: oneUserRole?.User?.id,
        companyId: companyData?.id,
        companyRoleId: showRoleName === false ? selectedRole?.id : null,
        selectAllLocations:
          manageLocation?.label === "All Locations" ? true : false,
        allLocationAccess:
          manageLocation?.label === "All Locations" ? true : false,
        locationIds: locationIds,
        groupIds,
        newRole: showRoleName ? true : false,
        name: showRoleName
          ? roleName?.replaceAll(" ", "-").toLowerCase()
          : selectedRole?.name,
        permissions: allPermissions?.length > 0 ? allPermissions : [],
      });
      if (res.status === 200) {
        setLocationLoading(false);
        toast.success(res?.data?.message);
        navigate("/user/accounts-overview", {
          state: { tabIndex: 1, page: page },
        });
      }
    } catch (error) {
      setLocationLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {openPlanModal && (
        <FetchEmailsModel
          title={t("fetched_emails")}
          subTitle={t("fetch_emails_subheading")}
          open={openPlanModal}
          handleCloseNewRequestModel={handleCloseEmailsModal}
          handleCancelEmailsModal={handleCancelEmailsModal}
          dataArray={dataArray}
          totalModalEmailsArr={totalModalEmailsArr}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectAllChecked={selectAllChecked}
          setSelectAllChecked={setSelectAllChecked}
          handleSubmitOk={handleSubmitOk}
        />
      )}
      <Title
        title={t("edit_user")}
        showButton={true}
        navigateToAccountOverView={true}
        showDivider={true}
        page={page}
      />

      {locationLoading ? (
        <Loader />
      ) : (
        <>
          {allLocations?.length > 0 ? (
            <>
              <Grid container mt={2}>
                <Grid item xs={12} lg={12}>
                  <ThemeProvider theme={theme}>
                    <Grid>
                      <Grid className={classes.addUserGrid}>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          {oneUserRole !== null &&
                            oneUserRole?.User?.status !== "pending" && (
                              <Box mt={6}>
                                {" "}
                                <Typography
                                  className={classes.addUserFieldLable}
                                  mb={2}
                                >
                                  {t("First_Name")}
                                </Typography>
                                <Box mt={3}>
                                  {" "}
                                  <TextField
                                    type="text"
                                    name="firstName"
                                    fullWidth
                                    value={singleUser?.firstName}
                                    error={firstNameRequired}
                                    helperText={
                                      firstNameRequired && t("First_name_req")
                                    }
                                    onChange={(e) => {
                                      setSingleUSer({
                                        ...singleUser,
                                        firstName: e.target.value,
                                      });
                                      if (e.target.value?.length > 0) {
                                        setFirstNameRequired(false);
                                      } else {
                                        setFirstNameRequired(true);
                                      }
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}

                          {oneUserRole !== null &&
                            oneUserRole?.User?.status !== "pending" && (
                              <Box mt={6}>
                                {" "}
                                <Typography
                                  className={classes.addUserFieldLable}
                                  mb={2}
                                >
                                  {t("Last_Name")}
                                </Typography>
                                <Box mt={3}>
                                  <TextField
                                    type="text"
                                    name="lastName"
                                    value={singleUser?.lastName}
                                    fullWidth
                                    error={lastNameRequired}
                                    helperText={
                                      lastNameRequired && t("Last_name_req")
                                    }
                                    onChange={(e) => {
                                      setSingleUSer({
                                        ...singleUser,
                                        lastName: e.target.value,
                                      });
                                      /*  if (e.target.value?.length > 0) {
                                    setLastNameRequired(false);
                                  } else {
                                    setLastNameRequired(true);
                                  } */
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}

                          {/* <Typography>{errors.lastName?.message}</Typography> */}
                        </Grid>
                        <Box mt={6}>
                          {" "}
                          <Typography
                            className={classes.addUserFieldLable}
                            mb={2}
                          >
                            {t("Email_Address")}
                          </Typography>
                          <Box mt={3}>
                            {" "}
                            <TextField
                              type="email"
                              name="email"
                              fullWidth
                              value={singleUser?.email}
                              error={emailRequired || validEmail}
                              helperText={
                                emailRequired
                                  ? t("Email_required_text")
                                  : validEmail
                                  ? t("valid_emaill")
                                  : ""
                              }
                              onChange={(e) => {
                                setSingleUSer({
                                  ...singleUser,
                                  email: e.target.value,
                                });
                                if (e.target.value?.length > 0) {
                                  setEmailRequired(false);
                                  if (e.target.value.length > 0) {
                                    if (
                                      /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(
                                        e.target.value
                                      )
                                    ) {
                                      setValidEmail(false);
                                    } else {
                                      setValidEmail(true);
                                    }
                                  } else {
                                    setValidEmail(false);
                                  }
                                } else {
                                  setEmailRequired(true);
                                  setValidEmail(false);
                                }
                              }}
                            />
                          </Box>
                        </Box>

                        {/*   <Box mt={3}>
                          <TextField
                            id="outlined-basic"
                            multiline
                            rows={5.8}
                            maxRows={10}
                            ref={textArea}
                            variant="outlined"
                            placeholder={t("Enter_email_here")}
                            sx={{ marginBottom: "12px" }}
                            value={addNewUserEmials}
                            fullWidth
                            error={emailsError}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleAddNewUserEmailsChange(
                                  event.target.value + ","
                                );
                              }
                            }}
                            onChange={(e) =>
                              handleAddNewUserEmailsChange(e.target.value)
                            }
                            helperText={
                              emailsError ? "This field is required" : ""
                            }
                          />
                        </Box>
                     
                        <Box className={classes.reviewsubHeadBox}>
                          {totalEmailsArr?.length > 5000 ? (
                            <Typography
                              className={classes.reviewsubHeadTextError}
                            >
                              {t("max_upload_text")}
                            </Typography>
                          ) : (
                            <Typography className={classes.reviewsubHeadText}>
                              {"Emails will be sent to" +
                                " " +
                                totalEmailsArr?.length +
                                " " +
                                t("email_addresses")}
                            </Typography>
                          )}
                        </Box>

                        <Box>
                          <input
                            type="file"
                            id="file-email"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                            style={{ display: "none" }}
                          />

                          <Box mt={3} ml={-3}>
                            <CommonButton
                              onSubmit={handleButtonClick}
                              label={t("Browse_File")}
                            />
                          </Box>
                          <Box ml={3}>
                            {selectedFile ? (
                              <Box sx={{ display: "flex", paddingTop: "8px" }}>
                                <Typography>{selectedFile}</Typography>{" "}
                                <IconButton
                                  sx={{
                                    cursor: "pointer",
                                    width: "9px",
                                    height: "9px",
                                    marginLeft: "8px",
                                    marginTop: "2px",
                                  }}
                                  onClick={() => handleRemoveFile()}
                                >
                                  <DeleteIcon
                                    fontSize="small"
                                    sx={{ color: "#FF1616" }}
                                  />
                                </IconButton>
                              </Box>
                            ) : (
                              <p>{t("")}</p>
                            )}
                          </Box>
                        </Box> */}

                        <Box mt={6}>
                          <Typography
                            className={classes.addUserFieldLable}
                            mb={2}
                          >
                            {t("location_access")}
                          </Typography>

                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            getOptionLabel={(option) => option?.displayName}
                            options={manangeLocationsArray}
                            value={manageLocation}
                            fullWidth
                            disabled={disableField}
                            onChange={(e, value) => {
                              setManageLocation(value);
                              if (value?.label === "All Locations") {
                                let locationIds = [];
                                if (allLocations?.length > 0) {
                                  allLocations?.map((item) => {
                                    locationIds?.push(item);
                                  });
                                }
                                setLocationValue(locationIds);
                                setLocationError(false);
                                setSelectedGroups([]);
                                setSelectAllGroups(false);
                              } else {
                                setLocationValue([]);
                                setLocationError(false);
                                setSelectedGroups([]);
                                setSelectAllGroups(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("locations")}
                                disabled={disableField}
                              />
                            )}
                          />
                        </Box>

                        {manageLocation?.label === "Groups" && (
                          <Box mt={6}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("group_access")}
                            </Typography>

                            <Autocomplete
                              multiple
                              disablePortal
                              disableCloseOnSelect
                              id="combo-box-demo"
                              options={
                                allGroups !== null &&
                                allGroups?.rows?.length > 0
                                  ? allGroups?.rows
                                  : []
                              }
                              getOptionLabel={(option) =>
                                option?.name ? option?.name : ""
                              }
                              className="Autocomplete-field"
                              value={selectedGroups ? selectedGroups : []}
                              onChange={(e, value) => {
                                if (value.find((option) => option.id === 0)) {
                                  if (
                                    selectedGroups?.length ==
                                    allGroups?.rows?.length
                                  ) {
                                    setSelectedGroups([]);
                                    setSelectAllGroups(false);
                                  } else {
                                    setSelectAllGroups(true);
                                    handleChangeGroups(allGroups?.rows);
                                  }
                                } else {
                                  handleChangeGroups(value);
                                  if (
                                    value?.length === allGroups?.rows?.length
                                  ) {
                                    setSelectAllGroups(true);
                                  } else {
                                    setSelectAllGroups(false);
                                  }
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (allGroups?.rows?.length > 0) {
                                  return [
                                    { id: 0, name: "Select All" },
                                    ...filtered,
                                  ];
                                } else {
                                  return [];
                                }
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selectAllGroups || selected}
                                    id="select-all-checkbox"
                                  />

                                  <Typography>{option?.name}</Typography>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  placeholder={t("Select_group")}
                                  {...params}
                                  required
                                  name="language"
                                />
                              )}
                            ></Autocomplete>
                          </Box>
                        )}

                        {(manageLocation?.label === "Specific Locations" ||
                          manageLocation?.label === "Groups") && (
                          <Box mt={6}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("Select_Locations")}
                            </Typography>

                            {allLocations?.length > 0 && (
                              <Autocomplete
                                multiple
                                error={locationError}
                                disablePortal
                                id="combo-box-demo"
                                disableCloseOnSelect
                                options={
                                  allLocations?.length > 0 ? allLocations : []
                                }
                                value={locationValue}
                                onChange={(event, value) => {
                                  // Check if 'All' option is clicked
                                  if (value?.length > 0) {
                                    setLocationError(false);
                                  } else {
                                    setLocationError(true);
                                  }

                                  if (value.find((option) => option.id === 0)) {
                                    // Check if all options are selected
                                    if (
                                      allLocations?.length ===
                                      locationValue?.length
                                    ) {
                                      setLocationValue([]);
                                      setSelectAll(false);
                                    } else {
                                      setSelectAll(true);
                                      setLocationValue(allLocations);
                                      handleChangeGroupsForLocations(
                                        allLocations
                                      );
                                      //  setLocationRequired(false);
                                    }
                                  } else {
                                    setLocationValue(value);
                                    handleChangeGroupsForLocations(value);
                                    if (
                                      value?.length === allLocations?.length
                                    ) {
                                      setSelectAll(true);
                                    } else {
                                      setSelectAll(false);
                                    }
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (allLocations?.length > 0) {
                                    return [
                                      { id: 0, internalName: "Select All" },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                defaultValue={locationValue}
                                getOptionLabel={(option) =>
                                  option?.internalName
                                    ? option?.internalName +
                                      " " +
                                      "(" +
                                      (option?.addressLine1 !== "" &&
                                      option?.addressLine1 !== null &&
                                      option?.addressLine1 !== undefined
                                        ? option?.addressLine1 + "," + " "
                                        : "") +
                                      (option?.city ? option?.city : "") +
                                      ")"
                                    : ""
                                }
                                sx={{ minWidth: 300 }}
                                // value={locationId ? locationId : []}
                                // onChange={(e, value) => {
                                //   setLocationRequired(false);

                                //   setSelectedLocation(value);
                                // }}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      id="select-all-checkbox"
                                      checked={selectAll || selected}
                                      // checked={selected}
                                    />
                                    {`${option?.internalName} ${
                                      option?.addressLine1 !== "" &&
                                      option?.addressLine1 !== null &&
                                      option?.addressLine1 !== undefined
                                        ? "(" + option?.addressLine1 + "," + " "
                                        : ""
                                    } ${
                                      option?.city ? option?.city + ")" : ""
                                    }`}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    name="language"
                                    {...params}
                                    error={locationError}
                                    placeholder={t("Select_Location")}
                                    //  defaultValue={{ selectedLocation }}
                                    helperText={
                                      locationError ? t("location_req") : ""
                                    }
                                  />
                                )}
                              ></Autocomplete>
                            )}
                          </Box>
                        )}

                        <Box mt={6}>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("date_format")}
                            </Typography>
                            <BootstrapTooltip
                              title={
                                <h1 style={{ fontSize: "12px" }}>
                                  {t("date_format_tooltip")}
                                </h1>
                              }
                            >
                              <ErrorOutlineIcon
                                className={classes.quotionIcon}
                              />
                            </BootstrapTooltip>
                          </Box>

                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dateFormats}
                            fullWidth
                            onChange={(e, value) => {
                              setDateFormat(value);
                            }}
                            value={dateFormat}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("date_format")}
                              />
                            )}
                          />
                        </Box>

                        <Box mt={6}>
                          <Box className={classes.addUserBox}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("select_role")}
                            </Typography>
                            <Box mb={2}>
                              <Button autoFocus onClick={handleCreateNewRole}>
                                <AddIcon fontSize="medium" />
                                <Typography className={classes.createRole}>
                                  {t("create_new_role")}
                                </Typography>
                              </Button>
                            </Box>
                          </Box>

                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allRoles !== null ? allRoles?.rows : []}
                            getOptionLabel={(option) =>
                              option?.name
                                ? (
                                    option.name.charAt(0).toUpperCase() +
                                    option.name.slice(1)
                                  ).replaceAll("-", " ")
                                : ""
                            }
                            fullWidth
                            value={selectedRole}
                            onChange={handleChangeSelectedRole}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("select_role")}
                                error={selectedRoleError}
                                helperText={
                                  selectedRoleError ? t("role_req") : ""
                                }
                              />
                            )}
                          />
                        </Box>

                        {showRoleName && (
                          <Box mt={6}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("role_name")}
                            </Typography>
                            <TextField
                              fullWidth
                              placeholder={t("role_placeHolder")}
                              value={roleName}
                              onChange={handleChangeRoleName}
                              error={roleNameError || sameNameError}
                              helperText={
                                roleNameError
                                  ? t("roleName_required")
                                  : sameNameError
                                  ? t("roleName_can't_same")
                                  : ""
                              }
                            />
                          </Box>
                        )}
                        {showFeatures && (
                          <EditUserFeature
                            selectedRole={selectedRole}
                            duplicateRole={duplicateRole}
                            setDuplicateRole={setDuplicateRole}
                            setSelectedRole={setSelectedRole}
                            setPermissionsArr={setPermissionsArr}
                            permissionsArr={permissionsArr}
                            companyFeatures={companyFeatures}
                            singleRole={singleRole}
                            showRoleName={showRoleName}
                            setShowRoleName={setShowRoleName}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <Grid container className={classes.addUserButtonContainer}>
                <CommonButton
                  displayWhite="true"
                  onSubmit={onCancelEdit}
                  label={t("Cancel")}
                />

                <CommonButton
                  label={t("Submit")}
                  disabled={
                    disableButton
                      ? true
                      : singleUser?.firstName === ""
                      ? true
                      : singleUser?.email === ""
                      ? true
                      : selectedRole === null && roleName === ""
                      ? true
                      : manageLocation?.label === "Specific Locations" &&
                        locationError
                      ? true
                      : manageLocation?.label === "Specific Locations" &&
                        locationValue.length === 0
                      ? true
                      : manageLocation?.label === "Groups" &&
                        locationValue.length === 0
                      ? true
                      : false
                    /*   emailsError
                      ? true
                      : roleName === ""
                      ? true
                      : roleNameError
                      ? true
                      : selectedRole === ""
                      ? true
                      : selectedRoleError
                      ? true
                      : manageLocation?.label === "Specific Locations" &&
                        locationError
                      ? true
                      : manageLocation?.label === "Specific Locations" &&
                        locationValue.length===0
                      ? true
                      : singleUser?.firstName === ""
                      ? true
                      : singleUser?.lastName === ""
                      ? true
                      : singleUser?.email === ""
                      ? true
                      : validEmail
                      ? true
                      : false */
                  }
                  onSubmit={handleUpdateUser}
                  // loading={isLoading}
                />
              </Grid>
            </>
          ) : allLocations === null ? (
            <Box className={classes.locationbox}>
              <NoLocationFound
                text={t("No_Location_Added")}
                subtext={t("add_location_text")}
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
}

export default AddNewUser;
