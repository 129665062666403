import React, { useState, useEffect, useRef } from "react";
import {
  Divider,
  Stack,
  Typography,
  Box,
  Autocomplete,
  Grid,
  TextField,
  Checkbox,
  Button,
  Alert as MuiAlert,
  DialogActions,
  Tooltip,
  fabClasses,
  useMediaQuery,
  IconButton,
  tooltipClasses,
  Chip,
  createFilterOptions,
} from "@mui/material";
import * as XLSX from "xlsx";

import { useTheme } from "@mui/material/styles";
import LocationNotFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@material-ui/core";
import Googleicon from "../../../assets/Icons/Group.svg";
import { ReactComponent as AddGroup } from "../../../assets/images/AddGroup.svg";

import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import TitleHeader from "../../../components/CustomComponents/title/titleForReviews";
import TitleHeaderReviews from "../../../components/CustomComponents/title/index";
import styled from "styled-components/macro";
import SourceReviewsCard from "./Components/SourceReviewsCard";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Virtuoso } from "react-virtuoso";
import NotFound from "../../../components/NotFound/NotFound";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FiltersModel from "./Components/FiltersModel";
import FetchModel from "../../../components/Models/DeleteModal/FetchModel";
import SecheduledReports from "../../../components/Models/DeleteModal/SecheluedReports";
import SecheduleReport from "../../../components/Models/DeleteModal/ScheduleReport";
import BulkRequestModel from "../../../components/Models/DeleteModal/BulkRequestModel";
import Delete from "../../../components/Models/DeleteModal/Delete";
import ExportEmailModal from "./Components/Models/ExportEmailModel";
import ExportFileDownloadModal from "./Components/Models/ExportFileDownloadModal";
import WarningIcon from "@mui/icons-material/Warning";
import Iframe from "../../../components/Models/IframeModal/Iframe";
import QuestionMark from "../../../assets/Icons/Question.svg";
import NotConnected from "../../../components/CustomComponents/Errors/NotConnected";
import { CircularProgress } from "@mui/material";
import ReconnectCard from "../../../components/CustomComponents/Cards/ReconnectCard";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LinearBuffer from "./Components/Models/LinearProgressLoading";
import dayjs from "dayjs";
import FilterModal from "../../../components/Models/FilterModalReviews";
import { width } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { languageOptions } from "../../../assets/defaultLanguage";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import useDateFormat from "../../../hooks/useDateFormat";
import {
  LanguageChange,
  checkLocalStorageData,
} from "../../../utils/LocalStorageHelpers";
import { sourcesList } from "../../../assets/sourcesList";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  subHeadFetch: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2430",
    marginTop: "8px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  input: {
    // color: "white",
    // height: "48px",
    padding: "0px",
  },
}));

function GoogleReviews() {
  const initialState = {
    groups: [],
    group: [],
    locations: [],
    location: [],
    timePeriod: "",
    time: { id: 0, name: "", value: "" },
    starRatings: [],
    rate: [],
    sources: [],
    reviewSource: [],
    actions: 2,
    reviewedBy: "",
    tags: [],
  };
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDataValid = checkLocalStorageData();
  const filter = createFilterOptions();
  const { signOut, permissionsAcess, user } = useAuth();
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [reviewsData, setReviewsData] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [dumpReviewsLoading, setDumpReviewsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [oldReviewLoading, setOldReviewLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [openOldReviewModel, setOpenOldReviewModel] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [allGoogleLocation, setAllGoogleLocation] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [allReviewSourceForAgent, setAllReviewSourceForAgent] = useState([]);
  const [tags, setTags] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [sourceId, setsourceId] = useState("");
  const [allTimePeriod, setAllTimePeriod] = useState({
    name: "All",
    value: 0,
    periodValue: "All",
  });
  const [ratingsValue, setRatingsValue] = useState([]);
  const [selectedReply, setSelectedReply] = useState({ name: "All", value: 2 });
  const [selectedTags, setSelectedTags] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [allTemplate, setAllTemplate] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationValue, setLocationValue] = useState([]);
  const [reviewCountValue, setReviewCountValue] = useState("");
  const [reviewMessage, setReviewMessage] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [reviewChecked, setReviewChecked] = React.useState(true);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const [socialLink, setsociallink] = useState(false);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false);
  const [draftState, setDraftState] = useState(false);
  const [expiredLocations, setExpiredLocation] = useState(null);
  const [displayReconnectButton, setDisplayReconnectButton] = useState(false);
  const [displayReconnectError, setDisplayReconnectError] = useState(false);
  const [users, setUsers] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [openBulkStartModel, setOpenBulkStartModel] = useState(false);
  const [openBulkStopModel, setOpenBulkStopModel] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [locationEditPermission, setLocationEditPermission] = useState(false);
  const [locationAddPermission, setLocationAddPermission] = useState(false);
  const [locationDeletePermission, setLocationDeletePermission] =
    useState(false);
  const [locationViewPermission, setLocationViewPermission] = useState(false);
  const [openReportEmailModal, setOpenReportEmailModal] = useState(false);
  const [type, setType] = useState(null);
  const [filterWithName, setFilterWithName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [companyUser, setCompanyUser] = useState(true);
  const [others, setOthers] = useState(false);
  const [emailBody, setEmailBody] = useState(
    `Please find attached reviews report of ${companyData?.name}
      `
  );
  const [statusLoading, setStatusLoading] = useState(false);
  const [totalEmailsArr, setTotalEmailsArr] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [xlsFileData, setXlsFileData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filterObject, setFIlterObject] = useState({
    location: "",
    reviewSource: "",
    timePeriod: "",
    ratings: "",
    actions: "",
    status: "",
    tags: "",
  });
  const [openModalFilter, setOpenModalFilter] = React.useState(false);

  const [viewPermission, setViewPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermissionTasks, setViewPermissionTasks] = useState(false);
  const [addPermissionTasks, setAddPermissionTasks] = useState(false);
  const [editPermissionTasks, setEditPermissionTasks] = useState(false);
  const [deletePermissionTasks, setDeletePermissionTasks] = useState(false);
  const languageData = localStorage.getItem("i18nextLng");
  const [loadingXlsReport, setLoadingXlsReport] = useState(false);
  const [recipientError, setRecipientError] = useState(true);
  const [recipientEmailError, setRecipientEmailError] = useState(true);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientValue, setRecipientValue] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [addNewUserEmials, setAddNewUserEmails] = useState("");
  const [validEmailsArray, setValidEmailsArray] = useState([]);
  const [reviewReportLoading, setReviewReportLoading] = useState(false);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [newSourceDownload, setNewSourceDownload] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const [openSchduleModal, setOpenSchduleModal] = useState(false);
  const [openCreateSchedule, setOpenCreateSchedule] = useState(false);
  const today = new Date();
  const previousYear = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [scheduleReportLoading, setScheduleReportLoading] = useState(false);
  useState();
  const [deletedIds, setDeletedId] = useState(null);
  const [deleteScheduleReportLoading, setDeleteScheduleReportLoading] =
    useState(false);
  const [singleScheduledReportsLoading, setSingleScheduledReportsLoading] =
    useState();
  const [singleReportData, setSingleReportData] = useState(null);
  const [selectedEditId, setSelectedEditId] = useState(null);
  const [updateReportLoading, setUpdateReportLoading] = useState();
  const [emailTitle, setEmailTitle] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  const [emailSubject, setEmailSubject] = useState(
    `Reviews management report of ${companyData?.name}`
  );

  const [startDateSchedule, setStartDateSchedule] = useState(null);
  const [endDateSchedule, setEndDateSchedule] = useState(null);
  const [openDeleteScheduledReportModel, setOpenDeleteScheduledReportModel] =
    useState();
  const [scheduledReports, setScheduledReports] = useState(null);
  const [repeatValue, setRepeatValue] = useState("no");
  const [scheduledRportsPageNumber, setScheduledRportsPageNumber] = useState(0);
  const [rowsPerPageScheduled, setRowsPerPageScheduled] = useState(10);
  const [scheduledReportsLoading, setScheduledReportsLoading] = useState(false);
  const [dateRange, setDateRange] = useState("All");
  const [openUpdateStatusModel, setOpenUpdateStatusModel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [pauseValue, setPauseValue] = useState(false);
  const [languageSchedular, setLanguageSchedular] = useState("");
  const [scheduleLocations, setScheduleLocations] = useState(locationId);
  const [scheduleGroups, setScheduleGroups] = useState(selectedGroup);
  const [scheduleReviewSource, setScheduleReviewSource] = useState(sourceId);
  const [scheduleTags, setScheduleTags] = useState(selectedTags);
  const [scheduleRatingValue, setScheduleRatingValue] = useState(ratingsValue);
  const [scheduleReviewerName, setScheduleReviewerName] =
    useState(filterWithName);
  const [scheduleReply, setScheduleReply] = useState(selectedReply);

  const [pdfChecked, setPdfChecked] = useState(true);
  const [xlsChecked, setXlsChecked] = useState(false);
  const [openDownloadPdfModal, setOpenDownloadPdfModal] = useState(false);
  const [displayReportError, setDisplayReportError] = useState(false);
  const [reportFormat, setReportFormat] = useState("pdf");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [images, setImages] = useState([]);
  const stateData = JSON.parse(localStorage.getItem("user"));
  const [reportScheduleLocations, setReportScheduleLocations] = useState([]);
  const [yesChecked, setYesChecked] = useState(true);
  const [noChecked, setNoChecked] = useState(false);
  let selectedLanguage = "";
  let userLang = "";
  selectedLanguage = languageOptions[userLang];
  const [section, setSection] = useState("locations");
  const [checkedLocation, setCheckedLocation] = useState(false);
  const [filters, setFilters] = useState(initialState);
  const [filtersModal, setFiltersModal] = useState(initialState);
  const { formatDate, format } = useDateFormat();
  const [dateFormat, setDateFormat] = useState({ label: format });
  const [scheduledReportSearchVal, setScheduledReportSearchVal] = useState("");
  const [scheduleReportFilters, setScheduleReportFilters] = useState({
    searchVal: "",
    status: "",
  });
  const [frequencyValue, setFrequencyValue] = useState("");
  const [occuranceValue, setOccuranceValue] = useState(1);
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [enabledReviewSources, setEnabledReviewSources] = useState([]);
  const [
    defaultvalueLocationForPastReviews,
    setDefaultvalueLocationForPastReviews,
  ] = useState(null);

  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [filteredModalDate, setFilteredModalDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [filtersSelectAll, setFiltersSelectAll] = useState({
    starRatings: false,
    sources: false,
    location: false,
    group: false,
  });
  const [searchBy, setSearchBy] = useState("");
  const timePeriod = [
    { id: 0, name: "All", value: "All" },
    { id: 1, name: "today", value: "Today" },
    { id: 2, name: "yesterday", value: "Yesterday" },
    { id: 3, name: "2days", value: "2days" },
    { id: 4, name: "7days", value: "7days" },
    { id: 5, name: "week", value: "week" },
    { id: 6, name: "14days", value: "14days" },
    { id: 7, name: "30days", value: "30days" },
    { id: 8, name: "monthValue", value: "month" },
    { id: 9, name: "60days", value: "60days" },
    { id: 10, name: "90days", value: "90days" },
    { id: 11, name: "6months", value: "6months" },
    { id: 12, name: "12months", value: "12months" },
    { id: 12, name: "custom_text", value: "custom" },
  ];

  useEffect(() => {
    console.log(isDataValid, "isDataValid");
    if (isDataValid) {
      if (languageData !== "en" || stateData?.language === null) {
        LanguageChange();
      }
    } else {
      //  handleSignOut();
      //  toast.error("Unauthorized Session");
    }
  }, []);

  useEffect(() => {
    if (checkedLocation) {
      let group = allGroups?.rows?.filter(
        (item) => item?.id === filters?.groups
      );
      let groupLocationIds = group[0]?.groups?.map((item) => item?.locationId);
      if (groupLocationIds !== undefined) {
        if (
          groupLocationIds?.every((element) =>
            filters.locations?.includes(element)
          ) === false
        ) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            groups: initialState?.groups,
            group: initialState?.group,
          }));
        }
      }
    }
  }, [filters]);

  const handleChangeAutoSelect = (event) => {
    setCheckedLocation(event);
    if (filters.group.length > 0) {
      if (event) {
        let locationIds = filters.group[0].groups.map(
          (item) => item.locationId
        );
        let newLocations = allLocation?.results?.filter((element) =>
          locationIds.includes(element.id)
        );
        let mergedLocations = [...newLocations, ...filters.location].reduce(
          (acc, current) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
        let mergedLocationIds = mergedLocations.map((item) => item.id);

        setFilters((prevFilters) => ({
          ...prevFilters,
          locations: mergedLocationIds,
          location: mergedLocations,
        }));
      } else {
        let locationIds = filters.group[0].groups.map(
          (item) => item.locationId
        );
        const filtered = filters.location.filter(
          (element) => !locationIds.includes(element.id)
        );
        const filteredIds = filtered.map((item) => item.id);
        setFilters((prevFilters) => ({
          ...prevFilters,
          locations: filteredIds,
          location: filtered,
        }));
      }
    }
  };

  /*  useEffect(() => {
    if (dateRange !== "Today") {
      setEmailBody(
        `Please find attached reviews report of ${companyData?.name}${
          startDate !== null && endDate !== null
            ? ` from  ${dayjs(startDate).format("MMM DD, YYYY")} to ${dayjs(
                endDate
              ).format("MMM DD, YYYY")}`
            : ""
        }`
      );
    } else {
      setEmailBody(
        `Please find attached reviews report of ${companyData?.name}${
          startDate !== null && endDate !== null
            ? ` from  ${dayjs(startDate).format("MMM DD, YYYY")}.`
            : ""
        }`
      );
    }
  }, [startDate, endDate]); */

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
      let filteredLocationPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredLocationPermissions?.map((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationViewPermission(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/tasks"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermissionTasks(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermissionTasks(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermissionTasks(true);
        }
        if (item?.permissionName === "read") {
          setViewPermissionTasks(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (openSchduleModal === true) {
      const delayDebounceFn = setTimeout(() => {
        getAllScheduledReports();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [scheduleReportFilters]);
  useEffect(() => {
    if (openSchduleModal === true) {
      getAllScheduledReports();
    }
  }, [scheduledRportsPageNumber, rowsPerPageScheduled, openSchduleModal]);

  useEffect(async () => {
    setReviewsLoading(true);
    getSelectedCustomer();
    getAllLocations();
    getAllGroups();
    getAllUsers();
    getAllGoogleLocation();
    getAllTemplate();
    getAllThirdPartySource();

    getAllTags();
    setSearchTerms("");
  }, []);
  useEffect(() => {
    if (isDataValid) {
      getLocationGoogleReviewFirstTime(false);
    }
  }, [isDataValid]);

  useEffect(() => {
    if (isDataValid) {
      if (callApi === true) {
        getLocationGoogleReview(true);
      }

      setScheduleGroups(selectedGroup);
      setScheduleLocations(locationId);
      setScheduleRatingValue(ratingsValue);
      setScheduleReviewSource(sourceId);
      setScheduleReply(selectedReply);
      setScheduleTags(selectedTags);
      setScheduleReviewerName(filterWithName);
      if (filterStartDate !== null) {
        setStartDate(filterStartDate);
      }

      if (filterEndDate !== null) {
        setEndDate(filterEndDate);
      }
    }
  }, [callApi]);

  useEffect(() => {
    if (locationValue.length > 0 && type === null) {
      getAllOldReviewReply();
    }
  }, [locationValue]);

  useEffect(() => {
    if (allGoogleLocation?.results?.length > 0) {
      let arr = [];
      const expiredLocation = allGoogleLocation?.results?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    }
  }, [allGoogleLocation]);

  useEffect(() => {
    const combinedArray = [...selectedItems, ...validEmailsArray];

    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  }, [selectedItems]);

  useEffect(() => {
    // Combine the arrays
    const combinedArray = [...dataArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );
    setSelectedItems(distinctEmails);
    // setTotalModalEmailsArr(distinctEmails);
  }, [dataArray]);

  const handleSubmit = () => {
    setPageNumber(0);
    let data = {};
    if (filters.groups.length > 0) {
      data["groupId"] = filters.groups.join();
    }

    if (filters.locations.length > 0) {
      data["filters[locationId][$equals]"] = filters.locations.join(",");
    }

    if (filteredDate.startDate !== "" && filteredDate.endDate !== "") {
      data[
        "filters[date][$between]"
      ] = `${filteredDate.startDate},${filteredDate.endDate}`;
    }

    if (filters.sources.length > 0) {
      data["filters[thirdPartyReviewSourcesId][$equals]"] =
        filters.sources.join(",");
    }

    if (filters.starRatings.length > 0) {
      data["filters[ratingValue][$equals]"] = filters.starRatings.join(",");
    }

    if (filters.reviewedBy !== "") {
      data["search"] = filters.reviewedBy.trim();
    }

    if (filters.tags.length > 0) {
      data["filters[reviewTags][tagId][$equals]"] = filters.tags.join(",");
    }

    if (filters.actions !== 2) {
      if (filters.actions === "true") {
        data["filters[isDraft][$equals]"] = filters.actions;
      } else {
        data["haventReplied"] = filters.actions;
      }
    }

    setDateRange(filters.timePeriod);

    setFilterObj(data);
    setSearchBy(filters.reviewedBy);
  };

  const handleChangeTime = (value) => {
    const handleFilterDate = (s, e) => {
      let start = moment()
        .subtract(s.amount, s.unit)
        .endOf(s.key)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment()
        .subtract(e.amount, e.unit)
        .startOf(e.key)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFilteredDate({ startDate: end, endDate: start });
      setFilteredModalDate({ startDate: end, endDate: start });
      return `${end},${start}`;
    };
    if (value === "All") {
      setFilteredDate({ startDate: "", endDate: "" });
      setFilteredModalDate({ startDate: "", endDate: "" });
      setDateRange("All");
      return "";
    } else if (value === "Today") {
      let start = moment()
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment()
        .endOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFilteredDate({ startDate: start, endDate: end });
      setFilteredModalDate({ startDate: start, endDate: end });
      setDateRange("Today");
      let str = `${start},${end}`;
      return `${str}`;
    } else if (value === "Yesterday") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 1, unit: "d", key: "day" };
      setDateRange("Yesterday");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "2days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 2, unit: "d", key: "day" };
      setDateRange("2days");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "7days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 7, unit: "d", key: "day" };
      setDateRange("7days");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "14days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 14, unit: "d", key: "day" };
      setDateRange("14days");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "30days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 30, unit: "d", key: "day" };
      setDateRange("30days");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "60days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 60, unit: "d", key: "day" };
      setDateRange("60days");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "90days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 90, unit: "d", key: "day" };
      setDateRange("90days");
      return `${handleFilterDate(start, end)}`;
    } else if (value === "week") {
      let today = moment();
      let mondayOfPreviousWeek = today
        .clone()
        .subtract(7, "days")
        .startOf("isoWeek")
        .toDate();
      let sundayOfPreviousWeek = today
        .clone()
        .subtract(7, "days")
        .endOf("isoWeek")
        .toDate();

      let start = moment(mondayOfPreviousWeek)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(sundayOfPreviousWeek)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      let str = `${start},${end}`;
      setFilteredDate({ startDate: start, endDate: end });
      setFilteredModalDate({ startDate: start, endDate: end });
      setDateRange("week");
      return `${str}`;
    } else if (value === "month") {
      let today = moment();

      let firstDayOfPreviousMonth = today
        .clone()
        .subtract(1, "month")
        .startOf("month")
        .toDate();
      let endDayOfPreviousMonth = today
        .clone()
        .subtract(1, "month")
        .endOf("month")
        .toDate();
      let start = moment(firstDayOfPreviousMonth)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(endDayOfPreviousMonth)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let str = `${start},${end}`;
      setFilteredDate({ startDate: start, endDate: end });
      setFilteredModalDate({ startDate: start, endDate: end });
      setDateRange("month");
      return `${str}`;
    } else if (value === "6months") {
      let today = moment();
      let sixMonthsAgo = today.clone().subtract(6, "months").startOf("month");
      let startOfPrevious6Months = sixMonthsAgo
        .clone()
        .subtract(1, "month")
        .startOf("month");
      let endOfPrevious6Months = today
        .clone()
        .subtract(1, "month")
        .endOf("month");

      let startDateOfPrevious6Months = sixMonthsAgo.toDate();
      let endDateOfPrevious6Months = endOfPrevious6Months.toDate();

      let start = moment(startDateOfPrevious6Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(endDateOfPrevious6Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      let str = `${start},${end}`;

      setFilteredDate({ startDate: start, endDate: end });
      setFilteredModalDate({ startDate: start, endDate: end });
      setDateRange("6months");
      return `${str}`;
    } else if (value === "12months") {
      let today = moment();

      let twelveMonthsAgo = today
        .clone()
        .subtract(12, "months")
        .startOf("month");

      let startOfPrevious12Months = twelveMonthsAgo
        .clone()
        .subtract(1, "month")
        .startOf("month");
      let endOfPrevious12Months = today
        .clone()
        .subtract(1, "month")
        .endOf("month");

      let startDateOfPrevious12Months = twelveMonthsAgo.toDate();
      let endDateOfPrevious6Months = endOfPrevious12Months.toDate();

      let start = moment(startDateOfPrevious12Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(endDateOfPrevious6Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      let str = `${start},${end}`;

      setFilteredDate({ startDate: start, endDate: end });
      setFilteredModalDate({ startDate: start, endDate: end });
      setDateRange("12months");
      return `${str}`;
    } else if (value === "custom") {
      let start = moment(previousYear)
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment()
        .endOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFilteredDate({ startDate: start, endDate: end });
      setFilteredModalDate({ startDate: start, endDate: end });
      setDateRange("custom");
      setSection("others");
      handleOpenEditUser();
      let str = `${start},${end}`;
      return `${str}`;
    }
  };

  /*  useEffect(() => {
    if (startDateSchedule !== null) {
      if (repeatValue !== null && repeatValue === "daily") {
        setEndDate(startDateSchedule);
        let newEndDate = new Date(startDateSchedule);

        const newStartDate = new Date(
          newEndDate.getTime() - 24 * 60 * 60 * 1000
        );
        setStartDate(newStartDate);

        setEmailSubject(
          `Reviews management report of ${companyData?.name}${
            startDate !== null && endDate !== null
              ? ` from  ${dayjs(startDate).format("MMM DD, YYYY")} to ${dayjs(
                  endDate
                ).format("MMM DD, YYYY")}`
              : ""
          }`
        );
      } else if (repeatValue !== null && repeatValue === "weekly") {
        setEndDate(startDateSchedule);
        let newEndDateTwo = new Date(startDateSchedule);

        const newStartDate = new Date(newEndDateTwo.getTime());
        newStartDate.setDate(newStartDate.getDate() - 7); // Subtracting 7 days (1 week)

        setStartDate(newStartDate);

        setEmailSubject(
          `Reviews management report of ${companyData?.name}${
            startDate !== null && endDate !== null
              ? ` from  ${dayjs(startDate).format("MMM DD, YYYY")} to ${dayjs(
                  endDate
                ).format("MMM DD, YYYY")}`
              : ""
          }`
        );
      } else if (repeatValue !== null && repeatValue === "monthly") {
        setEndDate(startDateSchedule);
        let newEndDateThree = new Date(startDateSchedule);

        const newStartDate = new Date(newEndDateThree.getTime());
        newStartDate.setMonth(newStartDate.getMonth() - 1); // Subtracting 1 month

        setStartDate(newStartDate);

        setEmailSubject(
          `Reviews management report of ${companyData?.name}${
            startDate !== null && endDate !== null
              ? ` from  ${dayjs(startDate).format("MMM DD, YYYY")} to ${dayjs(
                  endDate
                ).format("MMM DD, YYYY")}`
              : ""
          }`
        );
      } else if (repeatValue !== null && repeatValue === "yearly") {
        setEndDate(startDateSchedule);
        let newEndDateFour = new Date(startDateSchedule);

        const newStartDate = new Date(newEndDateFour.getTime());
        newStartDate.setFullYear(newStartDate.getFullYear() - 1); // Subtracting 1 year

        setStartDate(newStartDate);

        setEmailSubject(
          `Reviews management report of ${companyData?.name}${
            startDate !== null && endDate !== null
              ? ` from  ${dayjs(startDate).format("MMM DD, YYYY")} to ${dayjs(
                  endDate
                ).format("MMM DD, YYYY")}`
              : ""
          }`
        );
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }
  }, [repeatValue, startDateSchedule, endDateSchedule]); */

  const handleOpenEditUser = () => {
    setOpenModalFilter(true);
  };
  const handleChangeCheckButton = (e) => {
    setChecked(e.target.checked);
    setSelectedLocation([]);
  };
  const handleReviewCheckbox = (e) => {
    setReviewChecked(e.target.checked);
  };
  const getAllGroups = async () => {
    // setLocationLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);

        // setLocationLoading(false);
      }
    } catch (error) {
      //  setLocationLoading(false);
      setAllGroups(null);
    }
  };
  const getAllScheduledReports = async () => {
    setScheduledReportsLoading(true);
    try {
      const status = scheduleReportFilters?.status?.includes("repeat")
        ? scheduleReportFilters.status + ",pending"
        : scheduleReportFilters.status;
      const res = await api.get(
        `reportSchedules?page=${
          scheduledRportsPageNumber + 1
        }&limit=${rowsPerPageScheduled}&locationIds=&reportType=Reviews&searchTerm=${
          scheduleReportFilters.searchVal
        }&status=${scheduleReportFilters?.status}`
      );
      if (res.status === 200) {
        setScheduledReports(res?.data?.data);
        setScheduledReportsLoading(false);
      }
    } catch (error) {
      if (
        scheduleReportFilters.searchVal === "" &&
        scheduleReportFilters.status === ""
      ) {
        setScheduledReports([]);
      } else {
        setScheduledReports(null);
      }
      setScheduledReportsLoading(false);
    }
  };
  const getSingleSelectedReportById = async () => {
    if (selectedEditId !== null) {
      setSingleScheduledReportsLoading(true);
      try {
        const res = await api.get(`reportSchedules/${selectedEditId}`);
        if (res.status === 200) {
          setSingleReportData(res?.data?.data);
          setSingleScheduledReportsLoading(false);
        }
      } catch (error) {
        setSingleScheduledReportsLoading(false);
        setSingleReportData(null);
      }
    }
  };

  const starRatings = [
    { id: 1, name: "1_star", value: 1 },
    { id: 2, name: "2_star", value: 2 },
    { id: 3, name: "3_star", value: 3 },
    { id: 4, name: "4_star", value: 4 },
    { id: 5, name: "5_star", value: 5 },
  ];

  const haventReplied = [
    { name: t("Haven't_Replied"), value: 1 },
    { name: `${t("Replied")}`, value: 0 },

    /*  { name: `${t("Haven't_Drafted")}`, value: "false" }, */
    { name: `${t("Drafted_Action")}`, value: "true" },
    { name: `${t("Alll")}`, value: 2 },
  ];
  const handleApplyFilter = () => {
    handleSubmit();
    if (callApi === true) {
      setCallApi(false);
    } else {
      setCallApi(true);
    }
  };

  const handleOpenFetchReviewsMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseFetchReviewsMenu = () => {
    setOpenDefaultModel(false);
  };

  const handleOpenOldReviewsMenu = () => {
    setOpenOldReviewModel(true);
  };
  const handleCloseOldReviewsMenu = () => {
    setOpenOldReviewModel(false);
    setLocationValue([defaultvalueLocationForPastReviews]);
    setDisplayWarningMessage(false);
    setReviewCountValue("");
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };
  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
        getAllThirdPartySourceForReviewAgent(res?.data.data);
      }
    } catch (error) {}
  };

  const getAllGoogleLocation = async () => {
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/googleLinkedlocations`, {
        params: {
          limit: 300,
        },
      });
      if (res.status === 200) {
        setAllGoogleLocation(res.data.data);
        let defaultlocation = res.data.data.results?.find(
          (item, index) => item.defaultLocation !== null
        );
        if (defaultlocation !== undefined) {
          setLocationValue([defaultlocation]);
          setDefaultvalueLocationForPastReviews(defaultlocation);
        } else {
          const earliestSubscription = res?.data?.data?.results?.reduce(
            (earliest, current) => {
              return new Date(current.subscriptionDate) <
                new Date(earliest.subscriptionDate)
                ? current
                : earliest;
            }
          );
          setLocationValue([earliestSubscription]);
          setDefaultvalueLocationForPastReviews(earliestSubscription);
        }
      }
    } catch (error) {}
  };
  const getAllLocations = async () => {
    try {
      setIsLoading(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
        // setReviewsData(res.data.data);
        setIsLoading(false);
        let defaultlocation = null;
        if (res.data.data.results.length > 0) {
          defaultlocation = res.data.data.results?.find(
            (item, index) => item.defaultLocation !== null
          );
          setDefaultvalueLocation(defaultlocation);
        } else {
          setDefaultvalueLocation(null);
        }

        let socialLinktext = true;
        for (var i = 0; i < res.data.data?.results.length; i++) {
          if (res.data.data?.results[i].socialLink.length > 0) {
            socialLinktext = false;
          }
        }
        if (socialLinktext) {
          setsociallink(true);
        } else {
          setsociallink(false);
        }
      } else {
        toast.error(t("No_location_found"));
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data /* + "&state=" + id */;
      }
    } catch (error) {}
  }
  const handleConnectWithGoogle = () => {
    fetchURL();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        let data;
        if (file.type === "text/csv") {
          const lines = fileContents.split("\n");
          data = lines.map((line) => line.replace("\r", "").split(","));
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContents, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          data = [];
          sheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            data.push(...jsonData);
          });
        } else {
          return;
        }
        const flatData = data.flat();

        // setTimeout(() => {
        //   setFileLoading(false);
        // }, 1000);
        setDataArray(flatData);
      };
      // setFileLoading(true);

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(file);
      }

      setSelectedFile(file.name);
      // } else {
      //   setTimeout(() => {
      //     setFileLoading(false);
      //   }, 1000);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const getAllTags = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/tags`);
      if (res.status === 200) {
        setTags(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllReviewSource(res.data.data);
        let tempSelected;
        res?.data.data?.results?.map((item) => {
          if (item?.name === "google") {
            tempSelected = item?.id;
          }
        });
      }
    } catch (error) {}
  };
  const getAllThirdPartySourceForReviewAgent = async (data) => {
    try {
      const res = await api.get(
        `/thirdPartyReviewSource/getByCompany/${data?.id}`,
        {
          params: {
            limit: 100,
          },
        }
      );
      if (res.status === 200) {
        if (res.data.data?.length > 0) {
          let arr = [];
          let google = [
            {
              name: "google",
              edit: true,
              delete: true,
            },
          ];
          res.data.data?.map((data) => {
            data?.reviewSource?.map((item) => {
              if (item?.enableReviewAgent === true) {
                const sourceName = data?.name;
                const filterSource = sourcesList?.find(
                  (source) => source?.name === sourceName
                );
                const obj = { ...item };
                obj.name = sourceName;
                obj.edit = filterSource?.edit;
                obj.delete = filterSource?.delete;
                obj.reviewAgent = filterSource?.reviewAgent;
                arr.push(obj);
              }
            });
          });
          setEnabledReviewSources([...arr, ...google]);
        }
        setAllReviewSourceForAgent(res.data.data);
      }
    } catch (error) {}
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`user/search`);
      if (res.status === 200) {
        setUsers(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllTemplate = async () => {
    try {
      const res = await api.get(`/template`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllTemplate(res.data.data);
      }
    } catch (error) {}
  };

  const handleDisplayLocationName = (locationId) => {
    const selectedData = allLocation?.results?.find(
      (item) => item?.id === locationId
    );

    return (
      selectedData?.name +
      (selectedData?.city !== null ? "(" + selectedData?.city + ")" : "")
    );
  };
  const handleDisplayReviewSourceName = (sourceId) => {
    const itemReviewSource = allReviewSource?.results?.find(
      (data) => data?.id === sourceId
    );
    return (
      itemReviewSource?.name?.charAt(0).toUpperCase() +
      itemReviewSource?.name.slice(1)
    );
  };
  const getLocationGoogleReview = async (newParams) => {
    if (callApi === true) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("New request is made");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      try {
        setReviewsLoading(true);

        // Merge previous parameters with new parameters
        const mergedParams = { ...filterObj };

        const res = await api.get(`/review?limit=20&page=${pageNumber + 1}`, {
          params: mergedParams,
          cancelToken: newCancelTokenSource.token,
        });

        if (res.status < 400 && res.data.data?.results?.length > 0) {
          //  setReviewsData(res.data.data);
          let response = res?.data?.data;
          response?.results?.map((item) => {
            item["replyToEdit"] = item?.reviewReply;
          });

          setReviewsData(response);

          // setPageNumber(pageNumber + 1);
          setReviewsLoading(false);
          setCallApi(false);
        } else {
          setReviewsData([]);
          setReviewsLoading(false);
          setCallApi(false);
        }
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          setReviewsData([]);
          setCallApi(false);
          setReviewsLoading(false);
        }
      }
    }
  };

  const getLocationGoogleReviewFirstTime = async (newParams) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("New request is made");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    try {
      setReviewsLoading(true);

      // Merge previous parameters with new parameters
      const mergedParams = { ...filterObj };

      const res = await api.get(`/review?limit=20&page=${pageNumber + 1}`, {
        params: mergedParams,
        cancelToken: newCancelTokenSource.token,
      });

      if (res.status < 400 && res.data.data?.results?.length > 0) {
        let response = res?.data?.data;
        response?.results?.map((item) => {
          item["replyToEdit"] = item?.reviewReply;
        });

        setReviewsData(response);

        // setPageNumber(pageNumber + 1);
        setReviewsLoading(false);
        setCallApi(false);
      } else {
        setReviewsData([]);
        setReviewsLoading(false);
        setCallApi(false);
      }
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setReviewsData([]);
        setCallApi(false);
        setReviewsLoading(false);
      }
    }
  };

  const getAllOldReviewReply = async () => {
    try {
      setOldReviewLoading(true);
      const res = await api.get(
        `oldReviewReply/total/non-replied/reviews?locationId=${
          locationValue[0]?.id
        }&language=${
          i18nextLng === "pt_PT"
            ? "pt-PT"
            : i18nextLng === "pt_BR"
            ? "pt-BR"
            : i18nextLng
        }`
      );

      if (res.status === 200) {
        setReviewCountValue(res?.data?.data);
        setReviewMessage(res?.data?.message);
        setDisplayWarningMessage(true);
      }
      setOldReviewLoading(false);
    } catch (error) {
      setOldReviewLoading(false);
    }
  };

  const refreshTags = async (locationId) => {
    await getLocationGoogleReview();
    await getAllTags();
  };

  const handleSourceChange = (value) => {
    let tempfilterObj = { ...filterObj };
    if (value?.length > 0) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("New request is made");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"
      setPageNumber(0);

      tempfilterObj["filters[thirdPartyReviewSourcesId][$equals]"] = ids || "";

      const newParams = {
        "filters[thirdPartyReviewSourcesId]": ids || "",
      };
      const newParamsForRewviews = {
        "filters[thirdPartyReviewSourcesId][$equals]": ids || "",
      };

      getLocationGoogleReview(newParamsForRewviews);
      setNewSourceDownload(newParams);
    } else {
      delete tempfilterObj["filters[thirdPartyReviewSourcesId][$equals]"];
      setPageNumber(0);
    }

    setFilterObj(tempfilterObj);
    setsourceId(value);
  };

  const fetchMoreData = async () => {
    if (reviewsData?.results?.length < reviewsData?.count) {
      try {
        const res = await api.get(`/review?limit=20&page=${pageNumber + 2}`, {
          params: { ...filterObj },
        });
        if (res.status === 200) {
          // setReviewsData((prev) => {});
          let data;
          let tempReviews = reviewsData?.results;
          data = { ...res.data.data };
          let response = res?.data?.data?.results;
          response?.map((item) => {
            item["replyToEdit"] = item?.reviewReply;
          });

          //  setReviewsData(response);
          data["results"] = [...tempReviews, ...response];
          setReviewsData(data);
          setPageNumber(pageNumber + 1);
        } else {
          setReviewsData([]);
        }
      } catch (error) {
        setReviewsData([]);
      }
    }
  };

  const addMoreReviewSource = () => {
    navigate("/user/reviewSource");
  };
  const handleChangeRatings = (value) => {
    let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"

    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[ratingValue][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setRatingsValue(value);
    setPageNumber(0);
    const newParams = {
      "filters[ratingValue]": ids || "",
    };

    setNewSourceDownload(newParams);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleChangeFilterWithName();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  const handleChangeFilterWithName = () => {
    let tempfilterObj = { ...filterObj };
    if (filterWithName.length > 0) {
      tempfilterObj["reviewerTitle"] = filterWithName || "";
      setPageNumber(0);

      setFilterObj(tempfilterObj);
    } else {
      delete tempfilterObj["reviewerTitle"];
      setFilterObj(tempfilterObj);
    }
  };

  const replyChangeHandler = (value) => {
    let tempfilterObj = {
      ...filterObj,
    };

    if (value?.name !== "All") {
      if (value?.name === "Drafted") {
        if (tempfilterObj.hasOwnProperty("haventReplied")) {
          delete tempfilterObj.haventReplied;
        }
        /*   let draftObj = [];
      draftObj[`filters[isDraft][$equals]`] = value?.value; */
        setDraftState(true);
      } else {
        tempfilterObj["haventReplied"] = value?.value;
        // tempfilterObj[`filters[isDraft][$equals]`] = "false";
        setDraftState(false);
      }
    } else {
      delete tempfilterObj["haventReplied"];
    }

    setFilterObj(tempfilterObj);
    setSelectedReply(value);
  };

  const tagsChangeHandler = (value) => {
    let tempfilterObj = { ...filterObj };
    if (value?.length > 0) {
      let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"

      tempfilterObj["filters[reviewTags][tagId][$equals]"] = ids || "";
    } else {
      delete tempfilterObj["filters[reviewTags][tagId][$equals]"];
    }

    setFilterObj(tempfilterObj);
    setSelectedTags(value);
    setPageNumber(0);
  };

  const handleLocationChange = (value) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("New request is made");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    let ids = Array.prototype.map.call(value, (s) => s.id).toString();
    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[locationId][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setLocationId(value);
    setPageNumber(0);

    if (value?.length > 0) {
      let arr = [];
      const expiredLocation = value?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    } else {
      let tempfilterObj = { ...filterObj };
      delete tempfilterObj["filters[locationId][$equals]"];
      setFilterObj(tempfilterObj);
      setDisplayReconnectError(false);
    }

    // Call the updated function with the new cancel token source

    const newParams = {
      "filters[locationId]": ids || "",
    };
    const newParamsForReviews = {
      "filters[locationId][$equals]": ids || "",
    };

    getLocationGoogleReview(newParamsForReviews);
    setNewSourceDownload(newParams);
  };
  const handleAddNewUserEmailsChange = (value) => {
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setAddNewUserEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
        setValidEmailsArray(validEmailsArray);
      }
    }

    const combinedArray = [...selectedItems, ...validEmailsArray];

    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRemoveFile = () => {
    setDataArray([]);
    setSelectedFile(null);
    setSelectedItems([]);
  };

  const handleChangeDateRange = (rangeValue, boolValue) => {
    const value = boolValue === true ? rangeValue : rangeValue?.target.value;
    setFiltersModal((prevFilters) => ({
      ...prevFilters,
      timePeriod: value,
      time: timePeriod.find((item) => item.value === value),
    }));
    setDateRange(value);
    if (startDateSchedule !== null && startDateSchedule !== "") {
      let date = dayjs(startDateSchedule);
      let oneDaysBefore = date.subtract(1, "day").endOf("day");
      let oneDaysBeforeDateEnd = oneDaysBefore.toDate();

      if (value === "All") {
        setStartDate(null);
        setEndDate(null);
      } else if (value === "Yesterday") {
        let date = dayjs(startDateSchedule);
        let oneDaysBefore = date.subtract(1, "day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();

        setStartDate(oneDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);
        setFilteredModalDate({
          startDate: oneDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneDaysBeforeDate !== null && endDate !== null
              ? ` from  ${formatDate(oneDaysBeforeDate)}.`
              : ""
          }`
        );
      } else if (value === "Today") {
        let date = dayjs(startDateSchedule);
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();

        setStartDate(oneDaysBeforeDate);
        setEndDate(startDateSchedule);
        setFilteredModalDate({
          startDate: oneDaysBeforeDate,
          endDate: startDateSchedule,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneDaysBeforeDate !== null && endDate !== null
              ? ` from  ${formatDate(oneDaysBeforeDate)}.`
              : ""
          }`
        );
      } else if (value === "2days") {
        let date = dayjs(startDateSchedule);
        let twoDaysBefore = date.subtract(2, "day").startOf("day");
        let twoDaysBeforeDate = twoDaysBefore.toDate();

        setStartDate(twoDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);
        setFilteredModalDate({
          startDate: twoDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            twoDaysBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(twoDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDateEnd
                )}`
              : ""
          }`
        );
      } else if (value === "7days") {
        let date = dayjs(startDateSchedule);
        let sevenDaysBefore = date.subtract(7, "day").startOf("day");
        let sevenDaysBeforeDate = sevenDaysBefore.toDate();

        setStartDate(sevenDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);
        setFilteredModalDate({
          startDate: sevenDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            sevenDaysBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(sevenDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDateEnd
                )}`
              : ""
          }`
        );
      } else if (value === "week") {
        let date = dayjs(startDateSchedule);

        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate.subtract(
          weekBeforeSelectedDate.day() - 1,
          "day"
        );
        let followingSunday = previousMonday.add(6, "day");

        setStartDate(previousMonday.toDate());
        setEndDate(followingSunday?.toDate());

        setFilteredModalDate({
          startDate: previousMonday.toDate(),
          endDate: followingSunday?.toDate(),
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            previousMonday !== null && startDateSchedule !== null
              ? ` from  ${formatDate(previousMonday)} to ${formatDate(
                  followingSunday
                )}`
              : ""
          }`
        );
      } else if (value === "month") {
        let date = dayjs(startDateSchedule);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");

        setStartDate(startOfMonthBefore.toDate());
        setEndDate(endOfMonthBefore?.toDate());

        setFilteredModalDate({
          startDate: startOfMonthBefore.toDate(),
          endDate: endOfMonthBefore?.toDate(),
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            startOfMonthBefore !== null && endOfMonthBefore !== null
              ? ` from  ${formatDate(startOfMonthBefore)} to ${formatDate(
                  endOfMonthBefore
                )}`
              : ""
          }`
        );
      } else if (value === "14days") {
        let date = dayjs(startDateSchedule);
        let fourteenDaysBefore = date.subtract(14, "day").startOf("day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.toDate();

        setStartDate(fourteenDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: fourteenDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            fourteenDaysBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(fourteenDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDateEnd
                )}`
              : ""
          }`
        );
      } else if (value === "30days") {
        let date = dayjs(startDateSchedule);
        let oneMonthBefore = date.subtract(30, "day").startOf("day");
        let oneMonthBeforeDate = oneMonthBefore.toDate();

        setStartDate(oneMonthBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: oneMonthBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneMonthBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(oneMonthBeforeDate)} to ${formatDate(
                  oneDaysBeforeDateEnd
                )}`
              : ""
          }`
        );
      } else if (value === "60days") {
        let date = dayjs(startDateSchedule);

        let sixtyDaysBefore = date.subtract(60, "day").startOf("day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.toDate();

        setStartDate(sixtyDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: sixtyDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            sixtyDaysBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(sixtyDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDateEnd
                )}`
              : ""
          }`
        );
      } else if (value === "90days") {
        let date = dayjs(startDateSchedule);

        let ninetyDaysBefore = date.subtract(90, "day").startOf("day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.toDate();

        setStartDate(ninetyDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: ninetyDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });

        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            ninetyDaysBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(ninetyDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDateEnd
                )}`
              : ""
          }`
        );
      } else if (value === "6months") {
        let date = dayjs(startDateSchedule);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore?.toDate());

        let sixMonthsBefore = date.subtract(6, "months").startOf("month");
        let sixMonthsBeforeDate = sixMonthsBefore.toDate();

        setStartDate(sixMonthsBeforeDate);

        setFilteredModalDate({
          startDate: sixMonthsBeforeDate,
          endDate: endOfMonthBefore?.toDate(),
        });

        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            sixMonthsBeforeDate !== null && sixMonthsBeforeDate !== null
              ? ` from  ${formatDate(sixMonthsBeforeDate)} to ${formatDate(
                  endOfMonthBefore
                )}`
              : ""
          }`
        );
      } else if (value === "12months") {
        let date = dayjs(startDateSchedule);

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore?.toDate());

        let oneYearBefore = date.subtract(12, "month").startOf("month");
        let oneYearBeforeDate = oneYearBefore.toDate();
        setFilterStartDate(oneYearBeforeDate);
        setStartDate(oneYearBeforeDate);

        setFilteredModalDate({
          startDate: oneYearBeforeDate,
          endDate: endOfMonthBefore?.toDate(),
        });
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneYearBeforeDate !== null && startDateSchedule !== null
              ? ` from  ${formatDate(oneYearBeforeDate)} to ${formatDate(
                  endOfMonthBefore
                )}`
              : ""
          }`
        );
      } else if (value === "custom") {
        const today = new Date();
        const previousYear = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        setEndDate(today);
        setStartDate(previousYear);

        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            filterStartDate !== null && filterEndDate !== null
              ? ` from  ${formatDate(filterStartDate)} to ${formatDate(
                  filterEndDate
                )}`
              : ""
          }`
        );
      }
    }
    if (
      filteredModalDate.startDate === "" &&
      filteredModalDate.endDate === ""
    ) {
      const today = new Date();
      const previousYear = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      setFilteredModalDate({
        startDate: previousYear,
        endDate: today,
      });
    }
  };
  const handleChangeRangeEndDateSchedule = (e) => {
    setFilteredModalDate((prev) => ({
      ...prev,
      endDate: e,
    }));
    setEndDate(e);
  };
  const handleChangeRangeStartDateSchedule = (e) => {
    setFilteredModalDate((prev) => ({
      ...prev,
      startDate: e,
    }));
    setStartDate(e);
  };

  useEffect(() => {
    if (
      filteredModalDate.startDate !== "" &&
      filteredModalDate.endDate !== ""
    ) {
      setStartDate(filteredModalDate.startDate);
      setEndDate(filteredModalDate.endDate);
      setEmailBody(
        `Please find attached reviews report of ${companyData?.name}${
          startDate !== null && endDate !== null
            ? ` from  ${formatDate(startDate)} to ${formatDate(endDate)}`
            : ""
        }`
      );
    }
  }, [filteredModalDate, startDate, endDate]);

  // const handleChangeTime = (value) => {
  //   let date;

  //   let tempfilterObj = { ...filterObj };
  //   if (value !== null) {
  //     setDateRange(value.periodValue);
  //     if (value?.periodValue==="All") {
  //       delete tempfilterObj["filters[date][$gte]"];
  //       delete tempfilterObj["filters[date][$between]"];
  //     } else {
  //       if (
  //         value.periodValue !== "week" &&
  //         value.periodValue !== "month" &&
  //         value.periodValue !== "custom"
  //       ) {
  //         delete tempfilterObj["filters[date][$between]"];
  //         if (value.periodValue==="Yesterday") {
  //           date = moment()
  //             .subtract(1, "d")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="Today") {
  //           date = moment()
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="2days") {
  //           date = moment()
  //             .subtract(2, "d")
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="7days") {
  //           date = moment()
  //             .subtract(7, "d")
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="14days") {
  //           date = moment()
  //             .subtract(14, "d")
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="30days") {
  //           date = moment()
  //             .subtract(30, "d")
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="60days") {
  //           date = moment()
  //             .subtract(60, "d")
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="90days") {
  //           date = moment()
  //             .subtract(90, "d")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="6months") {
  //           date = moment()
  //             .subtract(6, "months")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         } else if (value.periodValue==="12months") {
  //           date = moment()
  //             .subtract(12, "months")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         }
  //         tempfilterObj["filters[date][$gte]"] = `${date}` || "";
  //         setStartDate(date);
  //       } else if (value.periodValue==="custom") {
  //         if (endDate !== null) {
  //           delete tempfilterObj["filters[date][$gte]"];
  //           let todayDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //           setFilterStartDate(previousYear);
  //           setFilterEndDate(today);
  //           setStartDate(previousYear);
  //           setEndDate(today);
  //           let previousYearDate = moment(previousYear)
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //           let str = `${previousYearDate},${todayDate}`;
  //           tempfilterObj["filters[date][$between]"] = `${str}` || "";
  //         } else {
  //           delete tempfilterObj["filters[date][$between]"];
  //           date = moment()
  //             .startOf("day")
  //             .utc()
  //             .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //           /*    setFilterStartDate(previousYear);
  //            setFilterEndDate(today);
  //            setStartDate(previousYear);
  //            setEndDate(today);
  //            let previousYearDate = moment(previousYear)
  //              .utc()
  //              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //            let str = `${previousYearDate},${todayDate}`; */
  //           //  tempfilterObj["filters[date][$between]"] = `${str}` || "";
  //         }
  //       } else if (value.periodValue==="week") {
  //         delete tempfilterObj["filters[date][$gte]"];
  //         let today = moment();
  //         let mondayOfPreviousWeek = today
  //           .clone()
  //           .subtract(7, "days")
  //           .startOf("isoWeek")
  //           .toDate();
  //         let sundayOfPreviousWeek = today
  //           .clone()
  //           .subtract(7, "days")
  //           .endOf("isoWeek")
  //           .toDate();

  //         let startDateOfWeek = moment(mondayOfPreviousWeek)
  //           .utc()
  //           .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         let endDateOfWeek = moment(sundayOfPreviousWeek)
  //           .utc()
  //           .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  //         let str = `${startDateOfWeek},${endDateOfWeek}`;
  //         tempfilterObj["filters[date][$between]"] = `${str}` || "";
  //         setStartDate(startDateOfWeek);
  //         setEndDate(endDateOfWeek);
  //       } else if (value.periodValue==="month") {
  //         delete tempfilterObj["filters[date][$gte]"];
  //         // Go back to the first day of the current month and then subtract a month to get the 1st day of the previous month
  //         let today = moment(); // Get the current date

  //         // Go to the 1st day of the current month and then subtract a month to get the 1st day of the previous month
  //         let firstDayOfPreviousMonth = today
  //           .clone()
  //           .subtract(1, "month")
  //           .startOf("month")
  //           .toDate();
  //         let endDayOfPreviousMonth = today
  //           .clone()
  //           .subtract(1, "month")
  //           .endOf("month")
  //           .toDate();
  //         let startDateOfMonth = moment(firstDayOfPreviousMonth)
  //           .utc()
  //           .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         let endDateOfMonth = moment(endDayOfPreviousMonth)
  //           .utc()
  //           .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //         let str = `${startDateOfMonth},${endDateOfMonth}`;
  //         tempfilterObj["filters[date][$between]"] = `${str}` || "";
  //         setStartDate(startDateOfMonth);
  //         setEndDate(endDateOfMonth);
  //       }
  //     }
  //     setFilterObj(tempfilterObj);
  //     setAllTimePeriod(value);
  //   } else {
  //     setAllTimePeriod({ name: "All", value: 0, periodValue: "All" });
  //     setDateRange("All");
  //     setStartDate(null);
  //     setEndDate(null);
  //     delete tempfilterObj["filters[date][$gte]"];
  //     setFilterObj(tempfilterObj);
  //   }
  // };
  const handleChangeStartDate = (e) => {
    if (e !== null && endDate !== null) {
      let tempfilterObj = { ...filterObj };

      setFilterStartDate(e);
      setStartDate(e);

      let startDateOfMonth = dayjs(e).startOf("day");
      let formatedStartDate = startDateOfMonth.toISOString();

      let endDateOfMonth = dayjs(endDate).endOf("day");
      let formatedEndDate = endDateOfMonth.toISOString();

      let str = `${formatedStartDate},${formatedEndDate}`;
      tempfilterObj["filters[date][$between]"] = str || "";

      setFilterObj(tempfilterObj);
    }
  };
  const handleChangeEndDate = (e) => {
    let tempfilterObj = { ...filterObj };
    setFilterEndDate(e);
    setEndDate(e);

    if (e !== null && startDate !== null) {
      let startDateOfMonth = dayjs(startDate).startOf("day");
      let formatedStartDate = startDateOfMonth.toISOString();

      let endDateOfMonth = dayjs(e).endOf("day");
      let formatedEndDate = endDateOfMonth.toISOString();

      let str = `${formatedStartDate},${formatedEndDate}`;
      tempfilterObj["filters[date][$between]"] = str || "";

      setFilterObj(tempfilterObj);
    }
  };

  const handleCloseModalFilter = () => {
    setOpenModalFilter(false);
  };
  const handleIgnoreButton = () => {
    setsociallink(false);
  };
  const resetHandler = () => {
    setFilters(initialState);
    setFiltersModal(initialState);
    setFiltersSelectAll({
      starRatings: false,
      sources: false,
      location: false,
      group: false,
    });
    setCheckedLocation(false);
    setFilterObj({});
    setSearchBy("");
    setLocationId([]);
    setCallApi(true);
    setPageNumber(0);
    setDraftState(false);
    setsourceId(null);
    setSelectedTags(null);
    setSelectedReply(null);
    setRatingsValue([]);
    setAllTimePeriod({ name: "All", value: 0, periodValue: "All" });
    setSelectedGroup(null);
    setFilterWithName("");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };
  /*  const handleGoogleLocationChange = (option) => {
    setLocationId(option);
  }; */
  const getOldReviewData = async () => {
    let locationIds = [];
    if (selectedLocation.length > 0) {
      selectedLocation?.map((location) => {
        locationIds.push(location?.id);
      });
    }
    try {
      setDumpReviewsLoading(true);
      const res = await api.get(
        `google/dumpReviews?locationIds=${
          locationIds ? locationIds : ""
        }&all=${checked}&oldReviewsReply=${"false"}`
      );
      if (res.status === 200) {
        setDumpReviewsLoading(false);
        setOpenDefaultModel(false);
        setChecked(true);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setDumpReviewsLoading(false);
      setChecked(false);
      setOpenDefaultModel(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const getOldReviewReplyProgress = async () => {
    let locationIds = [];
    if (locationValue?.length > 0) {
      locationValue?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    try {
      setProgressLoading(true);
      const res = await api.post(`oldReviewReply/inprogress`, {
        locationIds: locationIds,
      });
      if (res.status === 200) {
        setProgressLoading(false);
        setOpenOldReviewModel(false);
        setLocationValue([]);
        getAllGoogleLocation();
        handleCloseBulkStartModel();
        handleCloseDeleteModel();
        handleCloseBulkStopModel();
        setReviewCountValue("");
        setDisplayWarningMessage(false);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setProgressLoading(false);
      setOpenOldReviewModel(false);
      setLocationValue([]);
      handleCloseDeleteModel();
      setReviewCountValue("");
      handleCloseBulkStartModel();
      handleCloseBulkStopModel();
      setDisplayWarningMessage(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handlePauseOldReviewReply = async () => {
    let locationIds = [];
    if (locationValue?.length > 0) {
      locationValue?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    try {
      setProgressLoading(true);
      const res = await api.patch(`location/pause-old-reviews-reply`, {
        locationIds: locationIds,
      });
      if (res.status === 200) {
        setProgressLoading(false);
        setOpenOldReviewModel(false);
        // setLocationValue([]);
        handleCloseBulkStartModel();
        handleCloseBulkStopModel();
        getAllGoogleLocation();
        handleCloseDeleteModel();
        setReviewCountValue("");
        setDisplayWarningMessage(false);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setProgressLoading(false);
      setOpenOldReviewModel(false);
      //  setLocationValue([]);
      handleCloseBulkStartModel();
      handleCloseBulkStopModel();
      handleCloseDeleteModel();
      setReviewCountValue("");
      setDisplayWarningMessage(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOpenTemplateMenu = () => {
    setOpenIframeModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };

  const pauseReplyHandler = async () => {
    try {
      setProgressLoading(true);
      const res = await api.patch(`/location/${locationValue[0]?.id}`, {
        oldReviewsReplyStatus: "paused",
      });
      getAllGoogleLocation();
      if (res.status === 200) {
        setProgressLoading(false);
        setOpenOldReviewModel(false);
        // setLocationValue(null);
        setReviewCountValue(res?.data?.data);
        setDisplayWarningMessage(false);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setProgressLoading(false);
      setOpenOldReviewModel(false);
      //  setLocationValue([]);
      setReviewCountValue("");
      setDisplayWarningMessage(false);
      // toast.error(error?.response?.data?.message);
    }
  };
  const startReplyHandler = async () => {
    try {
      setProgressLoading(true);
      const res = await api.patch(`/location/${locationValue?.id}`, {
        oldReviewsReplyStatus: "inprogress",
      });
      getAllGoogleLocation();
      if (res.status === 200) {
        setProgressLoading(false);
        setOpenOldReviewModel(false);
        // setLocationValue(null);
        setReviewCountValue(res?.data?.data);
        setDisplayWarningMessage(false);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setProgressLoading(false);
      setOpenOldReviewModel(false);
      setLocationValue([]);
      setReviewCountValue("");
      setDisplayWarningMessage(false);
      // toast.error(error?.response?.data?.message);
    }
  };

  const handleOpenBulkStartModel = () => {
    setType("start");
    setOpenBulkStartModel(true);
    handleCloseOldReviewsMenu();
  };
  const handleCloseBulkStartModel = () => {
    setType(null);
    setOpenBulkStartModel(false);
    setLocationValue([defaultvalueLocationForPastReviews]);
  };

  const handleOpenBulkStopModel = () => {
    setType("stop");
    setOpenBulkStopModel(true);
    handleCloseOldReviewsMenu();
  };
  const handleCloseBulkStopModel = () => {
    setType(null);
    setOpenBulkStopModel(false);
    setLocationValue([defaultvalueLocationForPastReviews]);
  };
  const handleOpenDeleteModel = (id) => {
    // setAnchorEl(null);
    setOpenDeleteModel(true);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };
  const handleClose = () => {
    //  setOpen(false);
    setAnchorEl(null);
    setAnchorBulk(null);
  };

  const handleChangeStartDateSchedule = (e) => {
    setStartDateSchedule(e);
    if (e !== null && e !== "") {
      let date = dayjs(e);
      let oneDaysBefore = date.subtract(1, "day").endOf("day");
      let oneDaysBeforeDateEnd = oneDaysBefore.toDate();
      /*   if (
        dateRange !== "month" &&
        dateRange !== "week" &&
        dateRange !== "custom"
      ) {
        setEndDate(e);
      } */

      if (dateRange === "All") {
        setStartDate(null);
        setEndDate(null);
      } else if (dateRange === "Yesterday") {
        let date = dayjs(e);
        let oneDaysBefore = date.subtract(1, "day").startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();
        setStartDate(oneDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);
        setFilteredModalDate({
          startDate: oneDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "Today") {
        let date = dayjs(e);
        let startOfDayBefore = date.startOf("day");
        let oneDaysBeforeDate = startOfDayBefore.toDate();
        setStartDate(oneDaysBeforeDate);
        setEndDate(null);
        setFilteredModalDate({
          startDate: oneDaysBeforeDate,
          endDate: startDateSchedule,
        });
      } else if (dateRange === "2days") {
        let date = dayjs(e);
        let twoDaysBefore = date.subtract(2, "day").startOf("day");
        let twoDaysBeforeDate = twoDaysBefore.toDate();

        setStartDate(twoDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: twoDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "7days") {
        let date = dayjs(e);
        let sevenDaysBefore = date.subtract(7, "day").startOf("day");
        let sevenDaysBeforeDate = sevenDaysBefore.toDate();

        setStartDate(sevenDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);
        setFilteredModalDate({
          startDate: sevenDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "week") {
        let date = dayjs(e);

        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate.subtract(
          weekBeforeSelectedDate.day() - 1,
          "day"
        );
        let followingSunday = previousMonday.add(6, "day");

        setStartDate(previousMonday.toDate());
        setEndDate(followingSunday?.toDate());

        setFilteredModalDate({
          startDate: previousMonday.toDate(),
          endDate: followingSunday?.toDate(),
        });
      } else if (dateRange === "month") {
        let date = dayjs(e);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");

        setStartDate(startOfMonthBefore.toDate());
        setEndDate(endOfMonthBefore?.toDate());

        setFilteredModalDate({
          startDate: startOfMonthBefore.toDate(),
          endDate: endOfMonthBefore?.toDate(),
        });
      } else if (dateRange === "14days") {
        let date = dayjs(e);
        let fourteenDaysBefore = date.subtract(14, "day").startOf("day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.toDate();

        setStartDate(fourteenDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: fourteenDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "30days") {
        let date = dayjs(e);
        let oneMonthBefore = date.subtract(30, "day").startOf("day");
        let oneMonthBeforeDate = oneMonthBefore.toDate();

        setStartDate(oneMonthBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: oneMonthBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "60days") {
        let date = dayjs(e);

        let sixtyDaysBefore = date.subtract(60, "day").startOf("day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.toDate();

        setStartDate(sixtyDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: sixtyDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "90days") {
        let date = dayjs(e);

        let ninetyDaysBefore = date.subtract(90, "day").startOf("day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.toDate();

        setStartDate(ninetyDaysBeforeDate);
        setEndDate(oneDaysBeforeDateEnd);

        setFilteredModalDate({
          startDate: ninetyDaysBeforeDate,
          endDate: oneDaysBeforeDateEnd,
        });
      } else if (dateRange === "6months") {
        let date = dayjs(e);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore.toDate());

        let sixMonthsBefore = date.subtract(6, "month").startOf("month");
        let sixMonthsBeforeDate = sixMonthsBefore.toDate();

        setStartDate(sixMonthsBeforeDate);

        setFilteredModalDate({
          startDate: sixMonthsBeforeDate,
          endDate: endOfMonthBefore.toDate(),
        });
      } else if (dateRange === "12months") {
        let date = dayjs(e);

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore.toDate());

        let oneYearBefore = date.subtract(12, "months").startOf("month");
        let oneYearBeforeDate = oneYearBefore.toDate();

        setStartDate(oneYearBeforeDate);

        setFilteredModalDate({
          startDate: oneYearBeforeDate,
          endDate: endOfMonthBefore.toDate(),
        });
      } else if (dateRange === "custom") {
        //  setStartDate(previousYear);
        //  setEndDate(e);
      }
    }
  };
  const handleChangeEndDateSchedule = (e) => {
    setEndDateSchedule(e);
  };

  const handleCloseExportFileDownloadModal = () => {
    setOpenDownloadPdfModal(false);
    setPdfChecked(true);
    setXlsChecked(false);
    handleResetEmailModel();
  };

  const handleOpenExportFileDownloadModal = () => {
    setOpenDownloadPdfModal(true);
  };

  const handleDownloadXLSReport = async () => {
    let formatedStartDate = "";
    let formatedEndDate = "";

    let filterObj = {
      dateRange: dateRange,
      filters: {},
    };
    if (dateRange !== "All") {
      if (dateRange === "Today") {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }
      } else if (dateRange === "custom") {
        let startDateNew = dayjs(startDate).startOf("day");
        formatedStartDate = startDateNew.toISOString();
        let endDateNew = dayjs(endDate).endOf("day");
        formatedEndDate = endDateNew.toISOString();
      } else {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }

        if (endDate !== null) {
          let endDateNew = dayjs(endDate).endOf("day");
          formatedEndDate = endDateNew.toISOString();
        }
      }
    }

    if (dateRange !== "All") {
      if (startDate !== null && dateRange === "Today") {
        let date = {
          $gte: formatedStartDate,
        };
        filterObj["filters"]["date"] = date;
      } else {
        let str = `${formatedStartDate},${formatedEndDate}`;

        let date = {
          $between: str,
        };

        filterObj["filters"]["date"] = date;
      }
    }

    if (filtersModal.locations.length > 0) {
      filterObj["filters"]["locationId"] = filtersModal.locations;
    }
    if (filtersModal.groups.length > 0) {
      filterObj["groupId"] = filtersModal.groups;
    }
    if (filtersModal.sources.length > 0) {
      filterObj["filters"]["thirdPartyReviewSourcesId"] = {
        $equals: filtersModal.sources.join(","),
      };
    }
    if (dateFormat.label !== "") {
      filterObj["dateFormat"] = dateFormat.label;
    }
    if (filtersModal.tags.length > 0) {
      filterObj["filters"]["reviewTags"] = {
        tagId: {
          $equals: filtersModal.tags.join(","),
        },
      };
    }
    if (filtersModal.starRatings.length > 0) {
      filterObj["filters"]["ratingValue"] = {
        $equals: filtersModal.starRatings.join(","),
      };
    }

    if (filtersModal.reviewedBy !== "") {
      filterObj["search"] = filtersModal.reviewedBy.trim();
    }

    if (filtersModal.actions !== 2) {
      if (filtersModal.actions === "true") {
        filterObj["isDraft"] = {
          $equals: true,
        };
      } else {
        filterObj["haventReplied"] = filtersModal.actions;
      }
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }
    if (Object.keys(filterObj?.filters).length === 0) {
      delete filterObj["filters"];
    }

    try {
      handleCloseExportFileDownloadModal();
      setLoadingXlsReport(true);

      /*   const queryParams = new URLSearchParams({
        // ...filterObj,
        //  ...newSourceDownload,
        ...(scheduleGroups !== null && { groupId: scheduleGroups?.id }),
        ...(locationIds?.length > 0 && {
          "filters[locationId]": locationIds?.toString(),
        }),
        ...(reviewSourceIds?.length > 0 && {
          "filters[thirdPartyReviewSourcesId][$equals]":
            reviewSourceIds?.toString(),
        }),
        ...(selectedTagsIds?.length > 0 && {
          "filters[reviewTags][tagId][$equals]": selectedTagsIds?.toString(),
        }),
        ...(ratingIds?.length > 0 && {
          "filters[ratingValue][$equals]": ratingIds?.toString(),
        }),
        ...(scheduleReviewerName !== "" && {
          reviewerTitle: scheduleReviewerName,
        }),
        ...(scheduleReply?.name !== "All" &&
          scheduleReply?.name !== "Drafted" && {
            haventReplied: scheduleReply?.value,
          }),
        ...(dateRange !== "All" &&
          (dateRange !== "custom" &&
          dateRange !== "week" &&
          dateRange !== "month"
            ? {
                "filters[date][$gte]": formatedStartDate,
              }
            : {
                "filters[date][$between]": `${formatedStartDate},${formatedEndDate}`,
              })),
        ...(draftState === true && { "filters[isDraft][$equals]": true }),
        ...(pdfChecked === true && xlsChecked === true
          ? { fileType: "both" }
          : pdfChecked === true && xlsChecked === false
          ? { fileType: "pdf" }
          : { fileType: "xlsx" }),
        ...(uploadedImages?.length > 0 && {
          customLogo: uploadedImages[0]?.imagePath,
        }),

        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
      }).toString(); */

      const res = await api.patch(`review/reports`, {
        filter: filterObj,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
      });

      if (res.status === 200) {
        setLoadingXlsReport(false);
        if (res?.data?.data?.length > 0) {
          res?.data?.data?.forEach((item) => {
            window.open(item?.report);
          });
        }
        toast.success("Reviews report downloaded!");
        handleResetScheduleFiltersModel();
        handleCloseEmailReportModal();
      }
    } catch (error) {
      setLoadingXlsReport(false);
      toast.error(error?.response?.data?.message);
      handleCloseEmailReportModal();
    }
  };
  const handleEmailReviewsXlsReport = async () => {
    let formatedStartDate = "";
    let formatedEndDate = "";

    let filterObj = {
      dateRange: dateRange,
      filters: {},
    };

    if (dateRange !== "All") {
      if (dateRange === "Today") {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }
      } else if (dateRange === "custom") {
        let startDateNew = dayjs(startDate).startOf("day");
        formatedStartDate = startDateNew.toISOString();
        let endDateNew = dayjs(endDate).endOf("day");
        formatedEndDate = endDateNew.toISOString();
      } else {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }

        if (endDate !== null) {
          let endDateNew = dayjs(endDate).endOf("day");
          formatedEndDate = endDateNew.toISOString();
        }
      }
    }

    if (dateRange !== "All") {
      if (startDate !== null && dateRange === "Today") {
        let date = {
          $gte: formatedStartDate,
        };
        filterObj["filters"]["date"] = date;
      } else {
        let str = `${formatedStartDate},${formatedEndDate}`;

        let date = {
          $between: str,
        };

        filterObj["filters"]["date"] = date;
      }
    }

    if (filtersModal.locations.length > 0) {
      filterObj["filters"]["locationId"] = filtersModal.locations;
    }
    if (filtersModal.groups.length > 0) {
      filterObj["groupId"] = filtersModal.groups;
    }
    if (filtersModal.sources.length > 0) {
      filterObj["filters"]["thirdPartyReviewSourcesId"] = {
        $equals: filtersModal.sources.join(","),
      };
    }
    if (dateFormat.label !== "") {
      filterObj["dateFormat"] = dateFormat.label;
    }
    if (filtersModal.tags.length > 0) {
      filterObj["filters"]["reviewTags"] = {
        tagId: {
          $equals: filtersModal.tags.join(","),
        },
      };
    }
    if (filtersModal.starRatings.length > 0) {
      filterObj["filters"]["ratingValue"] = {
        $equals: filtersModal.starRatings.join(","),
      };
    }

    if (filtersModal.reviewedBy !== "") {
      filterObj["search"] = filtersModal.reviewedBy.trim();
    }

    if (filtersModal.actions !== 2) {
      if (filtersModal.actions === "true") {
        filterObj["isDraft"] = {
          $equals: true,
        };
      } else {
        filterObj["haventReplied"] = filtersModal.actions;
      }
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }
    if (Object.keys(filterObj?.filters).length === 0) {
      delete filterObj["filters"];
    }

    try {
      setReviewReportLoading(true);

      /*   const queryParams = new URLSearchParams({
        // ...filterObj,
        //  ...newSourceDownload,
        ...(scheduleGroups !== null && { groupId: scheduleGroups?.id }),
        ...(locationIds?.length > 0 && {
          "filters[locationId]": locationIds?.toString(),
        }),
        ...(reviewSourceIds?.length > 0 && {
          "filters[thirdPartyReviewSourcesId][$equals]":
            reviewSourceIds?.toString(),
        }),
        ...(selectedTagsIds?.length > 0 && {
          "filters[reviewTags][tagId][$equals]": selectedTagsIds?.toString(),
        }),
        ...(ratingIds?.length > 0 && {
          "filters[ratingValue][$equals]": ratingIds?.toString(),
        }),
        ...(scheduleReviewerName !== "" && {
          reviewerTitle: scheduleReviewerName,
        }),
        ...(scheduleReply?.name !== "All" &&
          scheduleReply?.name !== "Drafted" && {
            haventReplied: scheduleReply?.value,
          }),
        ...(dateRange !== "All" &&
          (dateRange !== "custom" &&
          dateRange !== "week" &&
          dateRange !== "month"
            ? {
                "filters[date][$gte]": formatedStartDate,
              }
            : {
                "filters[date][$between]": `${formatedStartDate},${formatedEndDate}`,
              })),
        ...(draftState === true && { "filters[isDraft][$equals]": true }),
        ...(pdfChecked === true && xlsChecked === true
          ? { fileType: "both" }
          : pdfChecked === true && xlsChecked === false
          ? { fileType: "pdf" }
          : { fileType: "xlsx" }),
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
      }).toString(); */

      const res = await api.patch(`review/reports`, {
        filter: filterObj,
        emails: emails,
        subject: emailSubject,
        body: emailBody,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
      });

      if (res.status === 200) {
        setReviewReportLoading(false);
        toast.success("User will recieve email shortly");
        handleResetScheduleFiltersModel();
        handleCloseEmailReportModal();
      }
    } catch (error) {
      setReviewReportLoading(false);
      toast.error(error?.response?.data?.message);
      handleCloseEmailReportModal();
    }
  };

  const handleClickMenu = (event) => {
    // setFiltersModal((prev) => ({
    //   ...prev,
    //   timePeriod: filters.timePeriod === "" ? "All" : filters.timePeriod,
    //   time:
    //     filters.timePeriod === ""
    //       ? { id: 0, name: "All", value: "All" }
    //       : filters.time,
    //   ...filters,
    // }));
    setFiltersModal(filters);
    setFilteredModalDate(filteredDate);
    setDateRange(filters.timePeriod === "" ? "All" : filters.timePeriod);
    setAnchorBulk(event.currentTarget);
  };
  const handleClickMenuItem = (value) => {
    if (value === "download") {
      handleOpenExportFileDownloadModal(true);
      //  handleDownloadXLSReport(true);
    } else if (value === "email") {
      handleOpenReportEmailModal();
    } else if (value === "schedule") {
      handleOpenScheduleModal();
    }
  };

  const handleOpenScheduleModal = () => {
    setOpenSchduleModal(true);
  };
  const handleCloseScheduleModal = () => {
    setOpenSchduleModal(false);
    handleResetEmailModel();
  };
  const handleOpenCreateScheduleModal = (id) => {
    setOpenCreateSchedule(true);
    setSelectedEditId(id);
  };
  const handleCloseCreateScheduleModal = () => {
    // setOpenSchduleModal(false);
    setOpenCreateSchedule(false);
    handleResetEmailModel();
  };
  const handleChangeLanguageForScheduler = (value) => {
    setLanguageSchedular(value);
  };
  const handleScheduleReport = async () => {
    setScheduleReportLoading(true);

    let formatedStartDate = "";
    let formatedEndDate = "";

    let filterObj = {
      dateRange: dateRange,
      filters: {},
    };

    if (dateRange !== "All") {
      if (dateRange === "Today") {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }
      } else if (dateRange === "custom") {
        let startDateNew = dayjs(startDate).startOf("day");
        formatedStartDate = startDateNew.toISOString();
        let endDateNew = dayjs(endDate).endOf("day");
        formatedEndDate = endDateNew.toISOString();
      } else {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }

        if (endDate !== null) {
          let endDateNew = dayjs(endDate).endOf("day");
          formatedEndDate = endDateNew.toISOString();
        }
      }
    }

    if (dateRange !== "All") {
      if (startDate !== null && dateRange === "Today") {
        let date = {
          $gte: formatedStartDate,
        };
        filterObj["filters"]["date"] = date;
      } else {
        let str = `${formatedStartDate},${formatedEndDate}`;

        let date = {
          $between: str,
        };

        filterObj["filters"]["date"] = date;
      }
    }

    if (filtersModal.locations.length > 0) {
      filterObj["filters"]["locationId"] = filtersModal.locations;
    }
    if (filtersModal.groups.length > 0) {
      filterObj["groupId"] = filtersModal.groups;
    }
    if (filtersModal.sources.length > 0) {
      filterObj["filters"]["thirdPartyReviewSourcesId"] = {
        $equals: filtersModal.sources.join(","),
      };
    }
    if (filtersModal.tags.length > 0) {
      filterObj["filters"]["reviewTags"] = {
        tagId: {
          $equals: filtersModal.tags.join(","),
        },
      };
    }
    if (filtersModal.starRatings.length > 0) {
      filterObj["filters"]["ratingValue"] = {
        $equals: filtersModal.starRatings.join(","),
      };
    }
    if (dateFormat.label !== "") {
      filterObj["dateFormat"] = dateFormat.label;
    }

    if (filtersModal.reviewedBy !== "") {
      filterObj["search"] = filtersModal.reviewedBy.trim();
    }

    if (filtersModal.actions !== 2) {
      if (filtersModal.actions === "true") {
        filterObj["isDraft"] = {
          $equals: true,
        };
      } else {
        filterObj["haventReplied"] = filtersModal.actions;
      }
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }
    if (Object.keys(filterObj?.filters).length === 0) {
      delete filterObj["filters"];
    }
    let custom = null;
    if (repeatValue === "custom") {
      custom = {
        frequency: frequencyValue,
        occurance: occuranceValue,
        days: selectedWeekDays,
        dates: selectedMonthDays,
      };
    }
    try {
      const res = await api.post(
        `/reportSchedules`,

        {
          filter: filterObj,
          locationIds: filters.locations,

          startDate: startDateSchedule,
          endDate: endDateSchedule,
          fileType:
            pdfChecked && xlsChecked
              ? "both"
              : pdfChecked && xlsChecked === false
              ? "pdf"
              : "xlsx",
          language:
            languageSchedular !== null && languageSchedular !== ""
              ? languageSchedular?.language
              : "",
          emailSubject,
          emailBody,
          emails: emails,
          repeatType: repeatValue,
          reportType: "Reviews",
          status: "pending",
          title: emailTitle,
          customSchedule: custom,
          customLogo:
            uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
          noReviewsNotification:
            yesChecked && noChecked === false
              ? true
              : yesChecked === false && noChecked === true
              ? false
              : true,
        }
      );
      if (res.status === 201) {
        //  handleResetEmailModel();
        handleCloseCreateScheduleModal();
        getAllScheduledReports();
        setScheduleReportLoading(false);

        toast.success(`${t("Schedule_created")}`);
        handleResetEmailModel();
      }
    } catch (error) {
      setScheduleReportLoading(false);
      // toast.error(error?.response?.data?.message);
      // handleCloseCreateScheduleModal();
      // handleResetEmailModel();
      if (
        error?.response?.data?.data ===
        "Report Schedule Already Exists With this Name"
      ) {
        setDisplayReportError(true);
      } else {
        toast.error(error?.response?.data?.data);
      }
    }
  };
  const handleOpenUpdateStatusModel = (rowData, statusValue) => {
    setSelectedRowData(rowData);
    setPauseValue(statusValue);
    setOpenUpdateStatusModel(true);
  };
  const handleCloseUpdateStatusModel = () => {
    setOpenUpdateStatusModel(false);
    setSelectedRowData(null);
    setPauseValue(false);
  };

  const handleUpdateStatus = async () => {
    let formatedStartDate = "";
    let formatedEndDate = "";

    if (dateRange !== "All") {
      if (dateRange === "Today") {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }
      } else {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }

        if (endDate !== null) {
          let endDateNew = dayjs(endDate).endOf("day");
          formatedEndDate = endDateNew.toISOString();
        }
      }
    }

    let filterObj = {
      dateRange: dateRange,
      filters: {
        reviewTags: {},
      },
    };

    let locationIds = [];
    if (selectedRowData?.filter?.filters?.hasOwnProperty("locationId")) {
      selectedRowData?.filter?.filters?.locationId?.map((item) => {
        locationIds.push(item);
      });
    } else {
      if (
        selectedRowData?.filter?.filters?.hasOwnProperty("groupId") === false
      ) {
        allLocation?.results?.map((item) => {
          locationIds.push(item?.id);
        });
      }
    }
    if (selectedRowData?.filter?.filters?.hasOwnProperty("locationId")) {
      filterObj["filters"]["locationId"] =
        selectedRowData?.filter?.filters?.locationId;
    }

    /*   let groupIds = [];
    if (scheduleGroups !== null) {
      filterObj["groupId"] = scheduleGroups?.id;
    }
    if (dateRange !== "All") {
      if (startDate !== null && dateRange === "Today") {
        let date = {
          $gte: formatedStartDate,
        };
        filterObj["filters"]["date"] = date;
      } else {
        let str = `${formatedStartDate},${formatedEndDate}`;
        let date = {
          $between: str,
        };
        filterObj["filters"]["date"] = date;
      }
    }

    let reviewSourceIds = [];
    if (scheduleReviewSource?.length > 0) {
      scheduleReviewSource?.map((item) => {
        reviewSourceIds.push(item?.id);
      });
      let ids = {
        $equals: reviewSourceIds?.toString(),
      };
      filterObj["filters"]["thirdPartyReviewSourcesId"] = ids;
    }
    let selectedTagsIds = [];
    if (scheduleTags?.length > 0) {
      scheduleTags?.map((item) => {
        selectedTagsIds.push(item?.id);
      });
      let ids = {
        $equals: selectedTagsIds?.toString(),
      };
      filterObj["filters"]["reviewTags"]["tagId"] = ids;
    } else {
      delete filterObj["filters"]["reviewTags"];
    }
    let ratingIds = [];
    if (scheduleRatingValue?.length > 0) {
      scheduleRatingValue?.map((item) => {
        ratingIds.push(item?.value);
      });
      let ratingValue = {
        $equals: ratingIds?.toString(),
      };
      filterObj["filters"]["ratingValue"] = ratingValue;
    }
    if (scheduleReviewerName !== "") {
      filterObj["reviewerTitle"] = scheduleReviewerName;
    }
    if (scheduleReply?.name !== "All") {
      if (scheduleReply?.name === "Drafted") {
        let obj = {
          $equals: true,
        };
        filterObj["isDraft"] = obj;
      } else {
        filterObj["haventReplied"] = scheduleReply?.value;
      }
    }
 */

    let emails = JSON.parse(selectedRowData?.emails);

    try {
      setStatusLoading(true);
      const res = await api.patch(`reportSchedules/${selectedRowData?.id}`, {
        filter: selectedRowData?.filter,
        locationIds: locationIds,
        startDate: selectedRowData?.startDate,
        endDate: selectedRowData?.endDate,
        fileType: selectedRowData?.fileType,
        language: selectedRowData?.language,
        emailSubject: selectedRowData?.emailSubject,
        emailBody: selectedRowData?.emailBody,
        customSchedule: selectedRowData?.customSchedule,
        emails: JSON.parse(selectedRowData?.emails),
        repeatType: selectedRowData?.repeatType,
        reportType: selectedRowData?.reportType,
        status: selectedRowData?.status === "paused" ? "pending" : "paused",
        title: selectedRowData?.title,
        reportScheduleLocations: selectedRowData?.ReportScheduleLocations,
        customLogo: selectedRowData?.customLogo,
        noReviewsNotification: selectedRowData?.noReviewsNotification,
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setStatusLoading(false);
        handleCloseUpdateStatusModel();
        getAllScheduledReports();
      }
    } catch (error) {
      setStatusLoading(false);
      handleCloseUpdateStatusModel();
      toast.error(error?.response?.data?.message);
    }
  };

  const handleUpdateScheduledReport = async () => {
    const reportLoc = [];
    if (reportScheduleLocations.length > 0) {
      filtersModal.locations.forEach((itemOne) => {
        reportScheduleLocations.forEach((itemTwo) => {
          if (itemOne === itemTwo?.Location?.id) {
            reportLoc.push(itemTwo);
          }
        });
      });
    }
    // let formatedStartDate = "";
    // let formatedEndDate = "";

    // if (dateRange !== "All") {
    //   if (dateRange === "Today") {
    //     if (startDate !== null) {
    //       let startDateNew = dayjs(startDate).startOf("day");
    //       formatedStartDate = startDateNew.toISOString();
    //     }
    //   } else {
    //     if (startDate !== null) {
    //       let startDateNew = dayjs(startDate).startOf("day");
    //       formatedStartDate = startDateNew.toISOString();
    //     }

    //     if (endDate !== null) {
    //       let endDateNew = dayjs(endDate).endOf("day");
    //       formatedEndDate = endDateNew.toISOString();
    //     }
    //   }
    // }

    // let filterObj = {
    //   dateRange: dateRange,
    //   filters: {
    //     reviewTags: {},
    //   },
    // };

    // let locationIds = [];
    // if (scheduleLocations?.length > 0) {
    //   scheduleLocations?.map((item) => {
    //     locationIds.push(item?.id);
    //   });
    // } else {
    //   if (scheduleGroups===null) {
    //     allLocation?.results?.map((item) => {
    //       locationIds.push(item?.id);
    //     });
    //   }
    // }
    // if (filtersModal.locations.length > 0) {
    //   filterObj["filters"]["locationId"] = filtersModal.locations;
    // }

    // let groupIds = [];
    // if (scheduleGroups !== null) {
    //   filterObj["groupId"] = scheduleGroups?.id;
    // }
    // if (dateRange !== "All") {
    //   if (startDate !== null && dateRange === "Today") {
    //     let date = {
    //       $gte: formatedStartDate,
    //     };
    //     filterObj["filters"]["date"] = date;
    //   } else {
    //     let str = `${formatedStartDate},${formatedEndDate}`;
    //     let date = {
    //       $between: str,
    //     };
    //     filterObj["filters"]["date"] = date;
    //   }
    // }

    // let reviewSourceIds = [];
    // if (scheduleReviewSource?.length > 0) {
    //   scheduleReviewSource?.map((item) => {
    //     reviewSourceIds.push(item?.id);
    //   });
    //   let ids = {
    //     $equals: reviewSourceIds?.toString(),
    //   };
    //   filterObj["filters"]["thirdPartyReviewSourcesId"] = ids;
    // }
    // let selectedTagsIds = [];
    // if (scheduleTags?.length > 0) {
    //   scheduleTags?.map((item) => {
    //     selectedTagsIds.push(item?.id);
    //   });
    //   let ids = {
    //     $equals: selectedTagsIds?.toString(),
    //   };
    //   filterObj["filters"]["reviewTags"]["tagId"] = ids;
    // } else {
    //   delete filterObj["filters"]["reviewTags"];
    // }
    // let ratingIds = [];
    // if (scheduleRatingValue?.length > 0) {
    //   scheduleRatingValue?.map((item) => {
    //     ratingIds.push(item?.value);
    //   });
    //   let ratingValue = {
    //     $equals: ratingIds?.toString(),
    //   };
    //   filterObj["filters"]["ratingValue"] = ratingValue;
    // }
    // if (scheduleReviewerName !== "") {
    //   filterObj["reviewerTitle"] = scheduleReviewerName;
    // }
    // if (scheduleReply?.name !== "All") {
    //   if (scheduleReply?.name === "Drafted") {
    //     let obj = {
    //       $equals: true,
    //     };
    //     filterObj["isDraft"] = obj;
    //   } else {
    //     filterObj["haventReplied"] = scheduleReply?.value;
    //   }
    // }

    let formatedStartDate = "";
    let formatedEndDate = "";

    let filterObj = {
      dateRange: dateRange,
      filters: {},
    };

    if (dateRange !== "All") {
      if (dateRange === "Today") {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }
      } else if (dateRange === "custom") {
        let startDateNew = dayjs(startDate).startOf("day");
        formatedStartDate = startDateNew.toISOString();
        let endDateNew = dayjs(endDate).endOf("day");
        formatedEndDate = endDateNew.toISOString();
      } else {
        if (startDate !== null) {
          let startDateNew = dayjs(startDate).startOf("day");
          formatedStartDate = startDateNew.toISOString();
        }

        if (endDate !== null) {
          let endDateNew = dayjs(endDate).endOf("day");
          formatedEndDate = endDateNew.toISOString();
        }
      }
    }

    if (dateRange !== "All") {
      if (startDate !== null && dateRange === "Today") {
        let date = {
          $gte: formatedStartDate,
        };
        filterObj["filters"]["date"] = date;
      } else {
        let str = `${formatedStartDate},${formatedEndDate}`;

        let date = {
          $between: str,
        };

        filterObj["filters"]["date"] = date;
      }
    }

    if (filtersModal.locations.length > 0) {
      filterObj["filters"]["locationId"] = filtersModal.locations;
    }
    if (filtersModal.groups.length > 0) {
      filterObj["groupId"] = filtersModal.groups;
    }
    if (filtersModal.sources.length > 0) {
      filterObj["filters"]["thirdPartyReviewSourcesId"] = {
        $equals: filtersModal.sources.join(","),
      };
    }
    if (filtersModal.tags.length > 0) {
      filterObj["filters"]["reviewTags"] = {
        tagId: {
          $equals: filtersModal.tags.join(","),
        },
      };
    }
    if (filtersModal.starRatings.length > 0) {
      filterObj["filters"]["ratingValue"] = {
        $equals: filtersModal.starRatings.join(","),
      };
    }
    if (dateFormat.label !== "") {
      filterObj["filters"]["dateFormat"] = dateFormat.label;
    }

    if (filtersModal.reviewedBy !== "") {
      filterObj["search"] = filtersModal.reviewedBy.trim();
    }

    if (filtersModal.actions !== 2) {
      if (filtersModal.actions === "true") {
        filterObj["isDraft"] = {
          $equals: true,
        };
      } else {
        filterObj["haventReplied"] = filtersModal.actions;
      }
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }
    if (Object.keys(filterObj?.filters).length === 0) {
      delete filterObj["filters"];
    }
    let custom = null;
    if (repeatValue === "custom") {
      custom = {
        frequency: frequencyValue,
        occurance: occuranceValue,
        days: selectedWeekDays,
        dates: selectedMonthDays,
      };
    }
    try {
      setUpdateReportLoading(true);
      const res = await api.patch(`reportSchedules/${selectedEditId}`, {
        filter: filterObj,
        locationIds: filtersModal.locations,
        startDate: startDateSchedule,
        endDate: endDateSchedule,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        emailSubject,
        emailBody,
        emails: emails,
        repeatType: repeatValue,
        reportType: "Reviews",
        status: singleReportData?.status,
        title: emailTitle,
        reportScheduleLocations: reportLoc,
        customSchedule: custom,
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
        noReviewsNotification:
          yesChecked && noChecked === false
            ? true
            : yesChecked === false && noChecked === true
            ? false
            : true,
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setUpdateReportLoading(false);
        handleCloseCreateScheduleModal();
        setSelectedEditId(null);
        getAllScheduledReports();
      }
    } catch (error) {
      setUpdateReportLoading(false);
      // handleCloseCreateScheduleModal();
      // toast.error(error?.response?.data?.message);

      if (
        error?.response?.data?.data ===
        "Report Schedule Already Exists With this Name"
      ) {
        setDisplayReportError(true);
      } else {
        toast.error(error?.response?.data?.data);
      }
    }
  };

  const handleDeleteScheduleReport = async () => {
    try {
      setDeleteScheduleReportLoading(true);
      const res = await api.delete(`/reportSchedules/${deletedIds}`);
      if (res.status === 200) {
        toast.success(t("report_deleted"));
        if (scheduledRportsPageNumber === 0) {
          getAllScheduledReports();
        }
        setScheduledRportsPageNumber(0);
        setDeleteScheduleReportLoading(false);
        handleCloseDeleteScheduledReportModel();
        // getAllScheduledReports();
      }
    } catch (error) {
      setDeletedId(null);

      toast.error(error?.response?.data?.message);
      handleCloseDeleteScheduledReportModel();
      // getAllScheduledReports();
      setDeleteScheduleReportLoading(false);
    }
  };
  const handleOpenDeleteScheduledReportModel = (deletedIds) => {
    setDeletedId(deletedIds);
    setOpenDeleteScheduledReportModel(true);
  };
  const handleCloseDeleteScheduledReportModel = () => {
    setOpenDeleteScheduledReportModel(false);
    setDeletedId(null);
  };
  const handleResetOthers = () => {
    setTotalEmailsArr([]);
    setSelectedItems([]);
    // setValidEmailsArray([]);

    setSelectedFile(null);
    setDataArray([]);
  };

  const handleResetEmailModel = () => {
    // setStartDate(null);
    // setEndDate(null);
    setUploadedImages([]);
    setImages([]);
    setDeletedId(null);
    setYesChecked(true);
    setNoChecked(false);
    setStartDateSchedule(null);
    setEndDateSchedule(null);
    setPdfChecked(true);
    setXlsChecked(false);
    // setEmailTitle(`Reviews management report of ${companyData?.name}.`);
    setEmailTitle("");
    setDateFormat({ label: format });

    setSingleReportData(null);
    setSelectedEditId(null);

    setCompanyUser(true);
    setOthers(false);

    setRepeatValue("no");
    setTotalEmailsArr([]);
    setSelectedItems([]);
    setValidEmailsArray([]);
    setAddNewUserEmails([]);
    setSelectedFile(null);
    setDataArray([]);
    setSelectedCompanyUsers([]);
    setOthers(false);

    setEmailSubject(`Reviews management report of ${companyData?.name}`);
    setEmailBody(
      `Please find attached reviews report of ${companyData?.name}
      `
    );
    setScheduleGroups(selectedGroup);
    setScheduleLocations(locationId);
    setScheduleRatingValue(ratingsValue);
    setScheduleReviewSource(sourceId);
    setScheduleReply(selectedReply);
    setScheduleTags(selectedTags);
    setScheduleReviewerName(filterWithName);
    setStartDate(filterStartDate);
    setEndDate(filterEndDate);
    setFiltersModal(filters);
    setDateRange(filters?.timePeriod === "" ? "All" : filters?.timePeriod);
    setFilteredModalDate(filteredDate);
    setOccuranceValue(1);
    setFrequencyValue("");
    setSelectedWeekDays([]);
    setSelectedMonthDays([]);
  };
  const handleOpenReportEmailModal = () => {
    setOpenReportEmailModal(true);
  };

  const handleCloseEmailReportModal = () => {
    setOpenReportEmailModal(false);
    handleResetEmailModel();
  };

  const handleChangeGroups = (value) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("New request is made");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);
    setPageNumber(0);
    setSelectedGroup(value);
    if (value !== null) {
      const locationIds = value.groups.map((item) => item.locationId);
      const filteredData = allLocation?.results.filter((obj) =>
        locationIds.includes(obj.id)
      );
      setLocationId(filteredData);
    } else {
      setLocationId([]);
    }
  };

  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleChangeGroupsForLocations = (loc) => {
    if (selectedGroup !== null) {
      const locationIds = selectedGroup.groups.map((item) => item.locationId);
      for (let i = 0; i < locationIds.length; i++) {
        for (let j = 0; j < loc.length; j++) {
          if (loc[j].id !== locationIds[i]) {
            setSelectedGroup(null);
            break;
          }
        }
      }
    } else if (loc.length === 0) {
      setSelectedGroup(null);
    }
  };

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  const handleResetScheduleFiltersModel = () => {
    setScheduleGroups(selectedGroup);
    setScheduleLocations(locationId);
    setScheduleRatingValue(ratingsValue);
    setScheduleReviewSource(sourceId);
    setScheduleReply(selectedReply);
    setScheduleTags(selectedTags);
    setScheduleReviewerName(filterWithName);
  };

  return (
    <>
      {
        <Box>
          {loadingXlsReport && (
            <>
              <Typography>{t("download_progress")}</Typography>
              <LinearBuffer />
            </>
          )}
        </Box>
      }
      {openDeleteScheduledReportModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDeleteScheduledReportModel}
          onClose={handleCloseDeleteScheduledReportModel}
        >
          <Delete
            title={t("scheduled_report_delete")}
            description={t("confirm_delete_scheduled_report")}
            onConfirm={handleDeleteScheduleReport}
            onCancel={handleCloseDeleteScheduledReportModel}
            loading={deleteScheduleReportLoading}
          />
        </Dialog>
      )}
      {openUpdateStatusModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openUpdateStatusModel}
          onClose={handleCloseUpdateStatusModel}
        >
          <Delete
            title={pauseValue ? t("pause_report") : t("resume_report")}
            description={
              pauseValue
                ? t("pause_confirmation_text")
                : t("resume_confirmation_text")
            }
            onConfirm={handleUpdateStatus}
            onCancel={handleCloseUpdateStatusModel}
            loading={statusLoading}
            schedule={true}
          />
        </Dialog>
      )}
      {openSchduleModal && (
        <SecheduledReports
          title={t("Secheduled_Reports")}
          open={openSchduleModal}
          handleCloseNewRequestModel={handleCloseScheduleModal}
          handleOpenCreateScheduleModal={handleOpenCreateScheduleModal}
          allLocations={allLocation}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          getsentimentalLocation={getAllLocations}
          scheduledReportsLoading={scheduledReportsLoading}
          scheduledRportsPageNumber={scheduledRportsPageNumber}
          setScheduledRportsPageNumber={setScheduledRportsPageNumber}
          rowsPerPageScheduled={rowsPerPageScheduled}
          setRowsPerPageScheduled={setRowsPerPageScheduled}
          scheduledReports={scheduledReports}
          handleOpenDeleteScheduledReportModel={
            handleOpenDeleteScheduledReportModel
          }
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
          handleUpdateStatus={handleUpdateStatus}
          handleOpenUpdateStatusModel={handleOpenUpdateStatusModel}
          // searchVal={scheduledReportSearchVal}
          // setSearchVal={setScheduledReportSearchVal}
          setScheduleReportFilters={setScheduleReportFilters}
          scheduleReportFilters={scheduleReportFilters}
        />
      )}
      {openCreateSchedule && (
        <SecheduleReport
          open={openCreateSchedule}
          title={t("Sechedule_Report")}
          handleCloseNewRequestModel={handleCloseCreateScheduleModal}
          others={others}
          setOthers={setOthers}
          companyUser={companyUser}
          setCompanyUser={setCompanyUser}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          recipientError={recipientError}
          setRecipientError={setRecipientError}
          recipientEmailError={recipientEmailError}
          setRecipientEmailError={setRecipientEmailError}
          recipientEmail={recipientEmail}
          setRecipientEmail={setRecipientEmail}
          recipientValue={recipientValue}
          setRecipientValue={setRecipientValue}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
          selectedCompanyUsers={selectedCompanyUsers}
          totalEmailsArr={totalEmailsArr}
          handleAddNewUserEmailsChange={handleAddNewUserEmailsChange}
          addNewUserEmials={addNewUserEmials}
          handleResetOthers={handleResetOthers}
          handleButtonClick={handleButtonClick}
          handleFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          fileInputRef={fileInputRef}
          handleRemoveFile={handleRemoveFile}
          setEmailSubject={setEmailSubject}
          emailSubject={emailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          loadingXlsReport={loadingXlsReport}
          handleScheduleReport={handleScheduleReport}
          scheduleReportLoading={scheduleReportLoading}
          repeatValue={repeatValue}
          dateRange={dateRange}
          setRepeatValue={setRepeatValue}
          getSingleSelectedReportById={getSingleSelectedReportById}
          singleReportData={singleReportData}
          handleUpdateScheduledReport={handleUpdateScheduledReport}
          updateReportLoading={updateReportLoading}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          startDateSchedule={startDateSchedule}
          startDateRangeSchedule={startDateSchedule}
          setStartDateSchedule={setStartDateSchedule}
          endDateSchedule={endDateSchedule}
          setEndDateSchedule={setEndDateSchedule}
          endDateRangeSchedule={endDateSchedule}
          handleChangeStartDateSchedule={handleChangeStartDateSchedule}
          handleChangeEndDateSchedule={handleChangeEndDateSchedule}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          scheduleReviewSource={scheduleReviewSource}
          setScheduleReviewSource={setScheduleReviewSource}
          scheduleTags={scheduleTags}
          setScheduleTags={setScheduleTags}
          scheduleRatingValue={scheduleRatingValue}
          setScheduleRatingValue={setScheduleRatingValue}
          scheduleReviewerName={scheduleReviewerName}
          setScheduleReviewerName={setScheduleReviewerName}
          scheduleReply={scheduleReply}
          setScheduleReply={setScheduleReply}
          allLocations={allLocation}
          allGroups={allGroups}
          allReviewSource={allReviewSource}
          starRatings={starRatings}
          allTags={tags}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          haventReplied={haventReplied}
          handleChangeDateRange={handleChangeDateRange}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          // reportFormat={reportFormat}
          // setReportFormat={setReportFormat}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          displayReportError={displayReportError}
          setDisplayReportError={setDisplayReportError}
          reportFormat={reportFormat}
          setReportFormat={setReportFormat}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
          setReportScheduleLocations={setReportScheduleLocations}
          modalFilters={filtersModal}
          modalFiltersDate={filteredModalDate}
          setModalFilters={setFiltersModal}
          setModalFiltersDate={setFilteredModalDate}
          checked={checkedLocation}
          yesChecked={yesChecked}
          setYesChecked={setYesChecked}
          noChecked={noChecked}
          setNoChecked={setNoChecked}
          setSelectedMonthDays={setSelectedMonthDays}
          selectedMonthDays={selectedMonthDays}
          setSelectedWeekDays={setSelectedWeekDays}
          selectedWeekDays={selectedWeekDays}
          frequencyValue={frequencyValue}
          setFrequencyValue={setFrequencyValue}
          setOccuranceValue={setOccuranceValue}
          occuranceValue={occuranceValue}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
        />
      )}
      {openDownloadPdfModal && (
        <ExportFileDownloadModal
          open={openDownloadPdfModal}
          title={t("download_report_title")}
          handleCloseNewRequestModel={handleCloseExportFileDownloadModal}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          scheduleReviewSource={scheduleReviewSource}
          setScheduleReviewSource={setScheduleReviewSource}
          scheduleTags={scheduleTags}
          setScheduleTags={setScheduleTags}
          scheduleRatingValue={scheduleRatingValue}
          setScheduleRatingValue={setScheduleRatingValue}
          scheduleReviewerName={scheduleReviewerName}
          setScheduleReviewerName={setScheduleReviewerName}
          scheduleReply={scheduleReply}
          setScheduleReply={setScheduleReply}
          allLocations={allLocation}
          allGroups={allGroups}
          allReviewSource={allReviewSource}
          starRatings={starRatings}
          allTags={tags}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          haventReplied={haventReplied}
          handleChangeDateRange={handleChangeDateRange}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          // reportFormat={reportFormat}
          // setReportFormat={setReportFormat}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setEmailTitle={setEmailTitle}
          emailTitle={emailTitle}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          dateRange={dateRange}
          loadingXlsReport={loadingXlsReport}
          handleDownloadXLSReport={handleDownloadXLSReport}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
          modalFilters={filtersModal}
          modalFiltersDate={filteredModalDate}
          setModalFilters={setFiltersModal}
          setModalFiltersDate={setFilteredModalDate}
          checked={checkedLocation}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
        />
      )}
      {openReportEmailModal && (
        <ExportEmailModal
          open={openReportEmailModal}
          title={t("email")}
          handleCloseNewRequestModel={handleCloseEmailReportModal}
          others={others}
          setOthers={setOthers}
          companyUser={companyUser}
          setCompanyUser={setCompanyUser}
          recipientValue={recipientValue}
          setRecipientValue={setRecipientValue}
          setEmailSubject={setEmailSubject}
          emailSubject={emailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
          selectedCompanyUsers={selectedCompanyUsers}
          handleResetOthers={handleResetOthers}
          handleFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          fileInputRef={fileInputRef}
          handleButtonClick={handleButtonClick}
          handleAddNewUserEmailsChange={handleAddNewUserEmailsChange}
          addNewUserEmials={addNewUserEmials}
          handleEmailReviewsXlsReport={handleEmailReviewsXlsReport}
          handleRemoveFile={handleRemoveFile}
          totalEmailsArr={totalEmailsArr}
          companyData={companyData}
          reviewReportLoading={reviewReportLoading}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          scheduleReviewSource={scheduleReviewSource}
          setScheduleReviewSource={setScheduleReviewSource}
          scheduleTags={scheduleTags}
          setScheduleTags={setScheduleTags}
          scheduleRatingValue={scheduleRatingValue}
          setScheduleRatingValue={setScheduleRatingValue}
          scheduleReviewerName={scheduleReviewerName}
          setScheduleReviewerName={setScheduleReviewerName}
          scheduleReply={scheduleReply}
          setScheduleReply={setScheduleReply}
          allLocations={allLocation}
          allGroups={allGroups}
          allReviewSource={allReviewSource}
          starRatings={starRatings}
          allTags={tags}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          haventReplied={haventReplied}
          handleChangeDateRange={handleChangeDateRange}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          // reportFormat={reportFormat}
          // setReportFormat={setReportFormat}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setEmailTitle={setEmailTitle}
          emailTitle={emailTitle}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          dateRange={dateRange}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
          modalFilters={filtersModal}
          modalFiltersDate={filteredModalDate}
          setModalFilters={setFiltersModal}
          setModalFiltersDate={setFilteredModalDate}
          checked={checkedLocation}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
        />
      )}
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          // onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("old_reviews_replyy")}
            description={
              type === "start"
                ? t("bulk_start_confirm_msg")
                : t("bulk_stop_confirm_msg")
            }
            onConfirm={
              type === "start"
                ? getOldReviewReplyProgress
                : handlePauseOldReviewReply
            }
            onCancel={handleCloseDeleteModel}
            loading={progressLoading}
          />
        </Dialog>
      )}
      {openBulkStartModel && (
        <BulkRequestModel
          open={openBulkStartModel}
          locationsArray={allGoogleLocation?.results}
          title={t("Bulk_Start")}
          handleCloseNewRequestModel={handleCloseBulkStartModel}
          locationValue={locationValue}
          setLocationValue={setLocationValue}
          handleSubmit={getOldReviewReplyProgress}
          loading={progressLoading}
          submitButtonText={t("startss")}
          handleOpenDeleteModel={handleOpenDeleteModel}
        />
      )}
      {openBulkStopModel && (
        <BulkRequestModel
          open={openBulkStopModel}
          locationsArray={allGoogleLocation?.results}
          title={t("Bulk_stop")}
          handleCloseNewRequestModel={handleCloseBulkStopModel}
          locationValue={locationValue}
          setLocationValue={setLocationValue}
          handleSubmit={handlePauseOldReviewReply}
          loading={progressLoading}
          submitButtonText={t("stopss")}
          handleOpenDeleteModel={handleOpenDeleteModel}
        />
      )}
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          {i18nextLng === "es" ? (
            <Iframe
              title={t("Reviews_Tutorial")}
              onCancel={handleCloseTemplateMenu}
              iframe={
                <iframe
                  src="https://ask.obenan.com/f4ssj6qft"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="1200px"
                  height="500px"
                  onLoad={() => {}}
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              }
            />
          ) : (
            <Iframe
              title={t("Reviews_Tutorial")}
              onCancel={handleCloseTemplateMenu}
              iframe={
                <iframe
                  title="iframe"
                  loading="lazy"
                  src="https://ask.obenan.com/f4tkmskma"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="1200px"
                  height="500px"
                  onLoad={() => {}}
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                />
              }
            />
          )}
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          open={openDefaultModel}
          onClose={handleCloseFetchReviewsMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <FetchModel
            title={t("Import_Google_Old_Reviews")}
            description={
              <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                  {/*  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Checkbox
                      sx={{ paddingLeft: "0px" }}
                      checked={reviewChecked}
                      onChange={(e) => handleReviewCheckbox(e)}
                      inputProps={{ True: "False" }}
                    />
                    <Typography className={classes.subHeadFetch}>
                      {t("Fetch_reviews")}
                    </Typography>
                  </Box> */}
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Checkbox
                      sx={{ paddingLeft: "0px" }}
                      checked={checked}
                      onChange={(e) => handleChangeCheckButton(e)}
                      inputProps={{ True: "False" }}
                    />
                    <Typography className={classes.subHeadFetch}>
                      {t("All_connected_locationss")}
                    </Typography>
                  </Box>
                  {!checked === true && (
                    <>
                      <Autocomplete
                        multiple
                        disablePortal
                        disableCloseOnSelect
                        id="combo-box-demo"
                        options={
                          allGoogleLocation?.results?.length > 0
                            ? allGoogleLocation?.results
                            : []
                        }
                        getOptionLabel={(option) =>
                          option?.internalName
                            ? option?.internalName +
                              (option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? " (" + option.addressLine1
                                : "") +
                              (option?.city !== "" &&
                              option?.city !== null &&
                              option?.city !== undefined
                                ? (option?.addressLine1 ? ", " : " (") +
                                  option.city
                                : "") +
                              ")"
                            : ""
                        }
                        className="Autocomplete-field"
                        value={selectedLocation ? selectedLocation : []}
                        // value={selectedLocation}
                        onChange={(e, value) => {
                          setSelectedLocation(value);
                          // handleGoogleLocationChange(value);
                          setLocationRequired(false);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {`${option?.internalName} (${
                              option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? option?.addressLine1
                                : ""
                            }${
                              option?.city !== "" &&
                              option?.city !== null &&
                              option?.city !== undefined
                                ? ", " + option.city
                                : ""
                            })`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("SelectLocation")}
                            {...params}
                            required
                            error={locationRequired}
                            name="language"
                          />
                        )}
                      ></Autocomplete>
                    </>
                  )}
                  {allLocation?.count > allGoogleLocation?.count > 0 && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          background: "#FFF1D7",
                          padding: "10px",
                          borderRadius: "8px",
                          marginTop: "15px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          width: "99.9%",
                        }}
                      >
                        <Grid item xs={1} sm={1} lg={1} md={1}>
                          <WarningIcon sx={{ color: "#F8A92B" }} />
                        </Grid>
                        <Grid item xs={11} sm={11} lg={11} md={11}>
                          <Typography sx={{ color: "#33250B" }}>
                            {t("alert_review_text")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            }

            // onConfirm={ () => getOldReviewData() }
            // onCancel={() => navigate("/user/add-location")}
          />
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCloseFetchReviewsMenu}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>
            {allLocation?.count > allGoogleLocation?.count > 0 && (
              <Button
                onClick={() => handleConnectWithGoogle()}
                className="delete_button"
                autoFocus
              >
                {t("Connect_with_Google")}
              </Button>
            )}
            <Button
              autoFocus
              onClick={() => getOldReviewData()}
              className="delete_button"
              disabled={selectedLocation?.length === 0 && checked === false}
            >
              {t("Fetch_Reviews")}
            </Button>
          </DialogActions>

          {/* <Delete
            title={t("Import_Google_Old_Reviews")}
            description={t("reviws_desc")}
            onConfirm={() => getOldReviewData()}
            onCancel={handleCloseFetchReviewsMenu}
          /> */}
        </Dialog>
      )}
      {openOldReviewModel && (
        <Dialog
          open={openOldReviewModel}
          onClose={handleCloseOldReviewsMenu}
          aria-labelledby="responsive-dialog-title"
          // fullScreen={fullScreen}
          // classes={{ paperWidthSm: classes.customDialogPaperWidthSm }}
        >
          <FetchModel
            title={t("old_reviews_replyy")}
            description={
              <Grid
                container
                sx={{
                  height:
                    locationValue.length === 0 || reviewCountValue === ""
                      ? "210px"
                      : "210px",
                }}
              >
                <Grid item sm={12} md={12} lg={12}>
                  <Grid container>
                    <Grid
                      item
                      sm={oldReviewLoading ? 11 : 12}
                      md={oldReviewLoading ? 11 : 12}
                      lg={oldReviewLoading ? 11 : 12}
                    >
                      {" "}
                      <Autocomplete
                        disablePortal
                        // disableCloseOnSelect
                        id="combo-box-demo"
                        options={
                          allGoogleLocation?.results?.length > 0
                            ? allGoogleLocation?.results
                            : []
                        }
                        getOptionLabel={(option) =>
                          option?.internalName
                            ? option?.internalName +
                              (option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? " (" + option.addressLine1
                                : "") +
                              (option?.city !== "" &&
                              option?.city !== null &&
                              option?.city !== undefined
                                ? (option?.addressLine1 ? ", " : " (") +
                                  option.city
                                : "") +
                              ")"
                            : ""
                        }
                        // className="Autocomplete-field"
                        value={
                          locationValue?.length > 0 ? locationValue[0] : {}
                        }
                        // value={selectedLocation}
                        onChange={(e, value) => {
                          if (value) {
                            setLocationValue([value]);
                            setLocationRequired(false);
                            setDisplayWarningMessage(false);
                          } else {
                            setLocationValue([]);
                            setDisplayWarningMessage(false);
                            setReviewCountValue("");
                            setLocationRequired(false);
                          }
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Grid
                              container
                              style={{ justifyContent: "space-between" }}
                            >
                              <Grid item xs={9} sm={9} md={9} lg={9}>
                                <span style={{ textAlign: "start" }}>
                                  {`${option?.internalName} (${
                                    option?.addressLine1 !== "" &&
                                    option?.addressLine1 !== null &&
                                    option?.addressLine1 !== undefined
                                      ? option?.addressLine1
                                      : ""
                                  }${
                                    option?.city !== "" &&
                                    option?.city !== null &&
                                    option?.city !== undefined
                                      ? ", " + option.city
                                      : ""
                                  })`}
                                </span>
                              </Grid>
                              {option?.oldReviewsReplyStatus && (
                                <Grid item={3} sm={3} md={3} lg={3}>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color:
                                        option?.oldReviewsReplyStatus ===
                                        "inprogress"
                                          ? "#FFA500"
                                          : "",
                                    }}
                                  >
                                    {option?.oldReviewsReplyStatus ==
                                    "inprogress"
                                      ? "• " + `${t("in_progress")}`
                                      : ""}
                                  </span>
                                </Grid>
                              )}
                            </Grid>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("SelectLocation")}
                            {...params}
                            required
                            error={locationRequired}
                            name="language"
                          />
                        )}
                      ></Autocomplete>
                    </Grid>
                    {oldReviewLoading && (
                      <Grid item sm={1} md={1} lg={1}>
                        <CircularProgress
                          color="secondary"
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "5px",
                            marginTop: "8px",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <>
                    {displayWarningMessage === true && (
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          background: "#FFF1D7",
                          padding: "10px",
                          borderRadius: "8px",
                          marginTop: "15px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          width: "99.9%",
                        }}
                      >
                        <Grid item xs={1} sm={1} lg={1} md={1}>
                          <WarningIcon sx={{ color: "#F8A92B" }} />
                        </Grid>
                        <Grid item xs={11} sm={11} lg={11} md={11}>
                          {
                            reviewCountValue &&
                            reviewCountValue?.alreadyExistRule === false ? (
                              <Typography>{t("No_Rules")}</Typography>
                            ) : (
                              <Typography sx={{ color: "#33250B" }}>
                                {/* {`${reviewCountValue?.reviews}`}{" "}
                              {t("old_review_warning")}{" "}
                              {`${locationValue?.name}`}{" "}
                              {t("waring_Click_message")} {t("will_take")}
                              {`${
                                reviewCountValue?.remainingDays !== null
                                  ? reviewCountValue?.remainingDays
                                  : ""
                              }`}
                              {t("warning_text")}  */}
                                {reviewMessage}
                              </Typography>
                            )

                            // : (
                            //   <Typography
                            //     display="inline"
                            //     sx={{ color: "#33250B" }}
                            //   >
                            //     {t("zero_Data_warning") + " "}
                            //     {`${locationValue?.name}`} {t("on_Google")}{" "}
                            //   </Typography>
                            // )
                          }

                          {/*  {reviewCountValue !== "" &&
                          reviewCountValue?.reviews > 0 ? (
                            <Typography sx={{ color: "#33250B" }}>
                              {`${reviewCountValue?.reviews}`}{" "}
                              {t("old_review_warning")}{" "}
                              {`${locationValue?.name}`}{" "}
                              {t("waring_Click_message")}{" "}
                            </Typography>
                          ) : (
                            <Typography
                              display="inline"
                              sx={{ color: "#33250B" }}
                            >
                              {`${reviewCountValue?.reviews}`}{" "}
                              {t("zero_Data_warning") + " "}
                              {`${locationValue?.name}`} {t("on_Google")}{" "}
                              {reviewCountValue?.alreadyExistRule===false && (
                                <>{t("No_Rules")}</>
                              )}
                            </Typography>
                          )} */}
                        </Grid>
                      </Grid>
                    )}
                  </>
                </Grid>
              </Grid>
            }

            // onConfirm={ () => getOldReviewData() }
            // onCancel={() => navigate("/user/add-location")}
          />
          <Divider />
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCloseOldReviewsMenu}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>
            <Button
              autoFocus
              onClick={() => handleOpenBulkStartModel()}
              className="delete_button"
            >
              {t("Bulk_Start")}
            </Button>

            <Button
              autoFocus
              onClick={() => handleOpenBulkStopModel()}
              className="delete_button"
            >
              {t("Bulk_stop")}
            </Button>
            {reviewCountValue?.alreadyExistRule === false && (
              <Button
                autoFocus
                onClick={() => navigate("/user/rules")}
                className="delete_button"
              >
                {t("Add_Rule")}
              </Button>
            )}

            {reviewCountValue?.oldReviewsReplyStatus === "inprogress" ? (
              <Button
                autoFocus
                onClick={pauseReplyHandler}
                className="delete_button"
              >
                {progressLoading ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  t("Pause_reply")
                )}
              </Button>
            ) : reviewCountValue?.oldReviewsReplyStatus === "paused" ? (
              <Button
                autoFocus
                onClick={getOldReviewReplyProgress}
                className="delete_button"
                disabled={reviewCountValue?.totalReviewsToReply === 0}
              >
                {progressLoading ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  t("start_reply")
                )}
              </Button>
            ) : (
              <Button
                autoFocus
                onClick={() => getOldReviewReplyProgress()}
                className="delete_button"
                disabled={
                  locationValue?.length === 0 ||
                  reviewCountValue?.reviews === 0 ||
                  reviewCountValue?.alreadyExistRule === false
                    ? true
                    : reviewCountValue?.totalReviewsToReply === 0
                    ? true
                    : false
                }
              >
                {progressLoading ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  t("Fetch_reviews")
                )}
              </Button>
            )}
          </DialogActions>

          {/* <Delete
            title={t("Import_Google_Old_Reviews")}
            description={t("reviws_desc")}
            onConfirm={() => getOldReviewData()}
            onCancel={handleCloseFetchReviewsMenu}
          /> */}
        </Dialog>
      )}
      {openModalFilter && (
        <FilterModal
          open={openModalFilter}
          close={setOpenModalFilter}
          groups={allGroups}
          tags={tags}
          sources={allReviewSource}
          locations={allLocation}
          setFilterObj={setFilterObj}
          setCallApi={setCallApi}
          modalFilters={filters}
          setModalFilters={(data) => {
            setFilters(data);
            setSearchBy(data.reviewedBy);
            if (data.starRatings.length === starRatings.length) {
              setFiltersSelectAll((prevFiltersSelectAll) => ({
                ...prevFiltersSelectAll,
                starRatings: true,
              }));
            } else {
              setFiltersSelectAll((prevFiltersSelectAll) => ({
                ...prevFiltersSelectAll,
                starRatings: false,
              }));
            }

            if (data.location.length === allLocation?.results?.length) {
              setFiltersSelectAll((prevFiltersSelectAll) => ({
                ...prevFiltersSelectAll,
                location: true,
              }));
            } else {
              setFiltersSelectAll((prevFiltersSelectAll) => ({
                ...prevFiltersSelectAll,
                location: false,
              }));
            }

            if (data.groups.length === allGroups?.rows?.length) {
              setFiltersSelectAll((prevFiltersSelectAll) => ({
                ...prevFiltersSelectAll,
                group: true,
              }));
            } else {
              setFiltersSelectAll((prevFiltersSelectAll) => ({
                ...prevFiltersSelectAll,
                group: false,
              }));
            }
          }}
          modalFiltersDate={filteredDate}
          setModalFiltersDate={(e) => {
            setFilteredDate(e);
            setFilteredModalDate(e);
          }}
          checked={checkedLocation}
          setChecked={setCheckedLocation}
          defaultSection={section}
          setPageNumber={setPageNumber}
        />

        // <FiltersModel
        //   openModalFilter={openModalFilter}
        //   handleCloseModalFilter={handleCloseModalFilter}
        //   allLocation={allLocation}
        //   handleLocationChange={handleLocationChange}
        //   setLocationId={setLocationId}
        //   setSelectedGroup={setSelectedGroup}
        //   allReviewSource={allReviewSource}
        //   handleSourceChange={handleSourceChange}
        //   setsourceId={setsourceId}
        //   starRatings={starRatings}
        //   handleChangeRatings={handleChangeRatings}
        //   setRatingsValue={setRatingsValue}
        //   replyChangeHandler={replyChangeHandler}
        //   setSelectedReply={setSelectedReply}
        //   haventReplied={haventReplied}
        //   tagsChangeHandler={tagsChangeHandler}
        //   setDraftState={setDraftState}
        //   setSelectedTags={setSelectedTags}
        //   handleChangeTime={handleChangeTime}
        //   tags={tags}
        //   filterObj={filterObj}
        //   sourceId={sourceId}
        //   locationId={locationId}
        //   selectedTags={selectedTags}
        //   selectedReply={selectedReply}
        //   ratingsValue={ratingsValue}
        //   resetHandler={resetHandler}
        //   allTimePeriod={allTimePeriod}
        //   setCallApi={setCallApi}
        //   setPageNumber={setPageNumber}
        //   handleChangeGroups={handleChangeGroups}
        //   allGroups={allGroups}
        //   selectedGroup={selectedGroup}
        //   setFilterWithName={setFilterWithName}
        //   getLocationGoogleReview={getLocationGoogleReview}
        //   filterWithName={filterWithName}
        //   startDate={filterStartDate}
        //   handleChangeEndDate={handleChangeEndDate}
        //   endDate={filterEndDate}
        //   handleChangeStartDate={handleChangeStartDate}
        //   previousYear={previousYear}
        //   today={today}
        //   setStartDate={setFilterStartDate}
        //   setEndDate={setFilterEndDate}
        //   setFilterObj={setFilterObj}
        //   setDateRange={setDateRange}
        //   dateRange={dateRange}
        //   setAllTimePeriod={setAllTimePeriod}
        // />
      )}
      {allLocation?.results?.length <= 0 ? (
        <>
          <TitleHeaderReviews
            title={t("Reviews")}
            subHeading={t("subtitle_Reviews")}
            // IframeButton={true}
            IframeButtonName={
              <BootstrapTooltip title={t("Help")}>
                <img alt="" src={QuestionMark} />
              </BootstrapTooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
          />
        </>
      ) : socialLink ? (
        <>
          <TitleHeaderReviews
            title={t("Reviews")}
            subHeading={t("subtitle_Reviews")}
            // IframeButton={true}
            IframeButtonName={
              <BootstrapTooltip title={t("Help")}>
                <img alt="" src={QuestionMark} />
              </BootstrapTooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
          />
        </>
      ) : (
        <>
          <TitleHeader
            title={t("Reviews")}
            subHeading={t("subtitle_Reviews")}
            createButton="true"
            //  extraButton={true}
            // extraButtonName={t("Import_Google_Old_Reviews")}
            oldReviewButton={true}
            oldReviewButtonName={t("old_reviews_replyy")}
            handleOpenOldReviewsMenu={handleOpenOldReviewsMenu}
            handleDefaultTemplate={handleOpenFetchReviewsMenu}
            onClick={addMoreReviewSource}
            name={t("Add_Review_Source")}
            //   IframeButton={true}
            IframeButtonName={
              <BootstrapTooltip title={t("Help")}>
                <img alt="" src={QuestionMark} />
              </BootstrapTooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
            dumpReviewsLoading={dumpReviewsLoading}
            progressLoading={progressLoading}
            addPermission={addPermission}
            viewPermission={viewPermission}
            anchorBulk={anchorBulk}
            handleClose={handleClose}
            handleClickMenu={handleClickMenu}
            handleClickMenuItem={handleClickMenuItem}
            emotionAiExportButton={true}
            emotionAiExportButtonName={t("export")}
            sheduleMenu={
              locationId?.length === 0 && selectedGroup !== null ? false : true
            }
          />
        </>
      )}
      {allLocation?.results?.length <= 0 ? (
        <>
          <Box className={classes.locationbox}>
            <LocationNotFound
              text={t("No_Location_Added")}
              subtext={t("google_reviews_text")}
            />
          </Box>
        </>
      ) : socialLink ? (
        <>
          <>
            <Box className={classes.locationbox}>
              <NotConnected
                text={t("Connect_With_Google")}
                subtext={t("Nolocation_subhead_reviews")}
              />
              <Grid container>
                <Grid item sm={1} md={1} lg={1}></Grid>
                <Grid item sm={10} md={10} lg={10}>
                  <Box sx={{ display: "flex" }}>
                    <CommonButton
                      onSubmit={handleIgnoreButton}
                      alignment={true}
                      label={t("Ignore_for_Now")}
                    />

                    <CommonButton
                      alignment={true}
                      onSubmit={handleConnectWithGoogle}
                      icon={
                        <img
                          alt=""
                          src={Googleicon}
                          style={{ paddingRight: "8px" }}
                        />
                      }
                      label={t("Connect_With_Google")}
                    />
                  </Box>
                </Grid>
                <Grid item sm={1} md={1} lg={1}></Grid>
              </Grid>
            </Box>
          </>
        </>
      ) : (
        <>
          <Grid container spacing={2} sx={{ paddingY: "4px" }}>
            {!isLoading && (
              <Grid container item xs={12} md={1.8} lg={1.8}>
                <Grid item xs={12} md={12} lg={12}>
                  <Autocomplete
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="combo-box-demo"
                    options={
                      allGroups !== null && allGroups?.rows?.length > 0
                        ? allGroups?.rows
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.name ? option?.name : ""
                    }
                    onChange={(e, value, reason) => {
                      if (value.find((option) => option.id === 0)) {
                        if (filtersSelectAll.group) {
                          setFiltersSelectAll((prevFilters) => ({
                            ...prevFilters,
                            group: false,
                          }));
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            groups: [],
                            group: [],
                          }));
                        } else {
                          setFiltersSelectAll((prevFilters) => ({
                            ...prevFilters,
                            group: true,
                          }));
                          let groupIds = allGroups?.rows?.map(
                            (item) => item.id
                          );
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            groups: groupIds,
                            group: allGroups?.rows,
                          }));
                        }
                      } else {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          group: value,
                          groups: value.map((item) => item.id),
                        }));
                        if (value.length === allGroups?.rows?.length) {
                          setFiltersSelectAll((prevFilters) => ({
                            ...prevFilters,
                            group: true,
                          }));
                        } else {
                          setFiltersSelectAll((prevFilters) => ({
                            ...prevFilters,
                            group: false,
                          }));
                        }
                      }
                      // if (reason === "clear") {
                      //   checkedLocation
                      //     ? setFilters((prevFilters) => ({
                      //         ...prevFilters,
                      //         groups: 0,
                      //         group: [],
                      //         locations: [],
                      //         location: [],
                      //       }))
                      //     : setFilters((prevFilters) => ({
                      //         ...prevFilters,
                      //         groups: 0,
                      //         group: [],
                      //       }));
                      // } else {

                      //   // const ids = value.groups.map(
                      //   //   (group) => group.locationId
                      //   // );
                      //   // checkedLocation
                      //   //   ? setFilters((prevFilters) => ({
                      //   //       ...prevFilters,
                      //   //       groups: value !== null ? value.id : 0,
                      //   //       group: value !== null ? [value] : [],
                      //   //       locations: ids,
                      //   //       location: allLocation?.results?.filter((loc) =>
                      //   //         ids.includes(loc.id)
                      //   //       ),
                      //   //     }))
                      //   //   : setFilters((prevFilters) => ({
                      //   //       ...prevFilters,
                      //   //       groups: value !== null ? value.id : 0,
                      //   //       group: value !== null ? [value] : [],
                      //   //     }));
                      // }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allGroups?.rows?.length > 0) {
                        return [{ id: 0, name: "Select All" }, ...filtered];
                      } else {
                        return [];
                      }
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={filtersSelectAll.group || selected}
                        />
                        <Typography>
                          <span style={{ textAlign: "start" }}>
                            {option?.name}
                          </span>
                        </Typography>
                      </li>
                    )}
                    renderTags={(value, getTagProps) => {
                      const MAX_DISPLAY_ITEMS = 2;
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              flexWrap: "nowrap",
                            }}
                          >
                            {value
                              .slice(0, MAX_DISPLAY_ITEMS)
                              .map((option, index) => (
                                <Chip
                                  style={{
                                    background: "#FFF",
                                    border: "1px solid #06BDFF",
                                    borderRadius: "800px",
                                  }}
                                  key={index}
                                  label={option?.name}
                                  {...getTagProps({ index })}
                                />
                              ))}
                            {value.length > MAX_DISPLAY_ITEMS && (
                              <Chip
                                style={{
                                  background: "#FFF",
                                  border: "1px solid #BBBDC1",
                                  borderRadius: "800px",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  width: "100px",
                                }}
                                onClick={() => handleOpenEditUser()}
                                label={`+${
                                  value.length - MAX_DISPLAY_ITEMS
                                } more`}
                              />
                            )}
                          </Stack>
                        </Box>
                      );
                    }}
                    className="Autocomplete-field"
                    value={filters.group}
                    defaultValue={null}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select_group")}
                        {...params}
                        required
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                  >
                    {/* {locationViewPermission && locationAddPermission ? (
                      <BootstrapTooltip title={t("manage_groups")}>
                        <IconButton
                          onClick={() => handleNavigationToGroups()}
                          sx={{ padding: 0, cursor: "pointer" }}
                        >
                          <AddGroup />
                        </IconButton>
                      </BootstrapTooltip>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            disabled
                            onClick={() => handleNavigationToGroups()}
                            sx={{ padding: 0, cursor: "pointer" }}
                          >
                            <AddGroup />
                          </IconButton>
                        </span>
                      </BootstrapTooltip>
                    )}
                    <Grid
                      sx={{
                        height: "20px",
                        width: "1px",
                        border: "1px solid #E0E0E0",
                      }}
                    ></Grid> */}
                    {/* <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Checkbox
                        checked={checkedLocation}
                        onChange={(e) => {
                          handleChangeAutoSelect(e.target.checked);
                        }}
                        size="small"
                        sx={{
                          paddingLeft: "0px",
                        }}
                      />
                      <Typography sx={{ fontSize: "12px", overflow: "hidden" }}>
                        {t("auto-select-checkbox-group")}
                      </Typography>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={12} md={2.8} lg={2.8}>
                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  error={locationRequired}
                  id="combo-box-demo"
                  options={
                    allLocation?.results?.length > 0 ? allLocation?.results : []
                  }
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? " (" + option.addressLine1
                          : "") +
                        (option?.city !== "" &&
                        option?.city !== null &&
                        option?.city !== undefined
                          ? (option?.addressLine1 ? ", " : " (") + option.city
                          : "") +
                        ")"
                      : ""
                  }
                  sx={{ bgcolor: "white" }}
                  value={filters.location}
                  onChange={(e, value) => {
                    if (value.find((option) => option.id === 0)) {
                      if (filtersSelectAll.location) {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          location: false,
                        }));
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          locations: [],
                          location: [],
                        }));
                      } else {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          location: true,
                        }));
                        let locationIds = allLocation?.results?.map(
                          (item) => item.id
                        );
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          locations: locationIds,
                          location: allLocation?.results,
                        }));
                      }
                    } else {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        location: value,
                        locations: value.map((item) => item.id),
                      }));
                      if (value.length === allLocation.results.length) {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          location: true,
                        }));
                      } else {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          location: false,
                        }));
                      }
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, internalName: "Select All" }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={filtersSelectAll.location || selected}
                      />
                      <Typography>
                        <span style={{ textAlign: "start" }}>
                          {`${option?.internalName} ${
                            option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                              ? "(" + option?.addressLine1 + "," + " "
                              : ""
                          } ${option?.city ? option?.city + ")" : ""}`}
                        </span>
                      </Typography>
                    </li>
                  )}
                  renderTags={(value, getTagProps) => {
                    const MAX_DISPLAY_ITEMS = 2;
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexWrap: "nowrap",
                          }}
                        >
                          {value
                            .slice(0, MAX_DISPLAY_ITEMS)
                            .map((option, index) => (
                              <Chip
                                style={{
                                  background: "#FFF",
                                  border: "1px solid #06BDFF",
                                  borderRadius: "800px",
                                }}
                                key={index}
                                label={`${option?.internalName} ${
                                  option?.addressLine1 !== "" &&
                                  option?.addressLine1 !== null &&
                                  option?.addressLine1 !== undefined
                                    ? "(" + option?.addressLine1 + "," + " "
                                    : ""
                                } ${option?.city ? option?.city + ")" : ""}`}
                                {...getTagProps({ index })}
                              />
                            ))}
                          {value.length > MAX_DISPLAY_ITEMS && (
                            <Chip
                              style={{
                                background: "#FFF",
                                border: "1px solid #BBBDC1",
                                borderRadius: "800px",
                                cursor: "pointer",
                                fontWeight: 700,
                                width: "100px",
                              }}
                              onClick={() => handleOpenEditUser()}
                              label={`+${
                                value.length - MAX_DISPLAY_ITEMS
                              } more`}
                            />
                          )}
                        </Stack>
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={12} md={1.8} lg={1.8}>
                <SearchField
                  filterByName={(e) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      reviewedBy: e.target.value,
                    }));
                  }}
                  onSubmit={() => {}}
                  placeholder={t("search")}
                  filterWithName={filters.reviewedBy}
                />
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={12} md={1.8} lg={1.8}>
                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  value={filters.rate}
                  options={starRatings}
                  getOptionLabel={(option) => t(option?.name)}
                  sx={{ bgcolor: "white" }}
                  onChange={(event, value) => {
                    if (value.find((option) => option.id === 0)) {
                      if (filtersSelectAll.starRatings) {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          starRatings: false,
                        }));
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          starRatings: [],
                          rate: [],
                        }));
                      } else {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          starRatings: true,
                        }));
                        let starRatingIds = starRatings.map((item) => item.id);
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          starRatings: starRatingIds,
                          rate: starRatings,
                        }));
                      }
                    } else {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        rate: value,
                        starRatings: value.map((item) => item.value),
                      }));
                      if (value.length === starRatings.length) {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          starRatings: true,
                        }));
                      } else {
                        setFiltersSelectAll((prevFilters) => ({
                          ...prevFilters,
                          starRatings: false,
                        }));
                      }
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, name: "Select_All" }, ...filtered];
                  }}
                  renderOption={(props, option) => {
                    const isSelected = filters.rate.some(
                      (selectedOption) => selectedOption.id === option.id
                    );
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={filtersSelectAll.starRatings || isSelected}
                        />
                        {t(option?.name)}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) => {
                    const MAX_DISPLAY_ITEMS = 2;
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexWrap: "nowrap",
                          }}
                        >
                          {value
                            .slice(0, MAX_DISPLAY_ITEMS)
                            .map((option, index) => (
                              <Chip
                                style={{
                                  background: "#FFF",
                                  border: "1px solid #06BDFF",
                                  borderRadius: "800px",
                                }}
                                key={index}
                                label={t(option?.name)}
                                {...getTagProps({ index })}
                              />
                            ))}
                          {value.length > MAX_DISPLAY_ITEMS && (
                            <Chip
                              style={{
                                background: "#FFF",
                                border: "1px solid #BBBDC1",
                                borderRadius: "800px",
                                cursor: "pointer",
                                fontWeight: 700,
                                width: "100px",
                              }}
                              onClick={() => handleOpenEditUser()}
                              label={`+${
                                value.length - MAX_DISPLAY_ITEMS
                              } more`}
                            />
                          )}
                        </Stack>
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Rating")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                />
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={12} md={1.8} lg={1.8}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={timePeriod}
                  getOptionLabel={(option) =>
                    option?.name ? t(option?.name) : ""
                  }
                  className="Autocomplete-field"
                  value={filters.time}
                  // clearIcon={
                  //   filters.timePeriod === "All" ? (
                  //     <CloseIcon sx={{ display: "none" }} />
                  //   ) : (
                  //     <CloseIcon />
                  //   )
                  // }
                  onChange={(e, value, reason) => {
                    if (reason === "clear") {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        timePeriod: "",
                        time: { id: 0, name: "", value: "" },
                      }));
                    } else {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        timePeriod: value.value,
                        time: value,
                      }));
                      handleChangeTime(value.value);
                      setCallApi(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Time_Period")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}
            {!isLoading && (
              <Grid
                item
                spacing={2}
                container
                xs={12}
                md={2}
                lg={2}
                sx={{ placeContent: "baseline" }}
              >
                <Grid item xs={12} sm={7} md={7} lg={7}>
                  <CommonButton
                    leftMargin={true}
                    alignment={true}
                    label={t("Apply_filters")}
                    onSubmit={handleApplyFilter}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <CommonButton
                    leftMargin={true}
                    alignment={true}
                    displayBlack="true"
                    icon={<RefreshIcon />}
                    label={t("Reset")}
                    onSubmit={resetHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setSection("locations");
                        handleOpenEditUser();
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        {t("View_More_Filters")}.
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
          {displayReconnectError && (
            <Grid container sx={{ marginBottom: "5px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ReconnectCard
                  location={expiredLocations}
                  displayReconnectButton={displayReconnectButton}
                  addPermission={locationAddPermission}
                  editPermission={locationEditPermission}
                  deletePermission={locationDeletePermission}
                  viewPermission={locationViewPermission}
                />
              </Grid>
            </Grid>
          )}
          {reviewsLoading ? (
            <Loader />
          ) : (
            <>
              {reviewsData?.results?.length > 0 ? (
                <>
                  <Stack
                    marginBottom="2rem"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h1">
                      {reviewsData?.count} &nbsp;
                      {t("Reviews")}
                    </Typography>
                  </Stack>
                  <Virtuoso
                    data={reviewsData?.results}
                    endReached={fetchMoreData}
                    itemContent={(index) => (
                      <div className="scrollLeft">
                        <div>
                          <SourceReviewsCard
                            search={searchBy}
                            item={reviewsData?.results?.[index]}
                            allReviewSource={allReviewSource?.results}
                            allReviewSourceForAgent={allReviewSourceForAgent}
                            enabledReviewSources={enabledReviewSources}
                            refreshTags={refreshTags}
                            tags={tags}
                            getLocationGoogleReview={getLocationGoogleReview}
                            allTemplate={allTemplate}
                            users={users}
                            locations={allLocation?.results}
                            editPermission={editPermission}
                            addPermission={addPermission}
                            viewPermission={viewPermission}
                            deletePermission={deletePermission}
                            viewPermissionTasks={viewPermissionTasks}
                            addPermissionTasks={addPermissionTasks}
                            editPermissionTasks={editPermissionTasks}
                            index={index}
                            allData={reviewsData}
                            setReviewsData={setReviewsData}
                          />
                        </div>
                        <Divider
                          variant="middle"
                          sx={{ marginBottom: "10px" }}
                        />
                      </div>
                    )}
                  />
                </>
              ) : (
                <NotFound text={t("No_Reviews_Yet")} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default GoogleReviews;
