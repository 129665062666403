import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  DialogTitle,
  TextField,
  Box,
  DialogContent,
  Dialog,
  IconButton,
  Avatar,
  Autocomplete,
  Checkbox,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Loader from "../../../../../../../../components/Loaders/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageModel from "../../UploadModels/CoverImageModal";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../../contexts/JWTContext";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { countriesAndCurrencies } from "../../../../../../../../assets/countryCurrency";
import { useStyles } from "../../../../../Styles/style";
import noImageGray from "../../../../../../../../assets/images/noImageGray.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PreviewImageModal from "../../PreviewImageModal/PreviewImageModal";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const AddProductSection = ({
  title,
  open,
  handleCloseProductSectionModel,
  selectedItemData,
  selectedSectionData,
  setTouchedValue,
  getLocationDetails,
  location,
  locationDetailsData,
  setIsLoading,
  isLoading,
  getLocationDetailsListing,
  openFrom,
  getAllMenus,
  locationProducts,
  selectedLocationsBulkUpdate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [sectionName, setSectionName] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [currency, setCurrency] = useState(null);
  const [itemUrl, setItemUrl] = useState("");
  const [uniqueCurrencyCode, setUniqueCurrencyCode] = useState([]);
  const [openImageUploadModal, setOpenImageUploadModel] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [itemImage, setItemImage] = useState(null);
  const [images, setImages] = useState([]);
  const [loadImg, setLoadImg] = useState(false);
  const [openPreviewImageModal, setOpenPreviewImageModal] = useState(false);
  const [displayButtonGrid, setDisplayButtonGrid] = useState(false);
  const [indexValueArr, setIndexValueArr] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [veganChecked, setVeganChecked] = useState(false);
  const [vegetarianChecked, setVegetarianChecked] = useState(false);
  const [priceRequired, setPriceRequired] = useState(false);
  const [currencyRequired, setCurrencyRequired] = useState(false);
  const [sectionError, setSectionError] = useState(false);
  const [itemError, setItemError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [duplicateSection, setDuplicateSection] = useState(false);
  const [duplicateItem, setDuplicateItem] = useState(false);
  const [inValidPrice, setInValidPrice] = useState(false);

  useEffect(() => {
    const sortedCountriesAndCurrencies = countriesAndCurrencies.sort((a, b) => {
      if (a.currency < b.currency) {
        return -1;
      }
      if (a.currency > b.currency) {
        return 1;
      }
      return 0;
    });

    setUniqueCurrencyCode(sortedCountriesAndCurrencies);
    if (openFrom !== null && openFrom === "edit") {
      setIsLoading(true);

      if (selectedSectionData?.labels?.length > 0) {
        setSectionName(selectedSectionData?.labels[0]?.displayName);
      }
      if (selectedItemData?.labels?.length) {
        setItemName(selectedItemData?.labels[0]?.displayName);
      }
      if (
        selectedItemData?.attributes &&
        Object.keys(selectedItemData.attributes).length > 0 &&
        selectedItemData?.attributes?.hasOwnProperty("dietaryRestriction")
      ) {
        if (
          selectedItemData?.attributes?.dietaryRestriction?.length > 0 &&
          selectedItemData?.attributes?.dietaryRestriction?.includes(
            "VEGETARIAN"
          )
        ) {
          setVegetarianChecked(true);
          setVeganChecked(false);
        } else if (
          selectedItemData?.attributes?.dietaryRestriction?.length > 0 &&
          selectedItemData?.attributes?.dietaryRestriction?.includes("VEGAN")
        ) {
          setVegetarianChecked(false);
          setVeganChecked(true);
        }
      }
      if (
        selectedItemData?.attributes &&
        Object.keys(selectedItemData.attributes).length > 0 &&
        selectedItemData?.attributes?.price
      ) {
        if (
          selectedItemData.attributes.price?.hasOwnProperty("units") &&
          selectedItemData.attributes.price?.hasOwnProperty("nanos") &&
          selectedItemData.attributes.price?.nanos !== null
        ) {
          let priceUnits = selectedItemData.attributes.price.units;
          let priceNanos =
            selectedItemData.attributes.price?.nanos?.toString().slice(0, 2) ||
            "00"; // Default to "00" if nanos is missing
          let formattedPrice = parseFloat(`${priceUnits}.${priceNanos}`);
          setItemPrice(parseFloat(formattedPrice));
        } else if (
          selectedItemData.attributes.price?.hasOwnProperty("units") == false &&
          selectedItemData.attributes.price?.hasOwnProperty("nanos")
        ) {
          let priceUnits = 0;
          let priceNanos =
            selectedItemData.attributes.price?.nanos?.toString().slice(0, 2) ||
            "00"; // Default to "00" if nanos is missing
          let formattedPrice = parseFloat(`${priceUnits}.${priceNanos}`);
          setItemPrice(parseFloat(formattedPrice));
        } else if (
          selectedItemData.attributes.price?.hasOwnProperty("units") == true &&
          selectedItemData.attributes.price?.hasOwnProperty("nanos") == false
        ) {
          setItemPrice(parseInt(selectedItemData.attributes.price.units));
        }

        /*  setItemPrice(
            selectedItemData.attributes.price.units +
              "." +
              selectedItemData.attributes.price?.nanos?.toString().slice(0, 2)
          ); */

        const selectedCureency = countriesAndCurrencies?.find(
          (item) =>
            item?.currency === selectedItemData.attributes.price.currencyCode
        );
        setCurrency(selectedCureency);
      }

      if (
        selectedItemData?.attributes &&
        Object.keys(selectedItemData.attributes).length > 0 &&
        selectedItemData?.attributes?.mediaKeys?.length > 0
      ) {
        const selectedImageData = handleCheckForImage(selectedItemData);
        if (selectedImageData !== null && selectedImageData !== undefined) {
          setItemImage(
            selectedImageData?.sourceUrl
              ? selectedImageData?.sourceUrl
              : selectedImageData
          );
        } else {
          setItemImage(null);
        }
      }
      if (
        selectedItemData?.labels?.length > 0 &&
        selectedItemData?.labels[0]?.hasOwnProperty("description")
      ) {
        setItemDescription(selectedItemData?.labels[0]?.description);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, []);

  const handleCheckForImage = (selectedItemValue) => {
    if (locationDetailsData !== null)
      if (locationDetailsData?.result?.google?.media?.length > 0) {
        if (
          selectedItemValue?.attributes?.mediaKeys[0]?.includes("https") ===
          false
        ) {
          const selectedImageItem =
            locationDetailsData?.result?.google?.media?.find((item) =>
              item?.name?.includes(
                selectedItemValue?.attributes?.mediaKeys?.length > 0
                  ? selectedItemValue?.attributes?.mediaKeys[0]
                  : ""
              )
            );

          return selectedImageItem;
        } else {
          return selectedItemValue?.attributes?.mediaKeys[0];
        }
      }
  };

  const handleCheckForSameName = (newName) => {
    if (locationDetailsData !== null) {
      if (locationProducts?.length > 0) {
        let nameFound = false;

        if (locationProducts[0]?.sections?.length > 0) {
          nameFound = locationProducts[0]?.sections?.some((item) => {
            return (
              item?.labels?.length > 0 &&
              item?.labels[0]?.displayName.toLowerCase() ===
                newName.toLowerCase().trim("")
            );
          });
        }

        return nameFound; // Return the result of the check
      } else {
        return false;
      }
    }

    return false; // In case locationDetailsData is null
  };

  const handleCheckForItemName = (newName) => {
    if (locationDetailsData !== null) {
      if (locationProducts?.length > 0) {
        let nameFound = false;

        if (
          locationProducts[0]?.sections?.length > 0 &&
          selectedSectionData !== null &&
          selectedSectionData?.items?.length > 0
        ) {
          nameFound = selectedSectionData?.items?.some((item) => {
            return (
              item?.labels?.length > 0 &&
              item?.labels[0]?.displayName.toLowerCase() ===
                newName.toLowerCase().trim("")
            );
          });
        }

        return nameFound; // Return the result of the check
      } else {
        return false;
      }
    }

    return false; // In case locationDetailsData is null
  };

  const handleChangeSection = (e) => {
    setSectionName(e);

    if (handleCheckForSameName(e)) {
      setDuplicateSection(true);
    } else {
      setDuplicateSection(false);
    }

    if (e.length > 140) {
      setSectionError(true);
    } else {
      setSectionError(false);
    }
  };
  const handleChangeItemName = (e) => {
    setItemName(e);

    if (handleCheckForItemName(e)) {
      setDuplicateItem(true);
    } else {
      setDuplicateItem(false);
    }

    if (e.length > 140) {
      setItemError(true);
    } else {
      setItemError(false);
    }
  };
  const handleChangeItemPrice = (e) => {
    setItemPrice(parseFloat(e));
    if (/^\d*\.?\d{0,2}$/.test(e)) {
      setInValidPrice(false);
    } else {
      setInValidPrice(true);
    }
    if (e !== "" && e !== null && (currency === "" || currency === null)) {
      setCurrencyRequired(true);
      setPriceRequired(false);
    } else {
      if (currency !== "" && currency !== null && e === "") {
        setPriceRequired(true);
      } else {
        setPriceRequired(false);
      }
      setCurrencyRequired(false);
    }
  };
  const handleChangeItemDescription = (e) => {
    setItemDescription(e);
    if (e.length > 1000) {
      setdescriptionError(true);
    } else {
      setdescriptionError(false);
    }
  };
  const handleChangeProductUrl = (e) => {
    setItemUrl(e);
  };

  const handleOpenImageUploadModal = () => {
    setOpenImageUploadModel(true);
  };
  const handleCloseImageUploadModal = (boolValue) => {
    setOpenImageUploadModel(false);
    if (uploadedImages?.length > 0 && boolValue) {
      setItemImage(uploadedImages[0]);
    } else {
      setItemImage(null);
    }
  };
  const handleRemoveFile = () => {
    setSelectedFile(null);
    setUploadedImages([]);
    setImages([]);
    setItemImage(null);
    if (openPreviewImageModal) {
      setOpenPreviewImageModal(false);
    }
  };
  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  const handleDataToUpdate = (data) => {
    let menuData = locationDetailsData?.result?.menus;
    if (openFrom === "edit") {
      const indexValue =
        data?.items?.length > 0 &&
        data?.items?.findIndex(
          (item) =>
            item?.labels[0]?.displayName ===
            selectedItemData?.labels[0]?.displayName
        );

      if (indexValue > -1) {
        if (!data.items[indexValue].attributes) {
          data.items[indexValue].attributes = {};
        }

        // Ensure attributes.mediaKeys exists
        if (itemImage !== null) {
          data.items[indexValue].attributes["mediaKeys"] =
            itemImage !== null ? [itemImage] : [];
        } else {
          delete data.items[indexValue].attributes["mediaKeys"];
        }

        if (!data.items[indexValue].attributes["dietaryRestriction"]) {
          data.items[indexValue].attributes["dietaryRestriction"] = [];
        }

        // Ensure attributes.price exists and has the necessary structure
        if (!data.items[indexValue].attributes["price"]) {
          data.items[indexValue].attributes["price"] = {};
        }

        // Ensure price.units exists
        if (!data.items[indexValue].attributes["price"]["units"]) {
          data.items[indexValue].attributes["price"]["units"] = 0; // Default value or your choice
        }

        // Ensure price.currencyCode exists
        if (!data.items[indexValue].attributes["price"]["currencyCode"]) {
          data.items[indexValue].attributes["price"]["currencyCode"] = ""; // Default value or your choice
        }

        // Update price.units and price.currencyCode
        if (itemPrice !== null && itemPrice !== "") {
          if (!Number.isInteger(parseFloat(itemPrice))) {
            let [integerPart, decimalPart] = itemPrice.toString().split(".");

            data.items[indexValue].attributes["price"]["units"] = integerPart;
            data.items[indexValue].attributes["price"]["nanos"] = parseInt(
              decimalPart.toString().padEnd(9, "0")
            );
            data.items[indexValue].attributes["price"]["currencyCode"] =
              currency !== null ? currency?.currency : "";
          } else {
            data.items[indexValue].attributes["price"]["units"] = itemPrice;
            data.items[indexValue].attributes["price"]["currencyCode"] =
              currency !== null ? currency?.currency : "";
            delete data.items[indexValue].attributes["price"]["nanos"];
          }
        } else {
          delete data.items[indexValue].attributes["price"];
        }

        // Ensure labels[0] exists
        if (
          !data.items[indexValue].labels ||
          !data.items[indexValue].labels[0]
        ) {
          data.items[indexValue].labels = [{}]; // Create an array with an empty object
        }

        if (vegetarianChecked && veganChecked === false) {
          data.items[indexValue].attributes["dietaryRestriction"] = [
            "VEGETARIAN",
          ];
        } else if (vegetarianChecked === false && veganChecked) {
          data.items[indexValue].attributes["dietaryRestriction"] = ["VEGAN"];
        } else {
          delete data.items[indexValue].attributes["dietaryRestriction"];
        }

        data.items[indexValue].labels[0].displayName = itemName;
        if (itemDescription !== null && itemDescription !== "") {
          data.items[indexValue].labels[0].description = itemDescription;
        } else {
          delete data.items[indexValue].labels[0].description;
        }
      }

      let sectionIndex = menuData[0]?.sections?.findIndex(
        (item) =>
          item?.labels[0]?.displayName ===
          selectedSectionData?.labels[0]?.displayName
      );

      if (sectionIndex > -1) {
        menuData[0].sections[sectionIndex] = data;
      }
    } else if (openFrom === "add") {
      let obj = {
        labels: [
          {
            displayName: itemName,
          },
        ],
        attributes: {},
      };
      if (itemDescription !== null && itemDescription !== "") {
        obj.labels[0]["description"] = itemDescription;
      } else {
        delete obj.labels[0]["description"];
      }
      if (itemPrice !== null && itemPrice !== "") {
        if (!obj.attributes["price"]) {
          obj.attributes["price"] = {};
        }

        if (!Number.isInteger(parseFloat(itemPrice))) {
          let [integerPart, decimalPart] = itemPrice.toString().split(".");
          obj.attributes["price"]["units"] = integerPart;
          obj.attributes["price"]["nanos"] = parseInt(
            decimalPart.toString().padEnd(9, "0")
          );
          obj.attributes["price"]["currencyCode"] =
            currency !== null ? currency?.currency : "";
        } else {
          obj.attributes["price"]["units"] = itemPrice;
          obj.attributes["price"]["currencyCode"] =
            currency !== null ? currency?.currency : "";
          delete obj.attributes["price"]["nanos"];
        }
      } else {
        delete obj.attributes["price"];
      }
      if (vegetarianChecked === true || veganChecked === true) {
        if (vegetarianChecked && veganChecked === false) {
          obj.attributes["dietaryRestriction"] = ["VEGETARIAN"];
        } else if (vegetarianChecked === false && veganChecked) {
          obj.attributes["dietaryRestriction"] = ["VEGAN"];
        }
      } else {
        delete obj.attributes["dietaryRestriction"];
      }
      if (itemImage !== null) {
        obj.attributes["mediaKeys"] = [itemImage];
      } else {
        delete obj.attributes["mediaKeys"];
      }

      let sectionIndex = menuData[0]?.sections?.findIndex(
        (item) =>
          item?.labels[0]?.displayName ===
          selectedSectionData?.labels[0]?.displayName
      );

      if (sectionIndex > -1) {
        menuData[0].sections[sectionIndex].items?.push(obj);
      }
    } else if (openFrom === "section") {
      let obj = {
        labels: [
          {
            displayName: sectionName,
          },
        ],
        items: [
          {
            labels: [
              {
                displayName: itemName,
              },
            ],
            attributes: {},
          },
        ],
      };
      if (itemDescription !== null && itemDescription !== "") {
        obj.items[0].labels[0]["description"] = itemDescription;
      } else {
        delete obj.items[0].labels[0]["description"];
      }
      if (itemPrice !== null && itemPrice !== "") {
        if (!obj.items[0].attributes["price"]) {
          obj.items[0].attributes["price"] = {};
        }
        if (!Number.isInteger(parseFloat(itemPrice))) {
          let [integerPart, decimalPart] = itemPrice.toString().split(".");
          obj.items[0].attributes["price"]["units"] = integerPart;
          obj.items[0].attributes["price"]["nanos"] = parseInt(
            decimalPart.toString().padEnd(9, "0")
          );
          obj.items[0].attributes["price"]["currencyCode"] =
            currency !== null ? currency?.currency : "";
        } else {
          obj.items[0].attributes["price"]["units"] = itemPrice;
          obj.items[0].attributes["price"]["currencyCode"] =
            currency !== null ? currency?.currency : "";
          delete obj.items[0].attributes["price"]["nanos"];
        }
      } else {
        delete obj.items[0].attributes["price"];
      }
      if (vegetarianChecked === true || veganChecked === true) {
        if (vegetarianChecked && veganChecked === false) {
          obj.items[0].attributes["dietaryRestriction"] = ["VEGETARIAN"];
        } else if (vegetarianChecked === false && veganChecked) {
          obj.items[0].attributes["dietaryRestriction"] = ["VEGAN"];
        }
      } else {
        delete obj.items[0].attributes["dietaryRestriction"];
      }
      if (itemImage !== null) {
        obj.items[0].attributes["mediaKeys"] = [itemImage];
      } else {
        delete obj.items[0].attributes["mediaKeys"];
      }
      if (menuData !== null && menuData?.length > 0) {
        menuData[0].sections?.push(obj);
      } else {
        let objTwo = [
          {
            labels: [
              {
                displayName: "Menu",
              },
            ],
            sections: [obj],
          },
        ];
        menuData = objTwo;
      }
    }

    return menuData;
  };

  const handleUpdateSectionItemData = async () => {
    let sectionDataToUpdate = {
      ...selectedSectionData,
    };
    let menuData = handleDataToUpdate(sectionDataToUpdate);
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }

    try {
      setIsLoading(true);

      const res = await api.post(`/menus`, {
        menus: menuData,
        locationId: locationIds,
      });

      if (res.status === 200) {
        getAllMenus();
        setIsLoading(false);
        // getLocationDetailsListing(location);
        toast.success(`${t("Updated_Successfully")}`);
        handleCloseProductSectionModel();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleOpenPreviewImage = (images, displayGrid, value) => {
    setPreviewImages(images);
    setOpenPreviewImageModal(true);
    setDisplayButtonGrid(displayGrid);
    if (value !== undefined) {
      setIndexValueArr(value);
    } else {
      setIndexValueArr(null);
    }
  };
  const handleClosePreviewImage = () => {
    setOpenPreviewImageModal(false);
  };

  const handleVeganChecked = (e) => {
    setVeganChecked(e.target.checked);
    if (e.target.checked) {
      setVegetarianChecked(false);
    }
  };
  const handleVegetarianChecked = (e) => {
    setVegetarianChecked(e.target.checked);

    if (e.target.checked) {
      setVeganChecked(false);
    }
  };

  return (
    <>
      {openPreviewImageModal && (
        <PreviewImageModal
          handleClickOpen={handleOpenPreviewImage}
          open={openPreviewImageModal}
          handleClose={handleClosePreviewImage}
          previewImages={previewImages}
          handleDeleteImage={handleRemoveFile}
          title={t("Item_image")}
          displayButtonGrid={false}
          indexOfItem={indexValueArr}
          //  handleDeleteImagePreview={() => {}}
          mediaCount={1}
          previewLoading={false}
          /*   fetchMoreData={
          value === 0
            ? null
            : value === 1
            ? fetchMoreTeamsData
            : value === 2
            ? fetchMoreAdditionalData
            : value === 3
            ? fetchMoreExteriorData
            : fetchMoreInteriorData
        } */
        />
      )}
      {openImageUploadModal && (
        <ImageModel
          title={t("upload_imageItem")}
          handleClose={handleCloseImageUploadModal}
          open={openImageUploadModal}
          selectedImages={images}
          setSelectedImages={setImages}
          setSelectedFile={setSelectedFile}
          setUploadedImages={setUploadedImages}
          uploadedImages={uploadedImages}
          locationId={location?.id}
          setTouchedValue={setTouchedValue}
        />
      )}
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "700px",
              height: "650px",
            },
          }}
          open={open}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "12px",
            }}
          >
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "20px",
                color: "#1b2430",
                marginTop: "3px",
              }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {" "}
                {openFrom == "section" && (
                  <Grid item xs={12} md={12} lg={12} mt={1} mb={2}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography className={classes.fieldTextService}>
                        {t("section_name")}{" "}
                        <span style={{ color: "#FC3652" }}>*</span>
                      </Typography>
                      <Typography className={classes.characterLimitTypo}>
                        {sectionName?.length + "/140"}
                      </Typography>
                    </Box>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder={t("enter_section_name")}
                      value={sectionName}
                      fullWidth
                      error={sectionError || duplicateSection}
                      onChange={(e) => handleChangeSection(e.target.value)}
                      helperText={
                        sectionError
                          ? t("sectionError")
                          : duplicateSection
                          ? t("duplicate_section")
                          : ""
                      }
                    />
                  </Grid>
                )}
                <Grid container>
                  <Grid
                    container
                    // spacing={1}
                    className={classes.productSectionGrid}
                  >
                    {openFrom === "section" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.fieldTextService}>
                          {t("add_product_Item")}{" "}
                          <span style={{ color: "#FC3652" }}>*</span>
                        </Typography>
                        <Typography
                          className={classes.fieldTextServiceSubheading}
                        >
                          {t("add_product_Item_subHead")}
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.girdSpacing}
                      // sx={{ padding: "0px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className={classes.fieldTextService}>
                          {t("Item_Name")}{" "}
                          <span style={{ color: "#FC3652" }}>*</span>
                        </Typography>
                        <Typography className={classes.characterLimitTypo}>
                          {itemName?.length + "/140"}
                        </Typography>
                      </Box>

                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        error={itemError || duplicateItem}
                        placeholder={t("Enter_Item_Name")}
                        value={itemName}
                        type="text"
                        fullWidth
                        helperText={
                          itemError
                            ? t("sectionError")
                            : duplicateItem
                            ? t("duplicateItem")
                            : ""
                        }
                        onChange={(e) => handleChangeItemName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className={classes.girdSpacing}
                    >
                      <Typography className={classes.fieldTextService}>
                        {t("Item_price")}
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        error={priceRequired || inValidPrice}
                        placeholder={t("itemPrice_placeholder")}
                        value={itemPrice}
                        inputProps={{
                          inputMode: "numeric",
                          type: "number",
                        }}
                        sx={{ width: "98%" }}
                        helperText={inValidPrice && t("invalid_amout")}
                        onChange={(e) => handleChangeItemPrice(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={6}
                      lg={6}
                      className={classes.girdSpacing}
                    >
                      <Typography className={classes.fieldTextService}>
                        {t("Item_currency")}
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={uniqueCurrencyCode}
                        getOptionLabel={(option) => option?.currency}
                        className="Autocomplete-field"
                        value={currency}
                        onChange={(e, value) => {
                          setCurrency(value);

                          if (
                            value !== null &&
                            value !== "" &&
                            itemPrice === ""
                          ) {
                            setPriceRequired(true);
                            setCurrencyRequired(false);
                          } else if (
                            (value == null || value == "") &&
                            itemPrice !== ""
                          ) {
                            setPriceRequired(false);
                            setCurrencyRequired(true);
                          } else {
                            setPriceRequired(false);
                            setCurrencyRequired(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="e.g USD"
                            {...params}
                            required
                            error={currencyRequired}
                            name="language"
                          />
                        )}
                      />
                      {/*   <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          sx={{
                            color: "#A2A2A2",
                          }}
                        >
                          {"e.g USD"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          label="e.g USD"
                          id="demo-simple-select-helper"
                          value={currency}
                          onChange={(event) => {
                            const selectedValue = event.target.value;
                            setCurrency(selectedValue);

                            if (
                              selectedValue !== null &&
                              selectedValue !== "" &&
                              itemPrice === ""
                            ) {
                              setPriceRequired(true);
                              setCurrencyRequired(false);
                            } else {
                              setPriceRequired(false);
                              setCurrencyRequired(false);
                            }
                          }}
                          error={currencyRequired}
                          sx={{ width: "100%", marginTop: "1px" }}
                        >
                          {uniqueCurrencyCode?.length > 0 &&
                            uniqueCurrencyCode?.map((item) => (
                              <MenuItem
                                key={item.currency}
                                value={item.currency}
                              >
                                {item?.currency}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl> */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.girdSpacing}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className={classes.fieldTextService}>
                          {t("Item_description")}
                        </Typography>
                        <Typography className={classes.characterLimitTypo}>
                          {itemDescription?.length + "/1000"}
                        </Typography>
                      </Box>

                      <TextField
                        id="standard-basic"
                        rows={4}
                        maxRows={20}
                        multiline
                        type="text"
                        name="services_desc"
                        sx={{ width: "100%" }}
                        value={itemDescription}
                        onChange={(e) =>
                          handleChangeItemDescription(e.target.value)
                        }
                        placeholder={t("Enter_Item_description")}
                        error={descriptionError}
                        helperText={descriptionError && t("descriptionError")}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.girdSpacing}
                    >
                      <Typography className={classes.fieldTextService}>
                        {t("Dietary_Restrictions")}
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={3} md={3} sm={3} lg={4}>
                          <Box className={classes.checkboxStyle}>
                            <Checkbox
                              sx={{ marginTop: "4px" }}
                              checked={vegetarianChecked}
                              onChange={handleVegetarianChecked}
                              name="google"
                              color="primary"
                            />

                            <Typography className={classes.typoStyle}>
                              {t("vegetarian")}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} md={3} sm={3} lg={4}>
                          <Box className={classes.checkboxStyle}>
                            <Checkbox
                              sx={{ marginTop: "4px" }}
                              checked={veganChecked}
                              onChange={handleVeganChecked}
                              name="google"
                              color="primary"
                            />

                            <Typography className={classes.typoStyle}>
                              {t("vegan")}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.girdSpacing}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Typography className={classes.fieldTextService}>
                          {t("upload_imageItem")}
                        </Typography>
                        <BootstrapTooltip title={t("imageValidation")}>
                          <ErrorOutlineIcon
                            fontSize="small"
                            sx={{ marginLeft: "3px" }}
                          />
                        </BootstrapTooltip>
                      </Box>

                      <Box mt={2}>
                        {itemImage == null ? (
                          <CommonButton
                            customHeight={true}
                            leftMargin={true}
                            onSubmit={handleOpenImageUploadModal}
                            label={t("Browse_File")}
                          />
                        ) : (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={1.5}
                            lg={1.5}
                            style={{ marginTop: "10px", height: "100px" }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <Avatar
                                variant="square"
                                className={classes.imageAvatar}
                                onClick={() => {
                                  handleOpenPreviewImage(
                                    uploadedImages?.length > 0
                                      ? uploadedImages
                                      : [itemImage],
                                    true,
                                    0
                                  );
                                }}
                              >
                                <LazyLoadImage
                                  loading="lazy"
                                  src={loadImg ? noImageGray : itemImage}
                                  beforeLoad={handleBeforeLOad}
                                  afterLoad={handleAfterLOad}
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                  }}
                                  PlaceholderSrc={noImageGray}
                                  alt="Image Alt"
                                  effect="blur"
                                />
                              </Avatar>
                              <IconButton
                                className={classes.imageCrossIcon2}
                                onClick={() => handleRemoveFile()}
                              >
                                <CloseOutlinedIcon
                                  fontSize="small"
                                  sx={{ color: "#FFFF" }}
                                />
                              </IconButton>
                            </div>
                          </Grid>
                        )}
                      </Box>
                    </Grid>

                    {/*   <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.girdSpacing}
                >
                  <Typography className={classes.fieldTextService}>
                    {t("Product_URL")}
                  </Typography>
                  <TextField
                    id="standard-basic"
                    type="text"
                    name="services_desc"
                    sx={{ width: "100%" }}
                    value={itemUrl}
                    onChange={(e) => handleChangeProductUrl(e.target.value)}
                    placeholder={t("Enter_Product_URL")}
                  />
                </Grid> */}
                  </Grid>
                </Grid>
              </>
            )}{" "}
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
              marginRight: "7px",
            }}
          >
            <CommonButton
              displayWhite="true"
              onSubmit={handleCloseProductSectionModel}
              label={t("Cancel")}
            />

            <CommonButton
              type="submit"
              label={t("Save")}
              //  isLoading={serviceUpdateLoading}
              onSubmit={handleUpdateSectionItemData}
              disabled={
                itemName === "" ||
                itemName === null ||
                isLoading ||
                priceRequired ||
                currencyRequired ||
                (openFrom === "section" && sectionName === "") ||
                sectionError ||
                itemError ||
                duplicateSection ||
                duplicateItem ||
                descriptionError ||
                inValidPrice
              }
            />
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default AddProductSection;
