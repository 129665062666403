import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Divider as MuiDivider,
  MenuItem,
  Menu,
  Button,
  TextField,
  Dialog,
  Chip,
  Card,
  Divider,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import noImageGray from "../../../../../../../../assets/images/noImageGray.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../../Styles/style";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CollapsibleForm from "../../../../../../../../components/CustomComponents/Collapsible";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { toast } from "react-toastify";
import { api } from "../../../../../../../../contexts/JWTContext";
import Delete from "../../../../../../../../components/Models/DeleteModal/Delete";
import Truncate from "react-truncate";
import OtherSectionModel from "./OtherSectionModel";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../../../../../../../components/Loaders/Loader";

const LocationProductsSection = ({
  locationProducts,
  handleOpenProductSectionModel,
  setSelectedItemData,
  setSelectedSectionData,
  setTouchedValue,
  getLocationDetails,
  location,
  isLoading,
  setIsLoading,
  locationDetailsData,
  getAllMenus,
  search,
  setCallApi,
  selectedSectionData,
  getLocationDetailsListing,
  containerRef,
  openState,
  setOpenState,
  selectedItemData,
  setLocationProducts,
  setLoading,
  loading,
  setSearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElTwo, setAnchorElTwo] = React.useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [showEdit, setShowEdit] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [loadingSectionName, setLoadingSectionName] = useState(false);
  const [openItemDeleteModel, setOpenItemDeleteModel] = useState(false);
  const [openSectionDeleteModel, setOpenSectionDeleteModel] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [openOtherSectionModel, setOpenOtherSectionModel] = useState(false);
  const [sectionError, setSectionError] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    setLocationProducts(tempData);
    setSectionError(false);
  };
  const handleClickSectionMenu = (event, section, ind) => {
    setAnchorElTwo(event.currentTarget);
    setSelectedSectionData(section);
    setSelectedIndex(ind);
    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    setLocationProducts(tempData);
    setSectionError(false);
  };
  const handleCloseSectionMenu = () => {
    setAnchorElTwo(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowEdit = (indexValue, sectionData) => {
    setSectionName(
      sectionData !== null && sectionData?.labels?.length > 0
        ? sectionData?.labels[0]?.displayName
        : ""
    );
    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    tempData[0].sections[indexValue]["edit"] = true;
    setLocationProducts(tempData);
    setSectionError(false);

    setShowEdit(true);
  };

  const handleChangeSectionName = (e) => {
    setSectionName(e.target.value);
    if (e.target.value.length > 140) {
      setSectionError(true);
    } else {
      setSectionError(false);
    }
  };
  const handleCancelButton = (indexValue) => {
    setShowEdit(false);
    let tempData = locationProducts;
    delete tempData[0].sections[indexValue]["edit"];
    setLocationProducts(tempData);

    setSectionName("");
    setSectionError(false);
  };

  const handleDataToUpdate = (data) => {
    if (data?.labels?.length > 0) {
      data.labels[0].displayName = sectionName;
      delete data["edit"];
    }

    let menuData = locationDetailsData?.result?.menus;

    let sectionIndex = menuData[0]?.sections?.findIndex(
      (item) =>
        item?.labels[0]?.displayName ===
        selectedSectionData?.labels[0]?.displayName
    );

    if (sectionIndex > -1) {
      menuData[0].sections[sectionIndex] = data;
    }

    return menuData;
  };
  const handleSaveSectionName = async () => {
    setLoadingSectionName(true);
    let dummyObj = {};

    let objjjj = JSON.stringify(selectedSectionData);

    dummyObj = JSON.parse(objjjj);
    let menuData = handleDataToUpdate(dummyObj);

    try {
      setLoadingSectionName(true);

      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
          locationId: location?.id,
        }
      );

      if (res.status === 200) {
        setLoadingSectionName(false);
        setShowEdit(false);
        setSectionName("");
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Updated_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const handleDeleteItem = async () => {
    setDeleteLoading(true);
    let dummyObj = {};
    let objjjj = JSON.stringify(selectedSectionData);
    dummyObj = JSON.parse(objjjj);
    let sectionItems = [...dummyObj?.items];
    sectionItems = sectionItems?.filter(
      (item) =>
        item?.labels[0]?.displayName !==
        selectedItemData?.labels[0]?.displayName
    );
    dummyObj.items = sectionItems;
    let menuData = locationDetailsData?.result?.menus;
    let sectionIndex = menuData[0]?.sections?.findIndex(
      (item) =>
        item?.labels[0]?.displayName ===
        selectedSectionData?.labels[0]?.displayName
    );
    if (sectionIndex > -1) {
      if (dummyObj?.items?.length > 0) {
        menuData[0].sections[sectionIndex] = dummyObj;
      } else {
        let sections = menuData[0].sections;
        sections = sections?.filter(
          (item) =>
            item?.labels[0]?.displayName !== dummyObj?.labels[0]?.displayName
        );
        menuData[0].sections = sections;
      }
    }

    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        setDeleteLoading(false);
        handleCloseItemDeleteModel();
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Item_Deleted_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDeleteLoading(false);
    }
  };
  const handleDeleteSection = async () => {
    setDeleteLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    let sections = menuData[0].sections;
    sections = sections?.filter(
      (item) =>
        item?.labels[0]?.displayName !==
        selectedSectionData?.labels[0]?.displayName
    );
    menuData[0].sections = sections;
    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        setDeleteLoading(false);
        handleCloseSectionDeleteModel();
        getAllMenus();
        getLocationDetailsListing(location);

        toast.success(`${t("Section_Deleted_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDeleteLoading(false);
    }
  };
  const handleOpenItemDeleteModel = () => {
    setOpenItemDeleteModel(true);
  };
  const handleCloseItemDeleteModel = () => {
    setOpenItemDeleteModel(false);
  };
  const handleOpenSectionDeleteModel = () => {
    setOpenSectionDeleteModel(true);
  };
  const handleCloseSectionDeleteModel = () => {
    setOpenSectionDeleteModel(false);
  };
  const handleOpenOtherSectionModel = () => {
    setOpenOtherSectionModel(true);
  };
  const handleCloseOtherSectionModel = () => {
    setOpenOtherSectionModel(false);
  };

  const moveItemOneIndexUp = (array, index) => {
    if (index > 0 && index < array.length) {
      [array[index - 1], array[index]] = [array[index], array[index - 1]];
    }
    return array;
  };
  const moveItemOneIndexDown = (array, index) => {
    if (index >= 0 && index < array.length - 1) {
      [array[index], array[index + 1]] = [array[index + 1], array[index]];
    }
    return array;
  };
  const moveItemToStart = (array, index) => {
    if (index > 0 && index < array.length) {
      const [item] = array.splice(index, 1);
      array.unshift(item);
    }
    return array;
  };
  const handleMoveSectionToTop = async () => {
    setLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    const updatedArray = moveItemToStart(menuData[0]?.sections, selectedIndex);
    if (menuData?.length > 0) {
      delete menuData[0].section;
      menuData[0].sections = updatedArray;
    }
    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        getAllMenus();
        getLocationDetailsListing(location);

        toast.success(`${t("Section_Moved_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleMoveSectionDown = async () => {
    setLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    const updatedArray = moveItemOneIndexDown(
      menuData?.length > 0 && menuData[0]?.sections,
      selectedIndex
    );

    if (menuData?.length > 0) {
      menuData[0].sections = updatedArray;
    }
    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        getAllMenus();
        getLocationDetailsListing(location);

        toast.success(`${t("Section_Moved_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleMoveSectionUp = async () => {
    setLoading(true);

    let menuData = locationDetailsData?.result?.menus;
    const updatedArray = moveItemOneIndexUp(
      menuData?.length > 0 && menuData[0]?.sections,
      selectedIndex
    );

    if (menuData?.length > 0) {
      menuData[0].sections = updatedArray;
      delete menuData.section;
    }

    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Section_Moved_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleMoveItemToTop = async () => {
    setLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    const updatedArray = moveItemToStart(
      menuData[0]?.sections[selectedIndex].items,
      selectedItemIndex
    );

    if (menuData?.length > 0) {
      menuData[0].sections[selectedIndex].items = updatedArray;
    }
    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        getAllMenus();
        getLocationDetailsListing(location);

        toast.success(`${t("Item_Moved_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleMoveItemDown = async () => {
    setLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    const updatedArray = moveItemOneIndexDown(
      menuData[0]?.sections[selectedIndex].items,
      selectedItemIndex
    );
    if (menuData?.length > 0) {
      menuData[0].sections[selectedIndex].items = updatedArray;
    }

    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Item_Moved_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  const handleMoveItemUp = async () => {
    setLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    const updatedArray = moveItemOneIndexUp(
      menuData[0]?.sections[selectedIndex].items,
      selectedItemIndex
    );
    menuData[0].sections[selectedIndex].items = updatedArray;

    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Item_Moved_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleCheckForImage = (selectedItemValue) => {
    if (locationDetailsData !== null)
      if (locationDetailsData?.result?.google?.media?.length > 0) {
        if (
          selectedItemValue?.attributes?.mediaKeys?.length > 0 &&
          selectedItemValue?.attributes?.mediaKeys[0]?.includes("https") ===
            false
        ) {
          const selectedImageItem =
            locationDetailsData?.result?.google?.media?.find(
              (item) =>
                item?.name?.includes(
                  selectedItemValue?.attributes?.mediaKeys?.length > 0
                    ? selectedItemValue?.attributes?.mediaKeys[0]
                    : ""
                ) /* &&
                (item?.category === "FOOD_AND_DRINK" ||
                  item?.category === "FOOD_AND_MENU") */
            );
          console.log(selectedImageItem, "selectedImageItem");
          return selectedImageItem;
        } else {
          return selectedItemValue?.attributes?.mediaKeys?.length > 0
            ? selectedItemValue?.attributes?.mediaKeys[0]
            : null;
        }
      }
  };
  console.log(locationProducts, "locationProducts");
  const handleSearch = (e) => {
    setSearch(e);
    setCallApi(true);
  };

  return (
    <>
      {openOtherSectionModel && (
        <OtherSectionModel
          title={t("move_menu_item")}
          open={openOtherSectionModel}
          handleCloseProductSectionModel={handleCloseOtherSectionModel}
          locationProducts={locationProducts}
          selectedSectionData={selectedSectionData}
          selectedItemData={selectedItemData}
          locationDetailsData={locationDetailsData}
          getAllMenus={getAllMenus}
          location={location}
          getLocationDetailsListing={getLocationDetailsListing}
        />
      )}
      {openSectionDeleteModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openSectionDeleteModel}
          onClose={handleCloseSectionDeleteModel}
        >
          <Delete
            title={t("Delete_section")}
            description={`${
              selectedSectionData?.labels?.length > 0 &&
              selectedSectionData?.labels[0]?.displayName
            } ${t("Delete_sectionDes")}`}
            onConfirm={handleDeleteSection}
            onCancel={handleCloseSectionDeleteModel}
            loading={deleteLoading}
          />
        </Dialog>
      )}
      {openItemDeleteModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openItemDeleteModel}
          onClose={handleCloseItemDeleteModel}
        >
          <Delete
            title={t("Delete_section_item")}
            description={`${t("Delete_sectionDes_Item")} ${
              selectedItemData?.labels?.length > 0 &&
              selectedItemData?.labels[0]?.displayName
            }?`}
            onConfirm={handleDeleteItem}
            onCancel={handleCloseItemDeleteModel}
            loading={deleteLoading}
          />
        </Dialog>
      )}
      {locationDetailsData !== null &&
        locationDetailsData?.result?.menus !== null &&
        locationDetailsData?.result?.menus?.length > 0 &&
        locationDetailsData?.result?.menus[0]?.sections?.length > 0 && (
          <Grid container className={classes.searchGrid}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
              mb={companyData?.menuEnabled === false ? 4 : 2}
            >
              <TextField
                placeholder={t("search_section")}
                sx={{ borderRadius: "800px", width: "97.8%" }}
                className={classes.root}
                autoComplete="off"
                //  id="outlined-name"
                value={search}
                size="small"
                InputProps={{
                  className: classes.input,

                  startAdornment: (
                    <IconButton>
                      <SearchOutlinedIcon />
                    </IconButton>
                  ),
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Grid>
          </Grid>
        )}
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "460px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          {" "}
          {search === "" && companyData?.menuEnabled === true && (
            <Grid container className={classes.searchGrid}>
              {" "}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.addButtonGrid}
              >
                <Button
                  onClick={() => {
                    handleOpenProductSectionModel("section");
                  }}
                  className={classes.addButtonSection}
                >
                  <AddIcon
                    fontSize="small"
                    className={classes.addIconSection}
                  />{" "}
                  <Typography sx={{ fontWeight: 700 }}>
                    {t("product_section")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid container className={classes.menusGrid} ref={containerRef}>
            <Box sx={{ width: "100%" }}>
              {locationProducts?.length > 0
                ? locationProducts?.map((item) =>
                    item?.sections?.map((section, ind) => {
                      const editMode = section?.hasOwnProperty("edit");
                      return (
                        <Grid container className={classes.productSectionGrid}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card
                              variant="outlined"
                              sx={{
                                bgcolor: "#ffff",
                                borderRadius: "8px",
                                // height: "auto",
                              }}
                            >
                              {editMode == true ? (
                                <Box className={classes.boxContainerDescEdit}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {" "}
                                        <Typography
                                          className={classes.sectionNameHeading}
                                        >
                                          {t("section_name")}{" "}
                                          <span style={{ color: "#FC3652" }}>
                                            *
                                          </span>
                                        </Typography>
                                        <Typography
                                          className={classes.characterLimitTypo}
                                        >
                                          {sectionName?.length + "/140"}
                                        </Typography>
                                      </Box>

                                      <TextField
                                        placeholder={t("enter_section_name")}
                                        sx={{
                                          borderRadius: "800px",
                                          width: "100%",
                                        }}
                                        fullWidth
                                        className={classes.root}
                                        autoComplete="off"
                                        error={sectionError}
                                        value={sectionName}
                                        size="small"
                                        onChange={handleChangeSectionName}
                                        helperText={
                                          sectionError && t("sectionError")
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      <CommonButton
                                        displayWhite="true"
                                        label={t("Close")}
                                        customHeight={true}
                                        onSubmit={() => handleCancelButton(ind)}
                                      />
                                      <CommonButton
                                        label={t("Save")}
                                        disabled={
                                          sectionName === "" ||
                                          loadingSectionName ||
                                          sectionError
                                        }
                                        isLoading={loadingSectionName}
                                        customHeight={true}
                                        onSubmit={handleSaveSectionName}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              ) : (
                                <Box className={classes.boxContainerDesc}>
                                  <Typography
                                    onClick={() => {
                                      if (
                                        editMode == false &&
                                        companyData?.menuEnabled === true
                                      ) {
                                        setSelectedSectionData(section);
                                        handleShowEdit(ind, section);
                                      }
                                    }}
                                    className={classes.sectionNameStyle}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center", // Align the text and icon vertically
                                      position: "relative", // Ensure the parent is positioned relatively
                                    }}
                                  >
                                    {section?.labels?.length > 0
                                      ? section?.labels[0]?.displayName
                                      : ""}

                                    {/* Icon Box */}
                                    {editMode == false &&
                                      companyData?.menuEnabled === true && (
                                        <Box
                                          className="hover-icon"
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: 1, // Add some space between text and icon
                                            opacity: 0,
                                            transition: "opacity 0.3s",
                                            "&:hover": {
                                              opacity: 1, // Show icon on hover
                                            },
                                          }}
                                        >
                                          <IconButton
                                            sx={{
                                              "&:hover": {
                                                backgroundColor: "#F5F5F5",
                                              },
                                            }}
                                          >
                                            <EditIcon fontSize="small" />
                                          </IconButton>
                                        </Box>
                                      )}
                                  </Typography>
                                  {companyData?.menuEnabled === true && (
                                    <IconButton
                                      onClick={(e) => {
                                        handleClickSectionMenu(e, section, ind);
                                      }}
                                      disabled={editMode == true}
                                      sx={{
                                        padding: "0px",
                                        "&:hover": {
                                          backgroundColor: "#F5F5F5",
                                        },
                                      }}
                                      // disabled={companyData?.menuEnabled === false}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                  <Menu
                                    anchorEl={anchorElTwo}
                                    open={Boolean(anchorElTwo)}
                                    onClose={handleCloseSectionMenu}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        handleOpenSectionDeleteModel();
                                        handleCloseSectionMenu();
                                      }}
                                    >
                                      <Typography>{t("Delete")}</Typography>
                                    </MenuItem>

                                    {selectedIndex !== 0 && (
                                      <>
                                        <Divider />
                                        <MenuItem
                                          onClick={(e) => {
                                            handleCloseSectionMenu();
                                            handleMoveSectionUp();
                                          }}
                                        >
                                          <Typography>
                                            {t("move_up")}
                                          </Typography>
                                        </MenuItem>
                                      </>
                                    )}

                                    {selectedIndex !==
                                      locationProducts[0]?.sections?.length -
                                        1 && (
                                      <>
                                        <Divider />
                                        <MenuItem
                                          onClick={(e) => {
                                            handleCloseSectionMenu();
                                            handleMoveSectionDown();
                                          }}
                                        >
                                          <Typography>
                                            {t("move_down")}
                                          </Typography>
                                        </MenuItem>
                                      </>
                                    )}

                                    {selectedIndex !== 0 && (
                                      <>
                                        <Divider />
                                        <MenuItem
                                          onClick={(e) => {
                                            handleCloseSectionMenu();
                                            handleMoveSectionToTop();
                                          }}
                                        >
                                          <Typography>
                                            {t("move_to_top_menu")}
                                          </Typography>
                                        </MenuItem>
                                      </>
                                    )}
                                  </Menu>
                                </Box>
                              )}

                              {/* Correcting section?.items?.map */}
                              {section?.items?.length > 0 &&
                                section?.items.map(
                                  (
                                    singleItem,
                                    singleItemIndex // Corrected here: section?.items.map
                                  ) => {
                                    const selectedImageData =
                                      handleCheckForImage(singleItem);

                                    return (
                                      <Grid
                                        container
                                        className={
                                          editMode
                                            ? classes.productItemGridDisabled
                                            : classes.productItemGrid
                                        }
                                        key={singleItem.id}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                        >
                                          <Box
                                            className={
                                              editMode
                                                ? classes.boxContainerDescDisabled
                                                : classes.boxContainerDesc
                                            }
                                          >
                                            {" "}
                                            <Box
                                              onClick={() => {
                                                if (
                                                  editMode == false &&
                                                  companyData?.menuEnabled ===
                                                    true
                                                ) {
                                                  setSelectedSectionData(
                                                    section
                                                  );
                                                  setSelectedItemData(
                                                    singleItem
                                                  );
                                                  handleOpenProductSectionModel(
                                                    "edit"
                                                  );
                                                }
                                              }}
                                              sx={{ width: "100%" }}
                                            >
                                              <Typography
                                                className={
                                                  editMode
                                                    ? classes.sectionItemNameStyleDisabled
                                                    : classes.sectionItemNameStyle
                                                }
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center", // Align the text and icon vertically
                                                  position: "relative", // Ensure the parent is positioned relatively
                                                }}
                                              >
                                                {singleItem?.labels?.length > 0
                                                  ? singleItem?.labels[0]
                                                      ?.displayName
                                                  : ""}
                                                {editMode == false &&
                                                  companyData?.menuEnabled ===
                                                    true && (
                                                    <Box
                                                      className="hover-iconTwo"
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginLeft: 1, // Add some space between text and icon
                                                        opacity: 0,
                                                        transition:
                                                          "opacity 0.3s",
                                                        "&:hover": {
                                                          opacity: 1, // Show icon on hover
                                                        },
                                                      }}
                                                    >
                                                      <IconButton
                                                        sx={{
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "#F5F5F5",
                                                          },
                                                        }}
                                                      >
                                                        <EditIcon fontSize="small" />
                                                      </IconButton>
                                                    </Box>
                                                  )}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    width: "100%",
                                                  }}
                                                >
                                                  {singleItem?.labels?.length >
                                                    0 &&
                                                    singleItem?.labels[0]?.hasOwnProperty(
                                                      "description"
                                                    ) && (
                                                      <Box
                                                        sx={{
                                                          width: "95%",
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <Truncate
                                                          lines={2}
                                                          ellipsis={
                                                            <span>...</span>
                                                          }
                                                          style={{
                                                            fontSize: "12px",
                                                            fontWeight: 400,
                                                            lineHeight:
                                                              "14.7px",
                                                            color: editMode
                                                              ? "#B0B0B0"
                                                              : "#1B2430",

                                                            marginBottom: "5px",
                                                            width: "92%",
                                                          }}
                                                        >
                                                          {" "}
                                                          <Typography
                                                            className={
                                                              editMode === true
                                                                ? classes.sectionItemDesStyleDisabled
                                                                : classes.sectionItemDesStyle
                                                            }
                                                          >
                                                            {
                                                              singleItem
                                                                ?.labels[0]
                                                                ?.description
                                                            }
                                                          </Typography>
                                                        </Truncate>
                                                      </Box>
                                                    )}
                                                  <Box sx={{ display: "flex" }}>
                                                    {singleItem?.attributes &&
                                                      Object.keys(
                                                        singleItem.attributes
                                                      ).length > 0 &&
                                                      singleItem.attributes?.hasOwnProperty(
                                                        "price"
                                                      ) &&
                                                      ((singleItem?.attributes
                                                        ?.price?.units !==
                                                        null &&
                                                        singleItem?.attributes
                                                          ?.price?.units !==
                                                          "") ||
                                                        singleItem?.attributes
                                                          ?.price?.nanos !==
                                                          "") && (
                                                        <Typography
                                                          className={
                                                            editMode
                                                              ? classes.sectionItemPriceStyleDisabled
                                                              : classes.sectionItemPriceStyle
                                                          }
                                                        >
                                                          {`${
                                                            singleItem?.attributes?.price?.hasOwnProperty(
                                                              "units"
                                                            )
                                                              ? singleItem
                                                                  ?.attributes
                                                                  ?.price?.units
                                                              : "0"
                                                          }${
                                                            singleItem?.attributes?.price?.hasOwnProperty(
                                                              "nanos"
                                                            ) &&
                                                            singleItem
                                                              ?.attributes
                                                              ?.price?.nanos !==
                                                              null
                                                              ? "." +
                                                                singleItem?.attributes?.price?.nanos
                                                                  .toString()
                                                                  .slice(0, 2)
                                                              : ""
                                                          } ${
                                                            singleItem
                                                              ?.attributes
                                                              ?.price
                                                              ?.currencyCode !==
                                                            null
                                                              ? singleItem
                                                                  ?.attributes
                                                                  ?.price
                                                                  ?.currencyCode
                                                              : ""
                                                          }`}
                                                        </Typography>
                                                      )}

                                                    {singleItem?.attributes &&
                                                      Object.keys(
                                                        singleItem.attributes
                                                      ).length > 0 &&
                                                      singleItem?.attributes?.hasOwnProperty(
                                                        "dietaryRestriction"
                                                      ) && (
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          {singleItem?.attributes?.hasOwnProperty(
                                                            "price"
                                                          ) === true &&
                                                            singleItem
                                                              ?.attributes
                                                              ?.price?.units !==
                                                              null &&
                                                            singleItem
                                                              ?.attributes
                                                              ?.price?.units !==
                                                              "" && (
                                                              <Box
                                                                className={
                                                                  classes.dotStyle
                                                                }
                                                              ></Box>
                                                            )}
                                                          <Chip
                                                            style={{
                                                              background:
                                                                editMode ===
                                                                  false &&
                                                                "#E3F9F2",
                                                              //   border: "1px solid #BBBDC1",
                                                              borderRadius:
                                                                "800px",
                                                              cursor: "pointer",
                                                              fontWeight: 600,
                                                              width: "auto",
                                                              marginTop: "3px",
                                                              marginLeft:
                                                                singleItem?.attributes?.hasOwnProperty(
                                                                  "price"
                                                                ) === true &&
                                                                singleItem
                                                                  ?.attributes
                                                                  ?.price
                                                                  ?.units !==
                                                                  null &&
                                                                singleItem
                                                                  ?.attributes
                                                                  ?.price
                                                                  ?.units !== ""
                                                                  ? "8px"
                                                                  : "0px",
                                                              height: "25px",
                                                              fontSize: "10px",
                                                              color: editMode
                                                                ? "#808080"
                                                                : "#1B2430",
                                                            }}
                                                            label={
                                                              singleItem
                                                                ?.attributes
                                                                ?.dietaryRestriction
                                                                ?.length > 0 &&
                                                              singleItem?.attributes?.dietaryRestriction?.includes(
                                                                "VEGETARIAN"
                                                              )
                                                                ? "Vegetarian"
                                                                : singleItem
                                                                    ?.attributes
                                                                    ?.dietaryRestriction
                                                                    ?.length >
                                                                    0 &&
                                                                  singleItem?.attributes?.dietaryRestriction?.includes(
                                                                    "VEGAN"
                                                                  )
                                                                ? "Vegan"
                                                                : ""
                                                            }
                                                          />
                                                        </Box>
                                                      )}
                                                  </Box>
                                                </Box>

                                                {selectedImageData !== null &&
                                                  selectedImageData !==
                                                    undefined && (
                                                    <Avatar
                                                      variant="square"
                                                      className={
                                                        classes.imageAvatarDisplay
                                                      }
                                                    >
                                                      <LazyLoadImage
                                                        loading="lazy"
                                                        src={
                                                          selectedImageData?.sourceUrl
                                                            ? selectedImageData?.sourceUrl
                                                            : selectedImageData
                                                        }
                                                        style={{
                                                          height: "60px",
                                                          width: "60px",
                                                          opacity: editMode
                                                            ? 0.5
                                                            : 1, // If editMode is true, make it semi-transparent
                                                          filter: editMode
                                                            ? "grayscale(100%)"
                                                            : "none", // Apply grayscale when editMode is true
                                                          pointerEvents:
                                                            editMode
                                                              ? "none"
                                                              : "auto", // Disable interaction when editMode is true
                                                        }}
                                                        alt="Image Alt"
                                                        effect="blur"
                                                      />
                                                    </Avatar>
                                                  )}
                                              </Box>
                                            </Box>
                                            {companyData?.menuEnabled ===
                                              true && (
                                              <IconButton
                                                onClick={(e) => {
                                                  setSelectedSectionData(
                                                    section
                                                  );
                                                  setSelectedItemData(
                                                    singleItem
                                                  );
                                                  setSelectedItemIndex(
                                                    singleItemIndex
                                                  );
                                                  setSelectedIndex(ind);
                                                  handleClick(e);
                                                }}
                                                disabled={editMode == true}
                                                sx={{
                                                  padding: "0px",
                                                  alignItems: "start",
                                                  marginTop: "3px",
                                                  height: "30px",
                                                  "&:hover": {
                                                    backgroundColor: "#F5F5F5",
                                                  },
                                                }}
                                              >
                                                <MoreVertIcon fontSize="small" />
                                              </IconButton>
                                            )}
                                            <Menu
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={handleClose}
                                            >
                                              {/*   <MenuItem
                                        onClick={(e) => {
                                           e.stopPropagation();
                                          handleClose();
                                          handleOpenProductSectionModel("edit");
                                        }}
                                      >
                                        <Typography>{t("Edit")}</Typography>
                                      </MenuItem> */}
                                              <MenuItem
                                                onClick={(e) => {
                                                  handleClose();
                                                  handleOpenItemDeleteModel();
                                                  // setOpen();
                                                  // setDeletePost(item?.id);
                                                }}
                                              >
                                                <Typography>
                                                  {t("Delete")}
                                                </Typography>
                                              </MenuItem>

                                              {selectedItemIndex !== 0 && (
                                                <>
                                                  <Divider />
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      handleClose();
                                                      handleMoveItemUp();
                                                    }}
                                                  >
                                                    <Typography>
                                                      {t("move_up")}
                                                    </Typography>
                                                  </MenuItem>
                                                </>
                                              )}
                                              {selectedItemIndex !==
                                                selectedSectionData?.items
                                                  ?.length -
                                                  1 && (
                                                <>
                                                  <Divider />
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      handleClose();
                                                      handleMoveItemDown();
                                                    }}
                                                  >
                                                    <Typography>
                                                      {t("move_down")}
                                                    </Typography>
                                                  </MenuItem>
                                                </>
                                              )}
                                              {selectedItemIndex !== 0 && (
                                                <>
                                                  <Divider />
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      handleClose();
                                                      handleMoveItemToTop();
                                                    }}
                                                  >
                                                    <Typography>
                                                      {t("move_to_top")}
                                                    </Typography>
                                                  </MenuItem>
                                                </>
                                              )}

                                              {locationProducts?.length > 0 &&
                                                locationProducts[0]?.sections
                                                  ?.length > 1 && (
                                                  <>
                                                    {" "}
                                                    <Divider />
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClose();
                                                        handleOpenOtherSectionModel();
                                                      }}
                                                    >
                                                      <Typography>
                                                        {t(
                                                          "Move_to_other_section"
                                                        )}
                                                      </Typography>
                                                    </MenuItem>
                                                  </>
                                                )}
                                            </Menu>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}

                              {companyData?.menuEnabled === true && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  mt={2}
                                  mb={2}
                                  ml={2}
                                >
                                  <Button
                                    onClick={() => {
                                      setSelectedSectionData(section);
                                      handleOpenProductSectionModel(
                                        "add",
                                        section
                                      );
                                    }}
                                    className={classes.addButtonSection}
                                    disabled={editMode === true}
                                  >
                                    <AddIcon
                                      fontSize="small"
                                      className={
                                        editMode
                                          ? classes.addIconSectionDisabled
                                          : classes.addIconSection
                                      }
                                    />{" "}
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        color: editMode ? "#B0B0B0" : "#1B2430",
                                      }}
                                    >
                                      {t("add_product")}
                                    </Typography>
                                  </Button>
                                </Grid>
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                      );
                    })
                  )
                : (search !== "" || locationProducts?.length === 0) && (
                    <Grid
                      container
                      sx={{
                        height: "320px",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item sm={8} md={8} lg={8}>
                        <Typography
                          sx={{
                            lineHeight: "28px",
                            fontWeight: 700,
                            fontSize: "20px",
                            color: "#1b2430",
                            marginTop: "30px",
                            marginBottom: "15px",
                          }}
                        >
                          {t("No_Menu_found")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default LocationProductsSection;
