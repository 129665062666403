import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
  Radio,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../../../../contexts/JWTContext";
import Hours from "./hours";
import Loader from "../../../../../../../components/Loaders/Loader";
import { useStyles } from "../../../../Styles/style";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/system";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const CompanyOpeninghours = ({
  data,
  onCancel,
  locationDetailsData,
  location,
  getLocationDetails,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationDetailsListing,
  setIndexValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const containerRef = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState([]);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [filteredPeriodsArr, setFilteredPeriodsArr] = useState([]);
  const [timesArr, setTimesArr] = useState([]);
  const [openTimesError, setOpenTimesError] = useState(false);
  const [closeTimesError, setCloseTimesError] = useState(false);
  const [newTimeArr, setNewTimeArr] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  const [displayError, setDisaplyError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  /*   useEffect(() => {
    if (filteredPeriodsArr.length !==newTimeArr?.length) {
      setTouchedValue(true);
    }
  
  }, [filteredPeriodsArr]); */

  useEffect(() => {
    setStartDate(locationDetailsData?.result?.google?.regularHours?.periods);

    if (
      locationDetailsData?.result?.google?.openInfo?.status === "OPEN" &&
      locationDetailsData?.result?.google?.regularHours !== null
    ) {
      handlePeriodsData();
      setSelectedValue("OPEN1");
    } else if (
      locationDetailsData?.result?.google?.openInfo?.status === "OPEN" &&
      locationDetailsData?.result?.google.regularHours === null
    ) {
      setSelectedValue(locationDetailsData?.result?.google?.openInfo?.status);
    } else {
      setSelectedValue(locationDetailsData?.result?.google?.openInfo?.status);
    }
  }, []);

  /*  useEffect(() => {
    handleScroll();
  }, [selectedValue]); */

  const getLocationListingDetails = async (locationData) => {
    //  setTimesArr([]);
    // setLoadingLocation(true);
    try {
      const response = await api.get(`locationListing/${locationData.id}`);
      if (response.status === 200) {
        // setAutoSync(response?.data?.data?.result?.autoSync);
        //  setLoadingLocation(false);
        setLocationDetailsData(response?.data?.data);
      }
    } catch (error) {
      //  toast.error(error?.response?.data?.message);
      //  setLoadingLocation(false);
    }
  };

  const handleScroll = () => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTop = 0;
    }
  };

  const checkTime = (data) => {
    const filterTime = data
      .map((item) => item.mergedTimes)
      .filter((x) => x.length > 0)
      .map((e) => e[0])
      .map((item) => {
        return {
          openHours: item.openHours,
          closeHours: item.closedHours,
        };
      });
    return filterTime.every((item) => {
      const openHours = item.openHours || {};
      const closeHours = item.closeHours || {};

      const openHoursValid =
        openHours.hours !== null &&
        openHours.minutes !== null &&
        !isNaN(openHours.hours) &&
        !isNaN(openHours.minutes);
      const closeHoursValid =
        closeHours.hours !== null &&
        closeHours.minutes !== null &&
        !isNaN(closeHours.hours) &&
        !isNaN(closeHours.minutes);

      return openHoursValid && closeHoursValid;
    });
  };

  const handlePeriodsData = () => {
    setIsLoading(true);
    const finalFilteredData = [];
    const arr2 = [];
    let periodsData =
      locationDetailsData?.result?.google?.regularHours?.periods;
    periodsData?.forEach((itemOne) => {
      let openTimes = [];
      let closeTimes = [];
      let mergedTimes = [];
      periodsData?.forEach((itemtwo) => {
        if (
          itemOne?.openDay === itemtwo?.openDay &&
          itemOne?.closeDay === itemtwo?.closeDay
        ) {
          openTimes?.push(itemtwo.openTime);
          closeTimes.push(itemtwo.closeTime);
          const obj = {
            openHours: {
              hours: itemtwo.openTime?.hours ? itemtwo.openTime?.hours : 0,
              minutes: itemtwo.openTime?.minutes
                ? itemtwo.openTime?.minutes
                : 0,
            },
            closedHours: {
              hours: itemtwo.closeTime?.hours ? itemtwo.closeTime?.hours : 0,
              minutes: itemtwo.closeTime?.minutes
                ? itemtwo.closeTime?.minutes
                : 0,
            },

            openTimesValueMerged: "",
            closeTimesValueMerged: "",
          };
          mergedTimes.push(obj);
          /* itemOne.mergedOpen = [itemOne.openTime, itemtwo.openTime];
          itemOne.mergedClose = [itemOne.closeTime, itemtwo.closeTime]; */
        }
      });
      itemOne.mergedTimes = [...mergedTimes];
      /*   itemOne.mergedClose = [...closeTimes]; */
      finalFilteredData.push(itemOne);
      /*   timesArr.push(itemOne);
      setTimesArr([...timesArr]); */

      finalFilteredData.forEach((item) => {
        let isFound = false;
        timesArr.forEach((item2) => {
          if (item.openDay === item2.openDay) {
            isFound = true;
          }
        });
        if (!isFound) {
          timesArr.push(item);
          arr2.push(item);
        }
      });
      setTimesArr([...timesArr]);
    });

    const weekDays = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];
    let tempObj = {
      mergedTimes: [],
      closeDay: "",
      closeTime: {
        hours: 0,
        minutes: 0,
      },
      openDay: "",
      openTime: {
        hours: 0,
        minutes: 0,
      },
    };
    let finalPeriodsArr = [];
    const filteredData = weekDays.map((itemOne, index) => {
      let found = false;
      let data = "";
      if (arr2?.length > 0) {
        arr2?.forEach((itemTwo) => {
          if (itemTwo?.closeDay === itemOne) {
            found = true;
            data = itemTwo;
          }
        });
        if (found) {
          tempObj = {
            mergedTimes: data?.mergedTimes,
            closed: false,
            closeDay: data?.closeDay,
            closeTime: {
              hours: data?.closeTime?.hours ? data?.closeTime?.hours : 0,
              minutes: 0,
            },
            openDay: data?.openDay,
            openTime: {
              hours: data?.openTime?.hours ? data?.openTime?.hours : 0,
              minutes: data.openTime?.minutes ? data.openTime?.minutes : 0,
            },
          };
          setOpen(true);
        } else {
          tempObj = {
            mergedTimes: [],
            closed: true,
            closeDay: itemOne,
            closeTime: {
              hours: 0,
              minutes: 0,
            },
            openDay: itemOne,
            openTime: {
              hours: 0,
              minutes: 0,
            },
          };

          setOpen(false);
        }
      } else {
        timesArr?.forEach((itemTwo) => {
          if (itemTwo?.closeDay === itemOne) {
            found = true;
            data = itemTwo;
          }
        });
        if (found) {
          tempObj = {
            mergedTimes: data?.mergedTimes,
            closed: false,
            closeDay: data?.closeDay,
            closeTime: {
              hours: data?.closeTime?.hours ? data?.closeTime?.hours : 0,
              minutes: 0,
            },
            openDay: data?.openDay,
            openTime: {
              hours: data?.openTime?.hours ? data?.openTime?.hours : 0,
              minutes: data.openTime?.minutes ? data.openTime?.minutes : 0,
            },
          };
          setOpen(true);
        } else {
          tempObj = {
            mergedTimes: [],
            closed: true,
            closeDay: itemOne,
            closeTime: {
              hours: 0,
              minutes: 0,
            },
            openDay: itemOne,
            openTime: {
              hours: 0,
              minutes: 0,
            },
          };

          setOpen(false);
        }
      }

      finalPeriodsArr.push(tempObj);
    });

    setFilteredPeriodsArr([...finalPeriodsArr]);
    if (finalPeriodsArr?.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
        handleScroll();
      }, 500);
    }
  };
  const handleChangeOpen = (e, periods, ind) => {
    setTouchedValue(true);
    const date = new Date();
    if (e.target.checked) {
      if (ind > 0) {
        const aboveData = filteredPeriodsArr[ind - 1];

        if (aboveData !== undefined && aboveData?.closed === false) {
          if (aboveData?.mergedTimes?.length > 0) {
            const mergedTimesData =
              aboveData?.mergedTimes[aboveData?.mergedTimes?.length - 1];
            let tempObj = {
              closed: false,
              mergedTimes: [
                {
                  openHours: {
                    hours: mergedTimesData?.openHours?.hours
                      ? mergedTimesData?.openHours?.hours
                      : 0,
                    minutes: mergedTimesData?.openHours?.minutes
                      ? mergedTimesData?.openHours?.minutes
                      : 0,
                  },
                  closedHours: {
                    hours: mergedTimesData?.closedHours?.hours
                      ? mergedTimesData?.closedHours?.hours
                      : 0,
                    minutes: mergedTimesData?.closedHours?.minutes
                      ? mergedTimesData?.closedHours?.minutes
                      : 0,
                  },
                  openTimesValueMerged: mergedTimesData?.openTimesValueMerged
                    ? mergedTimesData?.openTimesValueMerged
                    : date,
                  closeTimesValueMerged: mergedTimesData?.closeTimesValueMerged
                    ? mergedTimesData?.closeTimesValueMerged
                    : date,
                },
              ],
              closeDay: periods?.closeDay,
              closeTime: {
                hours: aboveData?.closeTime?.hours
                  ? aboveData?.closeTime?.hours
                  : 0,
                minutes: aboveData?.closeTime?.minutes
                  ? aboveData?.closeTime?.minutes
                  : 0,
              },
              openDay: periods?.openDay,
              openTime: {
                hours: aboveData?.openTime?.hours
                  ? aboveData?.openTime?.hours
                  : 0,
                minutes: aboveData?.openTime?.minutes
                  ? aboveData?.openTime?.minutes
                  : 0,
              },
            };
            filteredPeriodsArr[ind] = tempObj;
          }
        } else {
          let tempObj = {
            closed: false,
            mergedTimes: [
              {
                openHours: {
                  hours: 0,
                  minutes: 0,
                },
                closedHours: {
                  hours: 0,
                  minutes: 0,
                },
                openTimesValueMerged: date,
                closeTimesValueMerged: date,
              },
            ],
            closeDay: periods?.closeDay,
            closeTime: {
              hours: 0,
              minutes: 0,
            },
            openDay: periods?.openDay,
            openTime: {
              hours: 0,
              minutes: 0,
            },
          };
          filteredPeriodsArr[ind] = tempObj;
        }
      } else {
        let tempObj = {
          closed: false,
          mergedTimes: [
            {
              openHours: {
                hours: 0,
                minutes: 0,
              },
              closedHours: {
                hours: 0,
                minutes: 0,
              },
              openTimesValueMerged: date,
              closeTimesValueMerged: date,
            },
          ],
          closeDay: periods?.closeDay,
          closeTime: {
            hours: 0,
            minutes: 0,
          },
          openDay: periods?.openDay,
          openTime: {
            hours: 0,
            minutes: 0,
          },
        };
        filteredPeriodsArr[ind] = tempObj;
      }
      setDisaplyError(false);

      setOpen(true);
    } else {
      filteredPeriodsArr[ind].closed = true;
      setOpen(false);
    }
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  const handleAddHours = (periodsIndex, itemIndex) => {
    setTouchedValue(true);
    const date = new Date();
    if (filteredPeriodsArr[periodsIndex].mergedTimes?.length > 0) {
      const aboveData =
        filteredPeriodsArr[periodsIndex].mergedTimes[
          filteredPeriodsArr[periodsIndex].mergedTimes?.length - 1
        ];
      let obj = {
        openHours: {
          hours: aboveData?.openHours?.hours ? aboveData?.openHours?.hours : 0,
          minutes: aboveData?.openHours?.minutes
            ? aboveData?.openHours?.minutes
            : 0,
        },
        closedHours: {
          hours: aboveData?.closedHours?.hours
            ? aboveData?.closedHours?.hours
            : 0,
          minutes: aboveData?.closedHours?.minutes
            ? aboveData?.closedHours?.minutes
            : 0,
        },
        openTimesValueMerged: aboveData?.openTimesValueMerged
          ? aboveData?.openTimesValueMerged
          : date,
        closeTimesValueMerged: aboveData?.closeTimesValueMerged
          ? aboveData?.closeTimesValueMerged
          : date,
      };
      filteredPeriodsArr[periodsIndex].mergedTimes?.push(obj);
    } else {
      let obj = {
        openHours: {
          hours: 0,
          minutes: 0,
        },
        closedHours: {
          hours: 0,
          minutes: 0,
        },
        openTimesValueMerged: date,
        closeTimesValueMerged: date,
      };

      filteredPeriodsArr[periodsIndex].mergedTimes?.push(obj);
    }

    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  const handleRemoveHours = (periodsIndex, itemIndex) => {
    setTouchedValue(true);
    const filteredMergedArr = filteredPeriodsArr[
      periodsIndex
    ].mergedTimes.filter((el, i) => i !== itemIndex);
    filteredPeriodsArr[periodsIndex].mergedTimes = filteredMergedArr;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };

  const handleChangeOpenTime = (e, itemIndex, item, periodsIndex) => {
    setTouchedValue(true);

    const formattedDate = dayjs(e).format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );
    const obj = {
      openHours: {
        hours: dayjs(e).hour(),
        minutes: dayjs(e).minute(),
      },
      closedHours: {
        hours: item?.closedHours?.hours,
        minutes: item?.closedHours?.minutes,
      },
      openTimesValueMerged: formattedDate,
      closeTimesValueMerged: item?.closeTimesValueMerged,
    };
    filteredPeriodsArr[periodsIndex].mergedTimes[itemIndex] = obj;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  const handleChangeCloseTime = (e, itemIndex, item, periodsIndex) => {
    setTouchedValue(true);
    const formattedDate = dayjs(e).format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );

    const obj = {
      openHours: {
        hours: item?.openHours?.hours,
        minutes: item?.openHours?.minutes,
      },
      closedHours: {
        hours: dayjs(e).hour(),
        minutes: dayjs(e).minute(),
      },
      openTimesValueMerged: item?.openTimesValueMerged,
      closeTimesValueMerged: formattedDate,
    };
    filteredPeriodsArr[periodsIndex].mergedTimes[itemIndex] = obj;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };

  console.log(JSON.stringify(filteredPeriodsArr), "filteredPeriodsArr");
  const handleUpdateTimesArray = (data) => {
    const processedData = data.map((item) => {
      // Check if mergedTimes contains a "special" object
      const hasZeroToFullDay = item.mergedTimes.some(
        (obj) =>
          obj?.openHours?.hours === 0 &&
          obj?.openHours?.minutes === 0 &&
          (obj?.closedHours?.hours === 24 || obj?.closedHours?.hours === 0) &&
          obj?.closedHours?.minutes === 0
      );

      // If such an object exists, keep only that object in mergedTimes
      const filteredMergedTimes = hasZeroToFullDay
        ? item.mergedTimes.filter(
            (obj) =>
              obj?.openHours?.hours === 0 &&
              obj?.openHours?.minutes === 0 &&
              (obj?.closedHours?.hours === 24 ||
                obj?.closedHours?.hours === 0) &&
              obj?.closedHours?.minutes === 0
          )
        : item.mergedTimes;

      return {
        ...item,
        mergedTimes: filteredMergedTimes,
      };
    });
    setFilteredPeriodsArr(processedData);
  };
  const handleUpdateRegularHour = async () => {
    let filteredOpenArr = [...filteredPeriodsArr];

    let finalArr = [];
    const openedArr = filteredOpenArr.filter(
      (item, index) => item?.closed === false
    );
    openedArr?.map((item) => {
      if (item?.mergedTimes?.length > 0 && item?.mergedTimes?.length === 1) {
        let tempObj = {
          openDay: item?.openDay,
          openTime: {
            hours: item?.mergedTimes[0]?.openHours?.hours,
            minutes: item?.mergedTimes[0]?.openHours?.minutes
              ? item?.mergedTimes[0]?.openHours?.minutes
              : 0,
          },
          closeDay: item?.closeDay,
          closeTime: {
            hours: item?.mergedTimes[0]?.closedHours?.hours,
            minutes: item?.mergedTimes[0]?.closedHours?.minutes
              ? item?.mergedTimes[0]?.closedHours?.minutes
              : 0,
          },
        };
        finalArr.push(tempObj);
      } else {
        item?.mergedTimes
          ?.filter((itemTwo) => {
            // Check if the current object is the "special" one
            const isZeroToFullDay =
              itemTwo?.openHours?.hours === 0 &&
              itemTwo?.openHours?.minutes === 0 &&
              (itemTwo?.closedHours?.hours === 24 ||
                itemTwo?.closedHours?.hours === 0) &&
              itemTwo?.closedHours?.minutes === 0;

            // Check if there is any "special" object in mergedTimes
            const hasZeroToFullDay = item?.mergedTimes?.some(
              (obj) =>
                obj?.openHours?.hours === 0 &&
                obj?.openHours?.minutes === 0 &&
                (obj?.closedHours?.hours === 24 ||
                  obj?.closedHours?.hours === 0) &&
                obj?.closedHours?.minutes === 0
            );

            // If a "special" object exists, retain only it and ignore the others
            return hasZeroToFullDay ? isZeroToFullDay : true;
          })
          .map((itemTwo) => {
            let tempObjTwo = {
              openDay: item?.openDay,
              openTime: {
                hours: itemTwo?.openHours?.hours,
                minutes: itemTwo?.openHours?.minutes || 0,
              },
              closeDay: item?.closeDay,
              closeTime: {
                hours: itemTwo?.closedHours?.hours,
                minutes: itemTwo?.closedHours?.minutes || 0,
              },
            };
            finalArr.push(tempObjTwo);
          });
        /*  item?.mergedTimes?.map((itemTwo) => {
          let tempObjTwo = {
            openDay: item?.openDay,
            openTime: {
              hours: itemTwo?.openHours?.hours,
              minutes: itemTwo?.openHours?.minutes
                ? itemTwo?.openHours?.minutes
                : 0,
            },
            closeDay: item?.closeDay,
            closeTime: {
              hours: itemTwo?.closedHours?.hours,
              minutes: itemTwo?.closedHours?.minutes
                ? itemTwo?.closedHours?.minutes
                : 0,
            },
          };
          finalArr.push(tempObjTwo);
        }); */
      }
    });

    if (
      ((finalArr?.length > 0 && selectedValue === "OPEN1") ||
        finalArr?.length > 0 ||
        (finalArr?.length === 0 && selectedValue !== "OPEN1")) &&
      openTimesError === false &&
      closeTimesError === false
    ) {
      const bodyWithTempClosed = {
        openInfo: {
          status:
            selectedValue === "OPEN" || selectedValue === "OPEN1"
              ? "OPEN"
              : selectedValue,
        },
      };
      const bodyWithPermClosed = {
        openInfo: {
          status:
            selectedValue === "OPEN" || selectedValue === "OPEN1"
              ? "OPEN"
              : selectedValue,
        },
      };
      const bodyWithNoMainHours = {
        openInfo: {
          status:
            selectedValue === "OPEN" || selectedValue === "OPEN1"
              ? "OPEN"
              : selectedValue,
        },
        regularHours: null,
      };
      const bodyWithMainHours = {
        openInfo: {
          status:
            selectedValue === "OPEN" || selectedValue === "OPEN1"
              ? "OPEN"
              : selectedValue,
        },
        regularHours: {
          periods: finalArr,
        },
      };
      setDisaplyError(false);
      try {
        setIsLoading(true);
        setDisplayConfirmationBox(false);

        let res = {};

        if (selectedValue === "OPEN1") {
          res = await api.patch(
            `/locationListing/update-regularHours/${location?.id}`,
            bodyWithMainHours
          );
        } else if (selectedValue === "OPEN") {
          res = await api.patch(
            `/locationListing/update-regularHours/${location?.id}`,
            bodyWithNoMainHours
          );
        } else if (selectedValue === "CLOSED_TEMPORARILY") {
          res = await api.patch(
            `/locationListing/update-regularHours/${location?.id}`,
            bodyWithTempClosed
          );
        } else {
          res = await api.patch(
            `/locationListing/update-regularHours/${location?.id}`,
            bodyWithPermClosed
          );
        }
        if (res.status === 200) {
          setIsLoading(false);
          handleUpdateTimesArray(filteredPeriodsArr);
          toast.success("Updated Successfully");
          // onCancel();
          getLocationDetailsListing(location);
          // getLocationListingDetails(location);
          // setLocationDetailsData(res?.data?.data);

          if (indexValue !== null) {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          }
          if (cancelState === true) {
            onCancel();
          }
          setTouchedValue(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      if (selectedValue === "OPEN1" && finalArr?.length === 0) {
        setDisaplyError(true);
      } else {
        setDisaplyError(false);
      }
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleRadioChange = (event) => {
    setTouchedValue(true);
    setSelectedValue(event.target.value);
    if (event.target.value === "OPEN1") {
      handlePeriodsData();
    } else {
      // setFilteredPeriodsArr([]);
    }
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {displayConfirmationBox && (
          <Dialog
            // fullScreen={fullScreen}
            open={displayConfirmationBox}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <ConfirmModal
              title={t("Delete_Bulk_Posts")}
              description={t("Delete_post_subhead")}
              onConfirm={handleUpdateRegularHour}
              onClose={handleClose}
              onCancel={onCancel}
              indexValue={indexValue}
              setSelectedTab={setSelectedTab}
              cancelState={cancelState}
              touchedValue={touchedValue}
              setTouchedValue={setTouchedValue}
              getLocationDetails={getLocationDetails}
              location={location}

              //  loading={setIsLoading}
            />
          </Dialog>
        )}
        <Grid container sx={{ padding: "18px" }}>
          <Grid
            item
            xs={10}
            sm={10}
            md={11.5}
            lg={11.5}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.modalHeader}>
              {t("Hours_head")}
            </Typography>
            <BootstrapTooltip title={t("Hours_subhead")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        <Grid
          container
          ref={containerRef}
          className={classes.gridContainertwoBulk}
        >
          {isLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.loaderBoxBulk}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                sx={{
                  padding: "12px 18px 10px 18px",
                  marginTop: "10px",
                  height: "290px",
                }}
              >
                <Grid container className={classes.radioContainer}>
                  <Grid item md={0.5} sm={0.5} xs={0.5} lg={0.5}>
                    <Radio
                      checked={selectedValue === "OPEN1"}
                      onChange={handleRadioChange}
                      value="OPEN1"
                      sx={{ padding: "0px" }}
                    />
                  </Grid>
                  <Grid item md={11.5} sm={11.5} xs={11.5} lg={11.5}>
                    <Typography className={classes.radioLabel}>
                      {t("with_main_hours")}
                    </Typography>
                    <Typography className={classes.radioLabeSubheading}>
                      {t("with_main_hours_subHeading")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={classes.radioContainer}>
                  <Grid item md={0.5} sm={0.5} xs={0.5} lg={0.5}>
                    <Radio
                      checked={selectedValue === "OPEN"}
                      onChange={handleRadioChange}
                      value="OPEN"
                      sx={{ padding: "0px" }}
                    />
                  </Grid>
                  <Grid md={11.5} sm={11.5} xs={11.5} lg={11.5}>
                    <Typography className={classes.radioLabel}>
                      {t("with_no_main_hours")}
                    </Typography>
                    <Typography className={classes.radioLabeSubheading}>
                      {t("with_no_main_hours_subHeading")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={classes.radioContainer}>
                  <Grid item md={0.5} sm={0.5} xs={0.5} lg={0.5}>
                    <Radio
                      checked={selectedValue === "CLOSED_TEMPORARILY"}
                      onChange={handleRadioChange}
                      value="CLOSED_TEMPORARILY"
                      sx={{ padding: "0px" }}
                    />
                  </Grid>
                  <Grid md={11.5} sm={11.5} xs={11.5} lg={11.5}>
                    <Typography className={classes.radioLabel}>
                      {t("Temporarily_closed")}
                    </Typography>
                    <Typography className={classes.radioLabeSubheading}>
                      {t("Temporarily_closed_subheading")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={classes.radioContainer}>
                  <Grid item md={0.5} sm={0.5} xs={0.5} lg={0.5}>
                    <Radio
                      checked={selectedValue === "CLOSED_PERMANENTLY"}
                      onChange={handleRadioChange}
                      value="CLOSED_PERMANENTLY"
                      sx={{ padding: "0px" }}
                    />
                  </Grid>
                  <Grid md={11.5} sm={11.5} xs={11.5} lg={11.5}>
                    <Typography className={classes.radioLabel}>
                      {t("Permanently_closed")}
                    </Typography>
                    <Typography className={classes.radioLabeSubheading}>
                      {t("Permanently_closed_subheading")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ padding: "12px 18px 18px 18px" }}>
                {displayError && (
                  <Grid container className={classes.errorContainer}>
                    <Grid
                      item
                      xs={0.5}
                      sm={0.5}
                      md={0.5}
                      lg={0.5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorOutlineIcon className={classes.errorIcon} />
                    </Grid>
                    <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.errorText1}>
                          {t("Opening_Hourss_error")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {selectedValue === "OPEN1" && filteredPeriodsArr?.length > 0 && (
                  <>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                      <Typography className={classes.modalHeader}>
                        {t("Opening_Hourss")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      sx={{ paddingTop: "10px" }}
                    >
                      {filteredPeriodsArr &&
                        filteredPeriodsArr.map((periods, ind) => {
                          return (
                            <Grid
                              container
                              // mb={4}
                              key={ind}
                              // sx={{ marginBottom: "10px" }}
                            >
                              <Hours
                                index={ind}
                                periods={periods}
                                openTime={openTime}
                                closeTime={closeTime}
                                handleChangeCloseTime={handleChangeCloseTime}
                                handleChangeOpenTime={handleChangeOpenTime}
                                handleChangeOpen={handleChangeOpen}
                                open={open}
                                filteredPeriodsArr={filteredPeriodsArr}
                                setSelectedData={setSelectedData}
                                selectedData={selectedData}
                                getLocationDetails={getLocationDetails}
                                handleAddHours={handleAddHours}
                                handleRemoveHours={handleRemoveHours}
                                openTimesError={openTimesError}
                                setOpenTimesError={setOpenTimesError}
                                setCloseTimesError={setCloseTimesError}
                                displayError={displayError}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>

        <Divider />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            onSubmit={handleUpdateRegularHour}
            label={t("Save")}
            disabled={
              openTimesError ||
              closeTimesError ||
              !checkTime(filteredPeriodsArr)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyOpeninghours;
