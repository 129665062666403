import React, { useState, useEffect } from "react";
import TitleHeader from "../../../components/CustomComponents/title";
import { useTranslation } from "react-i18next";
import NotFound from "../../../components/NotFound/NotFound";
import { Typography, Grid, TextField, Autocomplete, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import { toast } from "react-toastify";
import Loader from "../../../components/Loaders/Loader";
import Container from "../../../components/ReviewSource";
import { useStyles } from "./styles/style";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import LocationNotFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import { checkLocalStorageData } from "../../../utils/LocalStorageHelpers";

const Index = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isDataValid = checkLocalStorageData();
  const { signOut, permissionsAcess } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState();
  const [mostlyUsedSource, setMostlyUsedSource] = useState([]);
  const [otherSource, setOtherSource] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [color, setColor] = React.useState(0);
  const [reviewData, setReviewData] = useState({});
  const [viewPermission, setViewPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  // const [editPermission, setEditPermission] = useState(false);
  // const [deletePermission, setDeletePermission] = useState(false);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        /*   if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        } */
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isDataValid) {
      getAllLocations();
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);

  useEffect(() => {
    if (locationId) {
      getAllThirdPartySource();
    }
  }, [locationId]);
  useEffect(() => {
    if (locationId) {
      getReviews();
    }
  }, [locationId]);
  useEffect(() => {
    if (defaultLocation) {
      getReviewsForDefault();
    }
  }, [defaultLocation]);

  const onClickOpenModel = () => {};

  const handleLocationChange = (option) => {
    setLocationId(option);
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const getAllLocations = async () => {
    try {
      setIsLoading(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
        if (res?.data?.data?.results?.length > 0) {
          const defaultLoc = res.data.data.results.find(
            (item) => item.defaultLocation !== null
          );
          if (defaultLoc !== undefined) {
            setDefaultLocation(defaultLoc);
            setLocationId(defaultLoc);
          } else {
            const earliestSubscription = res?.data?.data?.results.reduce(
              (earliest, current) => {
                return new Date(current.subscriptionDate) <
                  new Date(earliest.subscriptionDate)
                  ? current
                  : earliest;
              }
            );
            setDefaultLocation(earliestSubscription);
            setLocationId(earliestSubscription);
          }
        }

        //
        setIsLoading(false);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getReviewsForDefault = async () => {
    try {
      setIsLoading(true);

      const res = await api.get(
        `/reviewSource/location/${defaultLocation?.id}`
      );

      if (res.status === 200 || res.status === 201) {
        setReviewData(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getReviews = async () => {
    try {
      setIsLoading(true);

      const res = await api.get(`/reviewSource/location/${locationId?.id}`);

      if (res.status === 200 || res.status === 201) {
        setReviewData(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        const sourceArray = res.data.data;
        const duplicateSourceArray = {
          count: sourceArray?.count,
          results: sourceArray?.results,
        };
        const filterSourceArray = ["happycow", "ubereats"];

        const filterData = sourceArray?.results?.filter(
          (item) => !filterSourceArray.includes(item?.name)
        );

        if (locationId?.enableReviewAgent) {
          duplicateSourceArray.results = sourceArray?.results;
        } else {
          duplicateSourceArray.results = filterData;
        }

        setAllReviewSource(duplicateSourceArray);
        const checkTrue = duplicateSourceArray?.results?.filter(
          (item) => item?.mostly_used === true
        );
        const checkFalse = duplicateSourceArray?.results?.filter(
          (item) => item?.mostly_used === null
        );
        setMostlyUsedSource(checkTrue);
        setOtherSource(checkFalse);
      }
    } catch (error) {}
  };

  return (
    <>
      <TitleHeader title={t("Review_Source")} />

      <>
        {isLoading ? (
          <Loader />
        ) : allLocation?.results?.length > 0 ? (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.reviewsAddLocation}>
                  {t("Add_Location")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allLocation?.results?.length > 0 ? allLocation?.results : []
                  }
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  sx={{ width: "100%", bgcolor: "white" }}
                  //  value={locationId}
                  defaultValue={locationId ? locationId : defaultLocation}
                  onChange={(e, value) => {
                    handleLocationChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "28px" }} my={4} />
            {locationId || defaultLocation ? (
              <>
                <Grid container>
                  <Typography className={classes.reviewSites}>
                    {t("Review_Sites")}
                  </Typography>

                  <Grid container spacing={8}>
                    {mostlyUsedSource &&
                      mostlyUsedSource?.map((data, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Container
                              onClickOpenModel={onClickOpenModel}
                              reviewData={reviewData}
                              thirdPartReviewData={data}
                              locationId={locationId}
                              // onSubmit={handleOpen}
                              color={color}
                              selectedLocation={locationId}
                              setIsLoading={setIsLoading}
                              getReviews={getReviews}
                              allReviewSource={allReviewSource}
                              viewPermission={viewPermission}
                              addPermission={addPermission}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "8px" }} my={4} />

                <Grid container>
                  <Typography className={classes.otherReviewSites}>
                    {t("Other_review_sites")}
                  </Typography>

                  <Grid container spacing={8}>
                    {otherSource &&
                      otherSource?.map((data, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Container
                              onClickOpenModel={onClickOpenModel}
                              reviewData={reviewData}
                              thirdPartReviewData={data}
                              //  onSubmit={handleOpen}
                              color={color}
                              selectedLocation={locationId}
                              locationId={locationId}
                              setIsLoading={setIsLoading}
                              getReviews={getReviews}
                              allReviewSource={allReviewSource}
                              viewPermission={viewPermission}
                              addPermission={addPermission}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <NotFound hideIcon="true" text={t("NoLocation")} />
              </>
            )}
          </>
        ) : (
          <Box className={classes.locationbox}>
            <LocationNotFound
              text={t("No_Location_Added")}
              subtext={t("add_location_text")}
            />
          </Box>
        )}
      </>
    </>
  );
};

export default Index;
