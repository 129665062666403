import React, { useState } from "react";
import styled from "styled-components/macro";
import { Button, Tooltip, tooltipClasses, Box } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import Loader from "../../../components/Loaders/Loader";
import TitleHeader from "../../../components/CustomComponents/title";

import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import NotFound from "../../../components/NotFound/NotFound";
import CompanyAutoComplete from "../../../components/CompanyAutoComplete";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import moment from "moment";
import UpdateSessionModal from "./Components/Modal";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Paper = styled(MuiPaper)(spacing);

const EnhancedTableHead = (props) => {
  const { t } = props;

  const headCells = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "review_platform", alignment: "left", label: t("Review_Platform") },
    { id: "session_status", alignment: "left", label: t("Session_Status") },
    { id: "updated_at", alignment: "left", label: t("updated_at") },
    { id: "updated_by", alignment: "left", label: t("Updated_By") },
    { id: "actions", alignment: "right", label: t("actionss") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells?.map((headCell) => (
          <CustomizedTableCell
            sx={{ fontWeight: "700" }}
            key={headCell?.id}
            align={headCell?.alignment}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

const AdminReviewAgent = () => {
  const { t } = useTranslation();

  const [companyId, setCompanyId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allReviewSource, setAllReviewSource] = useState({
    count: 10,
    results: [
      {
        id: 0,
        review_platform: "",
        session_status: "",
        updated_at: "2023-12-06T11:24:40.511Z",
        updated_by: "",
        actions: "",
      },
    ],
  });

  const handleCompanyChange = (option) => {
    setPage(0);
    setCompanyId(option);
  };

  const handleApplyFilter = () => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, selectedData) => {
    setSelectedRow(selectedData);
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen && (
        <UpdateSessionModal
          open={isModalOpen}
          close={() => setIsModalOpen(false)}
          data={selectedRow}
        />
      )}
      <TitleHeader title={t("Review_Agent")} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <CompanyAutoComplete
            onCompanySelect={(value) => handleCompanyChange(value)}
            selectedCompanyId={companyId}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CompanyAutoComplete
            onCompanySelect={(value) => handleCompanyChange(value)}
            selectedCompanyId={companyId}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <CommonButton
            leftMargin={true}
            alignment={true}
            label={t("Search")}
            onSubmit={handleApplyFilter}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : allReviewSource && allReviewSource?.count > 0 ? (
        <Grid container marginTop="10px" spacing={6}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead t={t} />
                <TableBody>
                  {allReviewSource?.results?.map((row, index) => {
                    return (
                      <CustomizedTableRow
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        sx={{ paddingY: "8px" }}
                      >
                        <CustomizedTableCell sx={{ cursor: "pointer" }}>
                          {row?.id}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ cursor: "pointer" }}>
                          <BootstrapTooltip title={<Typography></Typography>}>
                            <Typography
                              noWrap
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "6rem",
                              }}
                            >
                              {row?.review_platform}
                            </Typography>
                          </BootstrapTooltip>
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          sx={{ color: "#FC3652", width: "150px" }}
                          align="left"
                        >
                          <FiberManualRecordIcon
                            sx={{
                              fontSize: 10,
                              color: "#FC3652",
                              paddingRight: "3px",
                            }}
                          />

                          {"--"}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ cursor: "pointer" }}>
                          {row?.updated_at === null
                            ? "--"
                            : moment(row?.updated_at).format("DD/MM/YYYY")}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ cursor: "pointer" }}>
                          {row?.updated_by}
                        </CustomizedTableCell>
                        <CustomizedTableCell padding="none" align="right">
                          <Box>
                            <Button onClick={handleClick}>{t("Fix")}</Button>
                          </Box>
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allReviewSource?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : (
        <NotFound text={t("Location_found")} />
      )}
    </>
  );
};

export default AdminReviewAgent;
