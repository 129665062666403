import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  TextField,
  Alert as MuiAlert,
  CircularProgress,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { TextareaAutosize } from "@material-ui/core";
import { fontWeight, spacing } from "@mui/system";
import styled from "styled-components/macro";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { languagesDataTwo } from "../../../assets/defaultLanguage";
import { api } from "../../../contexts/JWTContext";
import ReplayIcon from "@mui/icons-material/Replay";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
}));

const Alert = styled(MuiAlert)(spacing);

const PostAIModal = ({
  title,
  onCancel,
  tags,
  setTags,
  AiContentDescription,
  setAiContentDescription,
  generatedContent,
  setGeneratedContent,
  setTagsRequired,
  tagsRequired,
  contentRequired,
  setContentRequired,
  displayError,
  defaultLocation,
  setLimitNumber,
  limitNumber,
  translatePromptAgainLoading,
  setTranslatePromptAgainLoading,
  translatePrompLoading,
  setTranslatePrompLoading,
  userLang,
  setUserLang,
}) => {
  const classes = useStyles();
  const textArea = useRef();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [userLanguage, setUserLanguage] = React.useState(i18nextLng);
  const [translateAgain, setTranslateAgain] = useState(false);

  const [contentText, setContentText] = useState(generatedContent);
  const [duplicateBrand, setDuplicateBrand] = useState(false);

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // useEffect(() => {
  //   if (translateAgain) handleLanguageChange()
  // }, [userLanguage]);

  useEffect(() => {
    // setAiContentDescription("");
    setGeneratedContent("");
    setTags([]);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...tags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...tags, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
    }
    const lowerCaseBrandTags = tags.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateBrand(true);
      setTimeout(() => {
        setDuplicateBrand(false);
      }, 2000);
    } else {
      for (let i = 0; i < tags.length; i++) {
        if (filtered.includes(tags[i])) {
          setDuplicateBrand(true);
          setTimeout(() => {
            setDuplicateBrand(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };

  useEffect(() => {
    if (tags?.length > 10) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    }
  }, [tags]);
  const { t, i18n } = useTranslation();
  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index));
  };

  useEffect(() => {
    const text1 = "Compose a ";
    const text2 =
      "concise SEO-optimised description for a local business named";
    const text3 = "located in";
    const text4 = "The description should incorporate the following keywords: ";
    const text5 =
      "at least twice but no more than three times, without any special formatting such as asterisks.";
    const text6 = "Highlight unique selling points of";
    const text7 = "Mention the location";
    const text8 =
      "to emphasize its local relevance. Include a call-to-action encouraging potential customers to visit or learn more. Please ensure that the text is coherent, engaging, and specifically tailored to attract online search traffic. The description should not exceed 160 words.";
    let finalStr = `${text1}${text2} ${
      defaultLocation !== null
        ? `${defaultLocation?.name} ${text3} ${defaultLocation?.city}.`
        : `${defaultLocation?.name}.`
    } ${
      tags?.length > 0 ? `${text4}` + tags?.toString() + " " + `${text5}` : ""
    }${text6}  ${defaultLocation?.name}. ${
      defaultLocation?.city !== null
        ? `${text7}, ${defaultLocation?.city} ${text8}`
        : `${text7} ${text8}`
    }`;

    setAiContentDescription(finalStr);
    handleLanguageChangOnOpenModel(i18nextLng, finalStr);
    setUserLang(i18nextLng);
  }, []);
  function extractIntegerNumber(str) {
    // Regular expression to match any integer number
    const regex = /\b(\d+)\b/g; // This regex matches sequences of digits (\d+) surrounded by word boundaries (\b)

    // Use the match method of the string to get the integer numbers
    const matches = str.match(regex);

    if (matches) {
      // Return the first matched integer number found in the string
      return parseInt(matches[0]);
    }

    // Return null if no integer number is found
    return null;
  }

  useEffect(() => {
    const text1 = "Compose a ";
    const text2 =
      "concise SEO-optimised description for a local business named";
    const text3 = "located in";
    const text4 = "The description should incorporate the following keywords: ";
    const text5 =
      "at least twice but no more than three times, without any special formatting such as asterisks.";
    const text6 = "Highlight unique selling points of";
    const text7 = "Mention the location";
    const text8 =
      "to emphasize its local relevance. Include a call-to-action encouraging potential customers to visit or learn more. Please ensure that the text is coherent, engaging, and specifically tailored to attract online search traffic. The description should not exceed 160 words.";

    setAiContentDescription(
      `${text1}${text2} ${
        defaultLocation !== null
          ? `${defaultLocation?.name} ${text3} ${defaultLocation?.city}.`
          : `${defaultLocation?.name}.`
      } ${
        tags?.length > 0 ? `${text4}` + tags?.toString() + " " + `${text5}` : ""
      }${text6}  ${defaultLocation?.name}. ${
        defaultLocation?.city !== null
          ? `${text7}, ${defaultLocation?.city} ${text8}`
          : `${text7} ${text8}`
      }`
    );
    if (generatedContent !== contentText) {
      setTranslateAgain(true);
    } else {
      setTranslateAgain(false);
    }
  }, [tags]);

  // const handleLanguageChange = (language) => {
  //   setUserLanguage(language);
  // };

  const handleDisplayLanguageName = (langKey) => {
    const selectedLanguage = languagesDataTwo?.find(
      (language) => language?.value === langKey
    );
    return selectedLanguage?.language;
  };

  const handleLanguageChangOnOpenModel = async (lang, promptValue) => {
    setTranslatePrompLoading(true);
    setTranslateAgain(false);
    setUserLanguage(lang);
    setUserLang(lang);
    let splittedTextForLangDetect = "";
    if (promptValue && promptValue.includes("(Original)\n")) {
      splittedTextForLangDetect = promptValue.split("(Original)\n")[1];
    } else if (promptValue && promptValue.includes("(Translated by Google)")) {
      splittedTextForLangDetect = promptValue.split(
        "(Translated by Google)"
      )[0];
    } else {
      splittedTextForLangDetect = promptValue;
    }
    const detectdResponse = await api.post(`/languge/detact`, {
      text: splittedTextForLangDetect,
    });

    if (detectdResponse.status === 200) {
      if (detectdResponse?.data?.data?.detactLanguge !== lang) {
        let formattedText = promptValue;
        if (promptValue?.includes("(Original)")) {
          let splittedText = promptValue.split("(Original)");
          formattedText = splittedText[1];
        }

        let languageName = handleDisplayLanguageName(lang);
        splittedTextForLangDetect = `translate this prompt text in ${languageName} \n"${formattedText}"\n NOTE: Only translate do not compile prompt also Do not include any additional comments or text.`;

        try {
          const res = await api.post(`/openAi/generateContent`, {
            prompt: splittedTextForLangDetect,
          });
          if (res.status === 200) {
            // setAiPromptTranslatedText(res?.data?.data);
            setAiContentDescription(res?.data?.data);
            setContentText(res?.data?.data);
            // if (res?.data?.data?.length > 0) {
            // let str = `(${t("translated_text")} ${loginUser?.language}) ${
            //   res?.data?.data[0]?.text
            // }`;
            // let tempArray = { ...allData };
            // tempArray.results[index]["translatedText"] = str;

            // setReviewsData(tempArray);
            // item["translatedText"] = str;
            // }
            setTranslatePrompLoading(false);
            toast.success("Translated successfully");
          }
        } catch (error) {
          toast.error("Something went wrong");
          setTranslatePrompLoading(false);
        }
      } else {
        const foundLanguage = languagesDataTwo?.find(
          (langs) => langs?.value === lang
        );
        toast.info(`Already translated in ${foundLanguage?.language}`);
        setTranslateAgain(true);
        setTranslatePrompLoading(false);
      }
    }
  };

  const handleLanguageChange = async (lang) => {
    setTranslatePrompLoading(true);
    setTranslateAgain(false);
    setUserLanguage(lang);
    setUserLang(lang);
    let splittedTextForLangDetect = "";
    if (AiContentDescription && AiContentDescription.includes("(Original)\n")) {
      splittedTextForLangDetect = AiContentDescription.split("(Original)\n")[1];
    } else if (
      AiContentDescription &&
      AiContentDescription.includes("(Translated by Google)")
    ) {
      splittedTextForLangDetect = AiContentDescription.split(
        "(Translated by Google)"
      )[0];
    } else {
      splittedTextForLangDetect = AiContentDescription;
    }
    const detectdResponse = await api.post(`/languge/detact`, {
      text: splittedTextForLangDetect,
    });

    if (detectdResponse.status === 200) {
      if (detectdResponse?.data?.data?.detactLanguge !== lang) {
        let formattedText = AiContentDescription;
        if (AiContentDescription?.includes("(Original)")) {
          let splittedText = AiContentDescription.split("(Original)");
          formattedText = splittedText[1];
        }

        let languageName = handleDisplayLanguageName(lang);
        splittedTextForLangDetect = `translate this prompt text in ${languageName} \n"${formattedText}"\n NOTE: Only translate do not compile prompt also Do not include any additional comments or text.`;

        try {
          const res = await api.post(`/openAi/generateContent`, {
            prompt: splittedTextForLangDetect,
          });
          if (res.status === 200) {
            // setAiPromptTranslatedText(res?.data?.data);
            setAiContentDescription(res?.data?.data);
            setContentText(res?.data?.data);
            // if (res?.data?.data?.length > 0) {
            // let str = `(${t("translated_text")} ${loginUser?.language}) ${
            //   res?.data?.data[0]?.text
            // }`;
            // let tempArray = { ...allData };
            // tempArray.results[index]["translatedText"] = str;

            // setReviewsData(tempArray);
            // item["translatedText"] = str;
            // }
            setTranslatePrompLoading(false);
            toast.success("Translated successfully");
          }
        } catch (error) {
          toast.error("Something went wrong");
          setTranslatePrompLoading(false);
        }
      } else {
        const foundLanguage = languagesDataTwo?.find(
          (langs) => langs?.value === lang
        );
        toast.info(`Already translated in ${foundLanguage?.language}`);
        setTranslateAgain(true);
        setTranslatePrompLoading(false);
      }
    }
  };

  const handleLanguageChangeAgain = async () => {
    setTranslatePromptAgainLoading(true);
    let splittedTextForLangDetect = AiContentDescription;
    //  setTranslateAgain(false);
    let languageName = handleDisplayLanguageName(userLanguage);
    splittedTextForLangDetect = `translate this prompt text in ${languageName} \n"${AiContentDescription}"\n NOTE: Only translate do not compile prompt also Do not include any additional comments or text.`;
    try {
      const res = await api.post(`/openAi/generateContent`, {
        prompt: splittedTextForLangDetect,
      });
      if (res.status === 200) {
        setAiContentDescription(res?.data?.data);

        setTranslatePromptAgainLoading(false);
        setTranslateAgain(false);
        toast.success("Translated successfully");
      }
    } catch (error) {
      toast.error("Something went wrong");
      setTranslatePromptAgainLoading(false);
      setTranslateAgain(false);
    }
  };

  const handleAddKeyword = (e) => {
    // let value = e.target.value;
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(tags);
      const mergeResult = [...tags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(tags);
      const mergeResult = [...tags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(tags);
      const mergeResult = [...tags, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = tags.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateBrand(true);
    } else {
      setDuplicateBrand(false);
    }

    /*   const newValue = e.target.value.replaceAll(",", "");
    const newValueTwo = e.target.value.replaceAll(".", "");
   
    setTags([...tags, newValueTwo]);
    e.target.value = ""; */
  };

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
          <Grid className={classes.headingTextGrid}>
            <Typography className={classes.headingText}>
              {t("Ai_Description")}
            </Typography>
          </Grid>
          <Divider variant="middle" />
          <Grid className={classes.mainKeyWordContainer}>
            <Typography className={classes.labelText}>
              {t("Keywords")}
            </Typography>

            <Grid
              className={
                tagsRequired === false
                  ? "tags-input-container"
                  : "tags-input-container-error"
              }
            >
              {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">
                    {tag}
                    <span className="close" onClick={() => removeTag(index)}>
                      {" "}
                      X
                    </span>
                  </span>
                </div>
              ))}

              {/*  <input
                type="text"
                className="tags-input"
                placeholder={t("E.g.Vegan,_Restaurant")}
                //   onKeyPress={this.keyPress}
                onKeyDown={handleKeyDown}
                disabled={tagsRequired}
                //  defaultValue={productData.tags}
              /> */}
              <TextField
                variant="standard"
                rows={4}
                maxRows={20}
                multiline
                disabled={tagsRequired}
                placeholder={t("E.g.Vegan,_Restaurant")}
                className="tags-input"
                onChange={(e) => {
                  if (
                    e.target.value === "," ||
                    e.target.value === "." ||
                    e.target.value.trim() === ""
                  ) {
                    e.target.value = "";
                  } else if (
                    (e.target.value !== "" &&
                      e.target.value !== "," &&
                      e.target.value.includes(".")) ||
                    (e.target.value !== "." &&
                      e.target.value !== "" &&
                      e.target.value.includes(","))
                  ) {
                    handleAddKeyword(e);
                  }
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                onKeyDown={handleKeyDown}
                style={{
                  width: "100%",
                  marginLeft: "5px",
                  borderBottom: "none",
                  marginTop: "5px",

                  padding: "0px",
                }}
              />
            </Grid>

            {duplicateBrand ? (
              <Typography className={classes.errorText}>
                {t("keywords_duplicated_error")}
              </Typography>
            ) : tagsRequired ? (
              <Typography className={classes.errorText}>
                {t("Enter_Keywords_Msg_limit")}
              </Typography>
            ) : (
              <Typography className={classes.helpingText}>
                {t("Enter_Keyword_text_post")}
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Grid
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className={classes.mainKeyWordContainer}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography className={classes.labelText}>
                  {t("select_prompt_language")}
                </Typography>

                {translatePrompLoading && (
                  <Grid
                    item
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    // className={classes.mainKeyWordContainer}
                  >
                    <CircularProgress
                      style={{
                        width: "17px",
                        height: "17px",
                        color: "#06BDFF",
                        marginTop: "8px",
                        marginLeft: "28px",
                      }}
                    />
                  </Grid>
                )}
              </Box>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userLanguage}
                  onChange={(event) => handleLanguageChange(event.target.value)}
                  disabled={translatePrompLoading}
                >
                  {languagesDataTwo?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                      {item?.language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid className={classes.mainKeyWordContainer}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.labelText}>
                {t("What_Would_Like")}
              </Typography>
              {translateAgain &&
                (translatePromptAgainLoading ? (
                  <CircularProgress
                    style={{
                      width: "17px",
                      height: "17px",
                      color: "#06BDFF",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  />
                ) : (
                  <BootstrapTooltip title={t("translate_again")}>
                    <IconButton
                      sx={{
                        width: "20px",
                        height: "18px",
                        color: "#06BDFF",
                      }}
                      // disabled={isLoading ? true : false}
                      aria-label="retry"
                      size="large"
                      onClick={() => handleLanguageChangeAgain()}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </BootstrapTooltip>
                ))}
            </Box>
            <TextField
              multiline
              fullWidth
              onChange={(e) => {
                if (AiContentDescription !== contentText) {
                  setTranslateAgain(true);
                } else {
                  setTranslateAgain(false);
                }
                let extractedNumber = extractIntegerNumber(e.target.value);
                setLimitNumber(extractedNumber);

                setAiContentDescription(e.target.value);
                if (e.target.value?.length > 0) {
                  setContentRequired(false);
                } else {
                  setContentRequired(true);
                }
              }}
              error={contentRequired}
              helperText={contentRequired && "Content is required"}
              placeholder={t("What_Would_Like_Placeholder")}
              value={AiContentDescription}
              name="Description"
            />
            {displayError && (
              <Alert mt={2} mb={3} severity="error">
                {
                  "Error in creating a.i written content, please change keywords & content or write down post description manually"
                }
              </Alert>
            )}
          </Grid>
          {generatedContent?.length > 0 && (
            <Grid className={classes.mainKeyWordContainer}>
              <Typography className={classes.labelText}>
                {t("Generated_Content")}
              </Typography>

              <TextField
                multiline
                fullWidth
                placeholder={t("What_Would_Like_Placeholder")}
                value={generatedContent}
                name="Description"
                onChange={(e) => setGeneratedContent(e.target.value)}
              />
            </Grid>
          )}
        </Box>

        {/* </DialogActions> */}
      </Grid>
    </>
  );
};

export default PostAIModal;
