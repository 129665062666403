import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  Stack,
  Divider,
  Zoom,
  Modal,
} from "@mui/material";
import dayjs from "dayjs";
import { api } from "../../../../contexts/JWTContext";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PostAIModal from "../../../../components/Models/AIModal/PostAIModal";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useStyles } from "../styles/style";
import PostCustomModel from "./CustomModel";
import PostCard from "../AddPosts/addPostCard";
import ProfilePictureModal from "../AddPosts/Components/pictureModal";
import Loader from "../../../../components/Loaders/Loader";
import { languagesDataTwo } from "../../../../assets/defaultLanguage";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const EditBulkPosts = ({
  bulkEditData,
  open,
  close,
  getAllUpcomingPosts,
  setPageNumber,
  handleBulkEditData,
  bulkLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const [repeat, setRepeat] = useState("no");
  const [loading, setLoading] = useState(false);
  const [allLocation, setAllLocation] = useState({ count: 0, results: [] });
  const [displayError, setDisplayError] = useState("");
  const locationId = location.state?.location?.id;
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationRequiredBulk, setLocationRequiredBulk] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [tags, setTags] = useState([]);
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [contentRequired, setContentRequired] = useState(false);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [endDateGreater, setEndDateGreater] = useState(false);
  const [ValidDate, setValidDate] = useState(true);
  const [EndValidDate, setEndValidDate] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [openProfilePictureModal, setOpenProfilePictureModal] = useState(false);
  const [loctionLoadings, setLoctionLoadings] = useState(false);

  const [limitNumber, setLimitNumber] = useState("160");
  const [openCustomModel, setOpenCustomModel] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState("daily");
  const [occuranceValue, setOccuranceValue] = useState(0);
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [customError, setCustomError] = useState(false);
  const [translatePrompLoading, setTranslatePrompLoading] = useState(false);
  const [selectedPostData, setSelectedPostData] = useState(null);
  const [checkRequired, setCheckRequired] = useState(false);
  const [bulkStartDate, setBulkStartDate] = useState(null);
  const [startDateRequired, setStartDateRequired] = useState(false);
  const [showEditAll, setShowEditAll] = useState(false);
  const [defaultLocationByDefault, setDefaultLocationByDefault] =
    useState(null);

  const [postData, setPostData] = useState(bulkEditData);
  const [translatePromptAgainLoading, setTranslatePromptAgainLoading] =
    useState(false);

  const [imageData, setImageData] = useState([]);
  const [removeSelectedImages, setRemoveSelectedImages] = useState(false);
  const [tempLocations, setTempLocations] = useState([]);
  const [userLang, setUserLang] = useState(localStorage?.getItem("i18nextLng"));

  useEffect(() => {
    if (open) {
      handleBulkEditData();
    }
  }, [open]);

  useEffect(() => {
    setPostData(bulkEditData);
  }, [bulkEditData]);

  useEffect(() => {
    let arr = [];
    if (allLocation?.results?.length > 0 && selectedLocation?.length > 0) {
      allLocation?.results?.forEach((itemOne) => {
        selectedLocation?.forEach((itemTwo) => {
          if (itemOne?.id === itemTwo?.id) {
            arr?.push(itemTwo);
          }
        });
      });
      /*  if (arr?.length > 0 && arr.length === selectedLocation?.length) {
        setDisableLocations(false);
      } else {
        setDisableLocations(true);
      } */
    }
  }, [allLocation, selectedLocation]);

  useEffect(() => {
    if (selectedImages?.length > 0) {
      setOpenPictureModal(true);
    }
  }, [selectedImages]);

  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    setLimitNumber("160");
  };

  const getAllLocations = async () => {
    try {
      setLoctionLoadings(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/googleLinkedlocations`);
      if (res.status === 200) {
        setAllLocation(res.data.data);
        setLoctionLoadings(false);
      }
    } catch (error) {
      setLoctionLoadings(false);
    }
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  const generateContent = async (data) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/openAi/generateContent`, {
          prompt: data,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const lang = `Please give response in ${
      languagesDataTwo.find((item) => item.value === userLang)?.language
    } language: ${AiContentDescription}`;
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = lang.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = lang.concat(str2);
    }

    generateContent(finalStr);
  };
  const handleSubmit = async () => {
    const data = postData?.map((item) => {
      let locationIds = item?.locationData.map((location) => location.id);
      let postLocations = item?.postLocations.filter((ele) =>
        locationIds.includes(ele.locationId)
      );

      return {
        endDate: item?.endDate,
        startDate: item?.startDate,
        postId: item?.id,
        content: item?.content,
        url: item?.url,
        repeat: item?.repeat,
        postType: item?.postType,
        isUploadPicture: item?.isUploadPicture,
        uploadUberAll: item?.uploadUberAll,
        postPictures: item?.postPictures,
        customSchedule: item?.customSchedule,
        postLocations: postLocations,
        locationIds: locationIds,
      };
    });

    try {
      setLoading(true);
      const res = await api.patch(`/post/bulkPosts`, { posts: data });

      if (res.status === 200) {
        toast.success(res.data.message);
        getAllUpcomingPosts(true);
        setPageNumber(0);
        setLoading(false);
        close();
        // handleCloseTemplateMenu();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      close();
    }
  };

  const handleCloseCustomModel = () => {
    setOpenCustomModel(false);
    if (
      (selectedMonthDays?.length === 0 && frequencyValue === "monthly") ||
      (selectedWeekDays?.length === 0 && frequencyValue === "weekly") ||
      occuranceValue === 0
    ) {
      setCustomError(true);
    } else {
      setCustomError(false);
    }

    if (frequencyValue === "" && repeat === "custom") {
      setRepeat("no");
    }
    // setSelectedPostData(null);
  };

  const changeHandler = (index, itemName, value, fromCustom) => {
    setPostData((prevPostData) => {
      const updatedPostData = [...prevPostData]; // Create a copy of the state array

      if (fromCustom) {
        if (index !== undefined) {
          updatedPostData[index] = {
            ...updatedPostData[index],
            customSchedule: {
              ...updatedPostData[index].customSchedule,
              [itemName]: value,
            },
          };
          setSelectedPostData(updatedPostData[index].customSchedule);
        } else {
          updatedPostData.forEach((item) => {
            item.customSchedule = {
              ...item.customSchedule,
              [itemName]: value,
            };
          });
        }
      } else {
        if (index !== undefined) {
          updatedPostData[index] = {
            ...updatedPostData[index],
            [itemName]: value,
          };
        } else {
          updatedPostData.forEach((item) => {
            item[itemName] = value;
          });
        }
      }
      return updatedPostData; // Return the updated state
    });
  };

  const handleClickCloseWarning = (index) => {
    let tempPostData = [...postData];
    let temmpObj = tempPostData[index];
    temmpObj["errorUberAll"] = false;
    tempPostData[index] = temmpObj;
    setPostData(tempPostData);
  };
  const handleChangeDescription = (e, index) => {
    changeHandler(index, "content", e.target.value);
  };
  const handleChangeAIDescription = (e, index) => {
    changeHandler(index, "content", e);
  };

  const handleChangeTitle = (e, index) => {
    changeHandler(index, "title", e.target.value);
  };

  const handleChangeStartDate = (e, index) => {
    setBulkStartDate(null);
    changeHandler(index, "startDate", e);
  };
  const handleChangeEndDate = (e, index) => {
    changeHandler(index, "endDate", e);
  };

  const handleChangeRepeatPost = (e, index) => {
    changeHandler(index, "repeat", e);
  };

  const handleChangeFrequency = (e, index) => {
    changeHandler(index, "frequency", e, true);
  };

  const handleChangeOccurance = (e, index) => {
    changeHandler(index, "occurance", parseInt(e), true);
  };
  const handleChangeCustomDates = (e, index) => {
    changeHandler(index, "dates", e, true);
  };

  const handleChangeCustomDays = (e, index) => {
    changeHandler(index, "days", e, true);
  };

  const handleChangeBusinessCta = (e, index) => {
    changeHandler(index, "postType", e.target.value);
    if (e.target.value === "CALL" && index) {
      let tempPostData = [...postData];
      let tempPostObj = tempPostData[index];
      tempPostObj["url"] = "";
      tempPostData[index] = tempPostObj;
      setPostData(tempPostData);
    }
  };

  const handleChangeButtonUrl = (e, index) => {
    changeHandler(index, "url", e.target.value);
  };
  const handleChangeLocation = (e, index, locations) => {
    const defaultLocationObj = locations?.find(
      (item) => item?.defaultLocation !== null
    );
    if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
      handleMakeDefaultLocation(defaultLocationObj, index);
    } else {
      handleMakeDefaultLocation(locations[0], index);
    }
    changeHandler(index, "locationData", locations);
    setTempLocations(locations);
  };
  const handleChangeLocationBulk = (e, index, locations) => {
    if (e?.length > 0) {
      setLocationRequired(false);
    }
    if (locations?.length > 0) {
      const defaultLocationObj = locations?.find(
        (item) => item?.defaultLocation !== null
      );
      if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
        handleMakeDefaultLocation(defaultLocationObj, index);
      } else {
        handleMakeDefaultLocation(locations[0], index);
      }
      changeHandler(index, "locationData", locations);
      setTempLocations(locations);
    } else {
      setPostData(
        postData.map((item) => {
          const bulkLocationData = item.locationData.filter(
            (ele) => ele.id !== tempLocations[0]?.id
          );
          return {
            ...item,
            locationData: bulkLocationData,
          };
        })
      );
    }
  };

  const handleMakeDefaultLocation = (e, index) => {
    setDefaultLocation(e);
    changeHandler(index, "defaultLocation", e);
    // changeHandler(index, "content", "");
  };
  const handleChangeCheckbox = (e, index) => {
    changeHandler(index, "isUploadPicture", e.target.checked);
  };

  const handleChangeCheckboxUberAll = (e, index) => {
    changeHandler(index, "uploadUberAll", e.target.checked);
  };

  const handleDeletePost = (e, index) => {
    const filteredPosts = postData?.filter((post, indx) => {
      return indx !== index;
    });
    setPostData(filteredPosts);
  };

  const handleShowEditAll = () => {
    setShowEditAll(!showEditAll);
  };

  const changeHandlerBulkDateUpdate = (dateTime, timeInterval) => {
    let tempPostData = [...postData];
    let initialDate = dateTime;
    if (dateTime !== null) {
      setStartDateRequired(false);
    }
    tempPostData?.forEach((item) => {
      item[`startDate`] = initialDate;
      setBulkStartDate(dateTime);
      initialDate = dayjs(initialDate).add(timeInterval, "m").toDate();
    });

    setPostData(tempPostData);
  };

  const handleChangeLocationData = (e, index) => {
    postData.forEach((item, i) => {
      const oldLocations = item?.locationData.map((location) => location.id);
      const newLocations = e?.map((location) => location.id);

      let removedLocations = [];
      if (item?.new !== undefined && e.length > 0) {
        removedLocations = item.new.filter(
          (location) => !newLocations.includes(location)
        );
      } else if (item?.new !== undefined && e.length === 0) {
        removedLocations = item.new;
      }

      const mergedArray = [...oldLocations, ...newLocations];
      const uniqueArray = [...new Set(mergedArray)];

      const filteredArray = uniqueArray.filter(
        (location) => !removedLocations.includes(location)
      );

      const locations = allLocation?.results?.filter((obj) =>
        filteredArray.includes(obj.id)
      );

      setPostData((prev) => {
        const newData = [...prev];
        newData[i].locationData = locations;
        newData[i].old = oldLocations;
        newData[i].new = newLocations;

        return newData;
      });
    });
  };

  const handleChangeAIDescriptionForBulk = (e, index) => {
    changeHandler(index, "content", e);
  };

  const handleChangeAIDescriptionForBulkTwo = (e, index) => {
    if (e.length > 0) {
      e.forEach((content, indexValue) => {
        let removeSpaces = content;
        if (removeSpaces.startsWith("\n")) {
          removeSpaces = removeSpaces.trimStart();
        }
        changeHandler(indexValue, "content", removeSpaces);
      });
    }
  };

  const getImagesData = () => {
    const images = imageData.map((item) => item?.postPictures[0]);
    const imagesError = imageData.map((item) => item?.errorUberAll);

    const imagesArray =
      images.length >= postData?.length
        ? images.slice(0, postData.length)
        : Array.from(
            { length: postData.length },
            (_, i) => images[i % images.length]
          );

    const uberAllError =
      imagesError.length >= postData?.length
        ? imagesError.slice(0, postData.length)
        : Array.from(
            { length: postData.length },
            (_, i) => imagesError[i % imagesError.length]
          );

    const updatePosts = postData.map((item, index) => ({
      ...item,
      postPicture: [imagesArray[index]],
      postPictures: [imagesArray[index]],
      errorUberAll: uberAllError[index],
    }));

    setPostData(updatePosts);
    setOpenProfilePictureModal(false);
    setRemoveSelectedImages(false);
    setImageData([]);
  };

  const checkPostData = () => {
    return postData?.some((item) => {
      if (item?.startDate === null || item?.locationData?.length === 0) {
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <>
      {openCustomModel && (
        <PostCustomModel
          title={t("Custom_Repeat_Date")}
          onCancel={handleCloseCustomModel}
          setOpenCustomModel={setOpenCustomModel}
          open={openCustomModel}
          frequencyValue={frequencyValue}
          setFrequencyValue={setFrequencyValue}
          occuranceValue={occuranceValue}
          setOccuranceValue={setOccuranceValue}
          selectedWeekDays={selectedWeekDays}
          setSelectedWeekDays={setSelectedWeekDays}
          selectedMonthDays={selectedMonthDays}
          setSelectedMonthDays={setSelectedMonthDays}
          repeat={repeat}
        />
      )}
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
        >
          <PostAIModal
            title={t("AI Writer")}
            onCancel={handleCloseTemplateMenu}
            tags={tags}
            setTags={setTags}
            displayError={displayError}
            AiContentDescription={AiContentDescription}
            setAiContentDescription={setAiContentDescription}
            generatedContent={generatedContent}
            setGeneratedContent={setGeneratedContent}
            setTagsRequired={setTagsRequired}
            tagsRequired={tagsRequired}
            setContentRequired={setContentRequired}
            contentRequired={contentRequired}
            defaultLocation={defaultLocation}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            translatePromptAgainLoading={translatePromptAgainLoading}
            setTranslatePromptAgainLoading={setTranslatePromptAgainLoading}
            translatePrompLoading={translatePrompLoading}
            setTranslatePrompLoading={setTranslatePrompLoading}
            userLang={userLang}
            setUserLang={setUserLang}
          />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {generatedContent?.length === 0 && (
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Me")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  <LoadingButton
                    loading={loading}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
            <style>
              {`
           .MuiDialogActions-root {
            display: block;
          }
         
        `}
            </style>
          </DialogActions>
        </Dialog>
      )}

      <Modal open={open} onClose={() => close(false)}>
        <Grid container>
          <Grid
            item
            sx={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid className={classes.styleMainModelContainer}>
              <Grid
                sx={{
                  pb: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography className={classes.mainContainerHeading}>
                  {t("bulk_edit_title")}
                </Typography>

                <IconButton
                  autoFocus
                  onClick={() => close(false)}
                  className="delete_button"
                  sx={{ paddingTop: "0px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider />
              {bulkLoading ? (
                <Loader />
              ) : (
                <Grid className={classes.contentContainer}>
                  {postData?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#FFFFFF",
                          width: "100%",
                          textAlign: "center",
                          backgroundColor: "#0638C1",
                          paddingY: "14px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={handleShowEditAll}
                      >
                        {t("Post_Desc")}
                      </Typography>
                    </>
                  )}

                  {showEditAll && postData.length > 0 && (
                    <Box
                      sx={{
                        border: "3px solid blue",
                        paddingX: "14px",
                        borderRadius: "8px",
                      }}
                    >
                      <Grid
                        container
                        sx={{ marginTop: "8px", marginBottom: "14px" }}
                      >
                        <Grid item md={12} xs={12}>
                          <PostCard
                            postData={postData}
                            handleChangeStartDate={handleChangeStartDate}
                            handleChangeEndDate={handleChangeEndDate}
                            handleChangeRepeatPost={handleChangeRepeatPost}
                            handleChangeBusinessCta={handleChangeBusinessCta}
                            handleChangeCheckbox={handleChangeCheckbox}
                            handleChangeCheckboxUberAll={
                              handleChangeCheckboxUberAll
                            }
                            handleChangeButtonUrl={handleChangeButtonUrl}
                            handleChangeDescription={handleChangeDescription}
                            handleChangeAIDescription={
                              handleChangeAIDescription
                            }
                            handleChangeLocation={handleChangeLocationBulk}
                            handleChangeTitle={handleChangeTitle}
                            handleDeletePost={handleDeletePost}
                            showEditAll={showEditAll}
                            bulkStartDate={bulkStartDate}
                            setShowEditAll={setShowEditAll}
                            startDateRequired={startDateRequired}
                            changeHandlerBulkDateUpdate={
                              changeHandlerBulkDateUpdate
                            }
                            handleChangeLocationData={handleChangeLocationData}
                            handleChangeAIDescriptionForBulk={
                              handleChangeAIDescriptionForBulk
                            }
                            handleChangeAIDescriptionForBulkTwo={
                              handleChangeAIDescriptionForBulkTwo
                            }
                            setLocationRequired={setLocationRequiredBulk}
                            locationRequired={locationRequiredBulk}
                            endDateGreater={endDateGreater}
                            setEndDateGreater={setEndDateGreater}
                            handleMakeDefaultLocation={
                              handleMakeDefaultLocation
                            }
                            setValidDate={setValidDate}
                            ValidDate={ValidDate}
                            setEndValidDate={setEndValidDate}
                            EndValidDate={EndValidDate}
                            setSelectedPostData={setSelectedPostData}
                            selectedPostData={selectedPostData}
                            handleChangeFrequency={handleChangeFrequency}
                            handleChangeOccurance={handleChangeOccurance}
                            handleChangeCustomDates={handleChangeCustomDates}
                            handleChangeCustomDays={handleChangeCustomDays}
                            allLocation={allLocation}
                            loctionLoadings={loctionLoadings}
                            defaultLocation={defaultLocation}
                            setDefaultLocation={setDefaultLocation}
                            defaultLocationByDefault={defaultLocationByDefault}
                            setDefaultLocationByDefault={
                              setDefaultLocationByDefault
                            }
                            bulkImages={true}
                            updateBulkImages={(bool) => {
                              setOpenProfilePictureModal(bool);
                              setRemoveSelectedImages(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {postData?.map((data, index) => (
                    <Stack direction="column">
                      <PostCard
                        key={data?.id ?? index}
                        index={index}
                        data={data}
                        postData={postData}
                        handleChangeStartDate={handleChangeStartDate}
                        handleChangeEndDate={handleChangeEndDate}
                        handleChangeRepeatPost={handleChangeRepeatPost}
                        handleChangeCheckbox={handleChangeCheckbox}
                        handleChangeCheckboxUberAll={
                          handleChangeCheckboxUberAll
                        }
                        handleChangeBusinessCta={handleChangeBusinessCta}
                        handleChangeButtonUrl={handleChangeButtonUrl}
                        handleChangeLocation={handleChangeLocation}
                        handleChangeDescription={handleChangeDescription}
                        handleChangeAIDescription={handleChangeAIDescription}
                        handleChangeTitle={handleChangeTitle}
                        handleDeletePost={handleDeletePost}
                        checkRequired={checkRequired}
                        setBulkStartDate={setBulkStartDate}
                        startDateRequired={startDateRequired}
                        setStartDateRequired={setStartDateRequired}
                        setLocationRequired={setLocationRequired}
                        locationRequired={locationRequired}
                        endDateGreater={endDateGreater}
                        setEndDateGreater={setEndDateGreater}
                        handleMakeDefaultLocation={handleMakeDefaultLocation}
                        setValidDate={setValidDate}
                        ValidDate={ValidDate}
                        setEndValidDate={setEndValidDate}
                        EndValidDate={EndValidDate}
                        handleClickCloseWarning={handleClickCloseWarning}
                        setSelectedPostData={setSelectedPostData}
                        selectedPostData={selectedPostData}
                        handleChangeFrequency={handleChangeFrequency}
                        handleChangeOccurance={handleChangeOccurance}
                        handleChangeCustomDates={handleChangeCustomDates}
                        handleChangeCustomDays={handleChangeCustomDays}
                        setPostData={setPostData}
                        allLocation={allLocation}
                        loctionLoadings={loctionLoadings}
                        defaultLocation={defaultLocation}
                        setDefaultLocation={setDefaultLocation}
                        defaultLocationByDefault={defaultLocationByDefault}
                        setDefaultLocationByDefault={
                          setDefaultLocationByDefault
                        }
                      />
                      <Divider />
                    </Stack>
                  ))}
                </Grid>
              )}
              <Divider />
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  py: "10px",
                }}
              >
                <CommonButton
                  type="submit"
                  isLoading={false}
                  disabled={false}
                  onSubmit={() => close(false)}
                  label={t("Cancel")}
                  leftMargin={true}
                  customHeight={true}
                  displayWhite={"true"}
                />
                <CommonButton
                  type="submit"
                  isLoading={false}
                  disabled={checkPostData()}
                  onSubmit={handleSubmit}
                  label={t("Update")}
                  leftMargin={true}
                  customHeight={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <ProfilePictureModal
        locationId={locationId}
        handleClose={getImagesData}
        open={openProfilePictureModal}
        loading={loading}
        setPostData={setImageData}
        handleCloseNavigate={() => setOpenProfilePictureModal(false)}
        postData={imageData}
        remove={removeSelectedImages}
        bulkPostImage={true}
      />
    </>
  );
};

export default EditBulkPosts;
