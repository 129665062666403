import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  DialogTitle,
  Box,
  DialogContent,
  Dialog,
  Checkbox,
  Chip,
  Card,
  Button,
  Avatar,
  IconButton,
  DialogActions,
} from "@mui/material";
import { WarningAmberOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Delete from "../../../../../../../../components/Models/DeleteModal/Delete";
import Truncate from "react-truncate";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../../contexts/JWTContext";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import { useStyles } from "../../../../../Styles/style";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddMenuItem from "./AddMenuItem";

const SectionDetailModel = ({
  title,
  open,
  handleCloseProductSectionModel,
  locationProducts,
  selectedSectionData,
  selectedItemData,
  locationDetailsData,
  getAllMenus,
  location,
  getLocationDetailsListing,
  section,
  selectedLocationsBulkUpdate,
  allMedia,
  locationName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedSectionToUpdate, setSelectedSectionToUpdate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [sectionToDisplay, setSectionToDisplay] = useState(section);
  const [openConfirmModel, setOpenConfirmModel] = useState(false);

  const handleSelectSection = (e, section) => {
    if (e.target.checked) {
      setSelectedSectionToUpdate(section);
    } else {
      setSelectedSectionToUpdate(null);
    }
  };

  const handleDataToUpdate = (data) => {
    let dataToUpdate = data[0]?.sections;
    let previousSectionIndex = dataToUpdate?.findIndex(
      (item) =>
        item?.labels?.length > 0 &&
        item?.labels[0]?.displayName ===
          selectedSectionData?.labels[0]?.displayName
    );

    dataToUpdate[previousSectionIndex].items = dataToUpdate[
      previousSectionIndex
    ].items?.filter(
      (item) =>
        item?.labels[0]?.displayName !==
        selectedItemData?.labels[0]?.displayName
    );

    if (dataToUpdate[previousSectionIndex].items?.length === 0) {
      dataToUpdate = dataToUpdate?.filter(
        (item) =>
          item?.labels[0]?.displayName !==
          selectedSectionData?.labels[0]?.displayName
      );
    }

    let newSectionIndex = dataToUpdate?.findIndex(
      (item) =>
        item?.labels?.length > 0 &&
        item?.labels[0]?.displayName ===
          selectedSectionToUpdate?.labels[0]?.displayName
    );

    dataToUpdate[newSectionIndex]?.items?.push(selectedItemData);

    return dataToUpdate;
  };

  const handleSaveSectionData = async () => {
    setLoading(true);
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }
    let dummyObj = {};
    // let objjjj = JSON.stringify(locationDetailsData?.result?.menus);
    //  dummyObj = JSON.parse(objjjj);
    //  let menuData = handleDataToUpdate(dummyObj);

    let objTwo = [
      {
        labels: [
          {
            displayName: "Menu",
          },
        ],
        sections: [sectionToDisplay],
      },
    ];

    try {
      setLoading(true);

      const res = await api.post(`/menus`, {
        menus: objTwo,
        locationId: locationIds,
      });

      if (res.status === 200) {
        setLoading(false);
        toast.success(`${t("Updated_Successfully")}`);
        getAllMenus();
        handleCloseConfirmModel();
        handleCloseProductSectionModel();

        // getLocationDetailsListing(location);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleOpenAddItem = () => {
    setOpenAddForm(true);
  };
  const handleCloseAddForm = () => {
    setOpenAddForm(false);
  };
  const handleCheckForImage = (selectedItemValue) => {
    if (allMedia !== null)
      if (allMedia?.length > 0) {
        if (
          selectedItemValue?.attributes?.mediaKeys?.length > 0 &&
          selectedItemValue?.attributes?.mediaKeys[0]?.includes("https") ===
            false
        ) {
          const selectedImageItem = allMedia?.find(
            (item) =>
              item?.name?.includes(
                selectedItemValue?.attributes?.mediaKeys?.length > 0
                  ? selectedItemValue?.attributes?.mediaKeys[0]
                  : ""
              ) /* &&
                (item?.category === "FOOD_AND_DRINK" ||
                  item?.category === "FOOD_AND_MENU") */
          );

          return selectedImageItem;
        } else {
          return selectedItemValue?.attributes?.mediaKeys?.length > 0
            ? selectedItemValue?.attributes?.mediaKeys[0]
            : null;
        }
      }
  };

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };

  return (
    <>
      {openConfirmModel && (
        <Dialog
          open={openConfirmModel}
          onClose={handleCloseConfirmModel}
          sx={{
            "& .MuiDialog-paper": {
              width: "550px",
              height: "340px",
            },
          }}
        >
          <DialogTitle>
            <Box className={classes.dialogBoxBulk}>
              <IconButton
                onClick={handleCloseConfirmModel}
                className="delete_button"
                // sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBoxContentBulk}>
            <WarningAmberOutlined className={classes.iconClass} />
            <Typography className={classes.makePrimaryUserTextHeadBulk}>
              {t("Bulk_data_update")}
            </Typography>
            <Typography className={classes.makePrimaryUserTexBulk}>
              {t("bulk_menu")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  onSubmit={handleCloseConfirmModel}
                  label={t("Cancel")}
                />

                <CommonButton
                  onSubmit={handleSaveSectionData}
                  label={t("Yes")}
                  isLoading={loading}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: "700px",
            maxWidth: "700px",
            height: "700px",
          },
        }}
        open={open}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px",
            marginBottom: "12px",
          }}
        >
          <Typography
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title}
          </Typography>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            padding: "12px",
            paddingTop: "0px",
          }}
        >
          {openAddForm !== true && (
            <Grid container>
              {" "}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mb={2}
                mt={2}
                sx={{ textAlign: "start" }}
              >
                <Button
                  autoFocus
                  onClick={() => {
                    //  setSelectedSectionData(section);
                    handleOpenAddItem("add", section);
                  }}
                  className={classes.addButtonSection}
                >
                  <AddIcon
                    fontSize="small"
                    className={classes.addIconSection}
                  />{" "}
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#1B2430",
                    }}
                  >
                    {t("add_product")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
          {openAddForm && (
            <AddMenuItem
              openFrom={"add"}
              handleCloseAddForm={handleCloseAddForm}
              selectedSectionData={sectionToDisplay}
              setSectionToDisplay={setSectionToDisplay}
            />
          )}
          <Grid container className={classes.productSectionGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card
                variant="outlined"
                sx={{
                  bgcolor: "#ffff",
                  borderRadius: "8px",
                  // height: "auto",
                }}
              >
                <Box className={classes.boxContainerDescBulk}>
                  <Typography
                    /*  onClick={() => {
                                      if (
                                        editMode == false &&
                                        companyData?.menuEnabled === true
                                      ) {
                                        setSelectedSectionData(section);
                                        handleShowEdit(ind, section);
                                      }
                                    }} */
                    className={classes.sectionNameStyle}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {sectionToDisplay?.labels?.length > 0
                      ? sectionToDisplay?.labels[0]?.displayName
                      : ""}
                  </Typography>

                  {locationName !== null && (
                    <Chip
                      style={{
                        borderRadius: "800px",
                        cursor: "pointer",
                        width: "auto",
                        marginTop: "8px",
                        height: "25px",
                        fontSize: "10px",
                        color: "#1B2430",
                      }}
                      label={`${locationName?.internalName} ${
                        locationName?.city !== "" && locationName?.city !== null
                          ? "(" + locationName?.city + ")"
                          : ""
                      }`}
                    />
                  )}
                </Box>

                {/* Correcting section?.items?.map */}
                {sectionToDisplay?.items?.length > 0 &&
                  sectionToDisplay?.items.map(
                    (
                      singleItem,
                      singleItemIndex // Corrected here: section?.items.map
                    ) => {
                      const selectedImageData = handleCheckForImage(singleItem);
                      return (
                        <Grid
                          container
                          className={classes.productItemGridBulk}
                          key={singleItem.id}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box className={classes.boxContainerDescBulk}>
                              {" "}
                              <Box
                                /*  onClick={() => {
                                    if (
                                      editMode == false &&
                                      companyData?.menuEnabled === true
                                    ) {
                                      setSelectedSectionData(section);
                                      setSelectedItemData(singleItem);
                                      handleOpenProductSectionModel("edit");
                                    }
                                  }} */
                                sx={{ width: "100%" }}
                              >
                                <Typography
                                  className={classes.sectionItemNameStyle}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center", // Align the text and icon vertically
                                    position: "relative", // Ensure the parent is positioned relatively
                                  }}
                                >
                                  {singleItem?.labels?.length > 0
                                    ? singleItem?.labels[0]?.displayName
                                    : ""}
                                </Typography>
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    {singleItem?.labels?.length > 0 &&
                                      singleItem?.labels[0]?.hasOwnProperty(
                                        "description"
                                      ) && (
                                        <Box
                                          sx={{
                                            width: "95%",
                                            display: "flex",
                                          }}
                                        >
                                          <Truncate
                                            lines={2}
                                            ellipsis={<span>...</span>}
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: 400,
                                              lineHeight: "14.7px",
                                              color: "#1B2430",

                                              marginBottom: "5px",
                                              width: "92%",
                                            }}
                                          >
                                            {" "}
                                            <Typography
                                              className={
                                                classes.sectionItemDesStyle
                                              }
                                            >
                                              {
                                                singleItem?.labels[0]
                                                  ?.description
                                              }
                                            </Typography>
                                          </Truncate>
                                        </Box>
                                      )}
                                    <Box sx={{ display: "flex" }}>
                                      {singleItem?.attributes &&
                                        Object.keys(singleItem.attributes)
                                          .length > 0 &&
                                        singleItem.attributes?.hasOwnProperty(
                                          "price"
                                        ) &&
                                        ((singleItem?.attributes?.price
                                          ?.units !== null &&
                                          singleItem?.attributes?.price
                                            ?.units !== "") ||
                                          singleItem?.attributes?.price
                                            ?.nanos !== "") && (
                                          <Typography
                                            className={
                                              classes.sectionItemPriceStyle
                                            }
                                          >
                                            {`${
                                              singleItem?.attributes?.price?.hasOwnProperty(
                                                "units"
                                              )
                                                ? singleItem?.attributes?.price
                                                    ?.units
                                                : "0"
                                            }${
                                              singleItem?.attributes?.price?.hasOwnProperty(
                                                "nanos"
                                              ) &&
                                              singleItem?.attributes?.price
                                                ?.nanos !== null
                                                ? "." +
                                                  singleItem?.attributes?.price?.nanos
                                                    .toString()
                                                    .slice(0, 2)
                                                : ""
                                            } ${
                                              singleItem?.attributes?.price
                                                ?.currencyCode !== null
                                                ? singleItem?.attributes?.price
                                                    ?.currencyCode
                                                : ""
                                            }`}
                                          </Typography>
                                        )}

                                      {singleItem?.attributes &&
                                        Object.keys(singleItem.attributes)
                                          .length > 0 &&
                                        singleItem?.attributes?.hasOwnProperty(
                                          "dietaryRestriction"
                                        ) && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                            }}
                                          >
                                            {singleItem?.attributes?.hasOwnProperty(
                                              "price"
                                            ) === true &&
                                              singleItem?.attributes?.price
                                                ?.units !== null &&
                                              singleItem?.attributes?.price
                                                ?.units !== "" && (
                                                <Box
                                                  className={classes.dotStyle}
                                                ></Box>
                                              )}
                                            <Chip
                                              style={{
                                                background: "#E3F9F2",
                                                //   border: "1px solid #BBBDC1",
                                                borderRadius: "800px",
                                                cursor: "pointer",
                                                fontWeight: 600,
                                                width: "auto",
                                                marginTop: "3px",
                                                marginLeft:
                                                  singleItem?.attributes?.hasOwnProperty(
                                                    "price"
                                                  ) === true &&
                                                  singleItem?.attributes?.price
                                                    ?.units !== null &&
                                                  singleItem?.attributes?.price
                                                    ?.units !== ""
                                                    ? "8px"
                                                    : "0px",
                                                height: "25px",
                                                fontSize: "10px",
                                                color: "#1B2430",
                                              }}
                                              label={
                                                singleItem?.attributes
                                                  ?.dietaryRestriction?.length >
                                                  0 &&
                                                singleItem?.attributes?.dietaryRestriction?.includes(
                                                  "VEGETARIAN"
                                                )
                                                  ? "Vegetarian"
                                                  : singleItem?.attributes
                                                      ?.dietaryRestriction
                                                      ?.length > 0 &&
                                                    singleItem?.attributes?.dietaryRestriction?.includes(
                                                      "VEGAN"
                                                    )
                                                  ? "Vegan"
                                                  : ""
                                              }
                                            />
                                          </Box>
                                        )}
                                    </Box>
                                  </Box>

                                  {selectedImageData !== null &&
                                    selectedImageData !== undefined && (
                                      <Avatar
                                        variant="square"
                                        className={classes.imageAvatarDisplay}
                                      >
                                        <LazyLoadImage
                                          loading="lazy"
                                          src={
                                            selectedImageData?.sourceUrl
                                              ? selectedImageData?.sourceUrl
                                              : selectedImageData
                                          }
                                          style={{
                                            height: "60px",
                                            width: "60px",
                                            opacity: 1, // If editMode is true, make it semi-transparent
                                            filter: "none", // Apply grayscale when editMode is true
                                            pointerEvents: "auto", // Disable interaction when editMode is true
                                          }}
                                          alt="Image Alt"
                                          effect="blur"
                                        />
                                      </Avatar>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "10px",
            marginRight: "7px",
          }}
        >
          <CommonButton
            displayWhite="true"
            onSubmit={handleCloseProductSectionModel}
            label={t("Cancel")}
          />

          <CommonButton
            type="submit"
            label={t("Add_label_menu")}
            // isLoading={loading}
            disabled={openAddForm}
            onSubmit={handleOpenConfirmModel}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default SectionDetailModel;
